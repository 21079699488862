import React from 'react';
import styles from '../../../styles/influencer/offers/OfferToBrand.module.css';
import tableStyles from '../../../styles/brand/commonStyle/commonTable.module.css';
import modalStyles from '../../../styles/brand/commonStyle/commonModal.module.css';
import PencilIcon from '../../../assets/common/Pencil-alt.svg';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';

function OfferToBrand() {
  const paymentCardData = [
    {
      title: 'TOTAL OFFER',
      value: 10,
      color: '#e3c700',
    },
    {
      title: 'COMPLETED OFFER',
      value: 3,
      color: '#49a240',
    },
    {
      title: 'ACTIVE OFFER',
      value: 200,
      color: '#49a240',
    },
  ];

  const offersListData = [
    {
      id: '1',
      offerTitle: 'Offer 1',
      startDate: '01/Jan/2022',
      endDate: '01/Jan/2022',
      offerDescription: 'Offer 1',
      status: 'Active',
    },
    {
      id: '2',
      offerTitle: 'Offer 2',
      startDate: '01/Jan/2022',
      endDate: '01/Jan/2022',
      offerDescription: 'Offer 2',
      status: 'Active',
    },
    {
      id: '3',
      offerTitle: 'Offer 3',
      startDate: '01/Jan/2022',
      endDate: '01/Jan/2022',
      offerDescription: 'Offer 3',
      status: 'Completed',
    },
  ];
  const statusColorClass = {
    Active: '#e3c700',
    Completed: '#49a240',
  };

  const uniqueStatus = Object.keys(statusColorClass);

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'OFFER TITLE',
      placeHolder: 'OFFER TITLE',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'START DATE',
      placeHolder: 'START DATE',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'END DATE',
      placeHolder: 'END DATE',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'OFFER DESCRIPTION',
      placeHolder: 'OFFER DESCRIPTION',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: false,
    },
    {
      fieldName: 'STATUS',
      placeHolder: 'STATUS',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: false,
    },
    {
      fieldName: 'ACTION',
      placeHolder: 'ACTION',
      searchHeader: false,
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setUpdateShowModal] = useState(false);

  const [offerTitle, setOfferTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [offerDescription, setOfferDescription] = useState('');

  const popUPModal = (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>Add Offer</div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              setShowModal(false);
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          <p>Offer Title</p>
          <input type="text" />
          <p>Start Date</p>
          <input type="date" />
          <p>End Date</p>
          <input type="date" />
          <p>Offer Description</p>
          <input type="text" />
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button className={modalStyles.btn_request}>Request</button>
          </div>
        </div>
      </div>
    </div>
  );

  const popUPModalUpdate = (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>Update Offer</div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              setUpdateShowModal(false);
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          <p>Offer Title</p>
          <input type="text" value={offerTitle} />
          <p>Start Date</p>
          <input type="date" value={startDate} />
          <p>End Date</p>
          <input type="date" value={endDate} />
          <p>Offer Description</p>
          <input type="text" value={offerDescription} />
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                setUpdateShowModal(false);
              }}
            >
              Cancel
            </button>
            <button className={modalStyles.btn_request}>Update</button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.historyMain}>
      {showModal && popUPModal}
      {showUpdateModal && popUPModalUpdate}
      <div className={styles.topCardsContainer}>
        {paymentCardData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}>
          <div className={styles.code__button}>
            <div></div>
            <div></div>
            <div className={styles.modal_button_container}>
              <div
                className={styles.modal_button}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add Offer
              </div>
            </div>
          </div>
        </div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select>
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {offersListData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.offerTitle}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>{item.offerDescription}</td>
                    <td style={{ color: statusColorClass[item.status] }}>
                      {item.status}
                    </td>
                    <td className={styles.align_center}>
                      <button
                        className={styles.campaigns_table_btn_edit}
                        onClick={() => {
                          setUpdateShowModal(true);
                          setOfferTitle(item.offerTitle);
                          setStartDate(item.startDate);
                          setEndDate(item.endDate);
                          setOfferDescription(item.offerDescription);
                        }}
                      >
                        <img src={PencilIcon} alt="" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferToBrand;
