import React from 'react';
import styles from '../../../styles/brand/payments/PaymentsHistory.module.css';
import tableStyles from '../../../styles/brand/commonStyle/commonTable.module.css';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';

function CampaignsWisePayments() {
  const paymentHistoryData = [
    {
      id: '1',
      campaignName: 'Campaign 1',
      offerAmount: 300,
      campaignAmount: 300,
      brandName: 'Coca Cola',
      startDate: '01/Jan/2022',
      endDate: '01/Jan/2022',
      noOfInfluencers: 10,
      paymentReceived: 300,
      paymentGiven: 300,
    },
    {
      id: '2',
      campaignName: 'Campaign 2',
      offerAmount: 300,
      campaignAmount: 300,
      brandName: 'Coca Cola',
      startDate: '01/Jan/2022',
      endDate: '01/Jan/2022',
      noOfInfluencers: 10,
      paymentReceived: 300,
      paymentGiven: 300,
    },
  ];

  const uniqueStatus = ['Process', 'Under Process'];

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'CAMPAIGN NAME',
      placeHolder: 'CAMPAIGN NAME',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'OFFER AMOUNT',
      placeHolder: 'OFFER AMOUNT',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'CAMPAIGN AMOUNT',
      placeHolder: 'CAMPAIGN AMOUNT',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'BRAND NAME',
      placeHolder: 'BRAND NAME',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: true,
    },
    {
      fieldName: 'START DATE',
      placeHolder: 'START DATE',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'END DATE',
      placeHolder: 'END DATE',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'NO INFLUENCERS',
      placeHolder: 'NO INFLUENCERS',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'PAYMENT RECEIVED',
      placeHolder: 'PAYMENT RECEIVED',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'PAYMENT GIVEN',
      placeHolder: 'PAYMENT GIVEN',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'DETAILS',
      placeHolder: 'DETAILS',
      searchType: 'textInput',
    },
  ];
  const statusColorClass = {
    Process: styles.status_process,
    'Under Process': styles.under_process,
  };

  return (
    <div className={styles.historyMain}>
      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}></div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {paymentHistoryData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.campaignName}</td>
                    <td>{item.offerAmount}</td>
                    <td>{item.campaignAmount}</td>
                    <td>{item.brandName}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>{item.noOfInfluencers}</td>
                    <td>{item.paymentReceived}</td>
                    <td>{item.paymentGiven}</td>
                    <td>
                      <button className={styles.detailsBtn}>Details</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignsWisePayments;
