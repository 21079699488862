import React, { useEffect, useState } from 'react';
import styles from '../../../styles/brand/campaigns/Campaigns.module.css';
import PlusWhiteIcon from '../../../assets/brand/Plus-white.svg';
import CopyBlueIcon from '../../../assets/brand/Copy-blue.svg';
import PencilIcon from '../../../assets/brand/Pencil-alt.svg';
import EyeIcon from '../../../assets/common/eye-white.svg';
import SearchIcon from '../../../assets/common/search-white.svg';

import AmplifyIcon from '../../../assets/brand/campaigns/createCampaign/objective/Amplify.png';
import blogReviewIcon from '../../../assets/brand/campaigns/createCampaign/objective/blogReview.png';
import customIcon from '../../../assets/brand/campaigns/createCampaign/objective/custom.png';
import marketplaceReviewIcon from '../../../assets/brand/campaigns/createCampaign/objective/marketplaceReview.png';
import socialEngagementIcon from '../../../assets/brand/campaigns/createCampaign/objective/socialEngagement.png';
import socialReviewIcon from '../../../assets/brand/campaigns/createCampaign/objective/socialReview.png';
import socialShareIcon from '../../../assets/brand/campaigns/createCampaign/objective/socialShare.png';
import videoReviewIcon from '../../../assets/brand/campaigns/createCampaign/objective/videoReview.png';

import { useNavigate } from 'react-router-dom';
import { getAllCampaigns } from '../../../generalApiCalls/Campaigns';
function Campaigns() {
  const navigate = useNavigate();
  const statsData = [
    {
      title: 'Total Campaigns',
      value: 0,
    },
    {
      title: 'Running',
      value: 0,
    },

    {
      title: 'Pitches',
      value: 0,
    },
    {
      title: 'Completed',
      value: 0,
    },
    {
      title: 'Drafts',
      value: 0,
    },
    {
      title: 'Archive',
      value: 0,
    },
    {
      title: 'Need Action',
      value: 0,
    },
    {
      title: 'Messages',
      value: 0,
    },
  ];

  // const getCampaigns = () => {

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  const [campaignsData, setCampaignsData] = useState([]);
  const [totalCampaignsCount, setTotalCampaignsCount] = useState(0);

  const getAllCampaignsData = (sortBy, search, page, limit) => {
    getAllCampaigns('camp_name', '', 1, 10).then((res) => {
      if (res?.data.length > 0) {
        setTotalCampaignsCount(res.totalSize);
        const reMappedData = res.data.map((item) => {
          return {
            id: item.id,
            campaignName: item.camp_name,
            offerAmount: item.offer_amount,
            campaignAmount: item.campaign_amount,
            brand: item.brand_name,
            startDate: dateStringToDate(item.start_date),
            cashValue: item.cash_value,
            productValue: item.product_value,
            totalBudget: item.budget,
            campaignType: item.obj_id,
            status: item.status,
            brandLogo: item.logo_path,
          };
        });
        setCampaignsData(reMappedData);
      }
    });
  };
  useEffect(() => {
    getAllCampaignsData();
  }, []);

  const uniqueBrands = [
    ...new Set(campaignsData.map((campaign) => campaign.brand)),
  ];
  const uniqueStatus = ['All', 'Running', 'Completed', 'Draft', 'Pitches'];

  const tableSchema = [
    {
      fieldName: '#',
      placeholder: '#',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Campaign Name',
      placeholder: 'Campaign Name',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Offer Amount',
      placeholder: 'Offer Amount',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Campaign Amount',
      placeholder: 'Campaign Amount',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Brand Name',
      placeholder: 'Brand Name',
      searchType: 'select',
      options: uniqueBrands,
      searchHeader: false,
    },
    {
      fieldName: 'Start Date',
      placeholder: 'Start Date',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Cash Value',
      placeholder: 'Cash Value',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Product Value',
      placeholder: 'Product Value',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Total Budget',
      placeholder: 'Total Budget',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Campaign Type',
      placeholder: 'Campaign Type',
      searchType: 'textInput',
      searchHeader: false,
    },

    {
      fieldName: 'Status',
      placeholder: 'Status',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: false,
    },
    {
      fieldName: 'Overview',
      placeholder: 'Overview',
    },
    { fieldName: 'Action', searchType: 'textInput' },
  ];

  const allObjecties = [
    {
      id: 1,
      name: 'Brand Amplify',
      description:
        'Get influencers to promote your brand by posting vidoe and creatives on their page',
      icon: AmplifyIcon,
    },
    {
      id: 2,
      name: 'Social Share',
      description: 'Get influencers to amplify an existing social media post.',
      icon: socialShareIcon,
    },
    {
      id: 3,
      name: 'Social Engagement',
      description:
        'Get influencers to engage with an existing social media posts.',
      icon: socialEngagementIcon,
    },
    {
      id: 4,
      name: 'Social Review',
      description: 'Get influencers to post a review on social media.',
      icon: socialReviewIcon,
    },
    {
      id: 5,
      name: 'Blog Review',
      description: 'Get influencers to write a review on their blog.',
      icon: blogReviewIcon,
    },
    {
      id: 6,
      name: 'Video Review',
      description: 'Get influencers to post a video review on social media.',
      icon: videoReviewIcon,
    },
    {
      id: 7,
      name: 'Maketing Review',
      description: 'Get influencers to post a review on social media.',
      icon: marketplaceReviewIcon,
    },
    {
      id: 8,
      name: 'Marketplace Purchase',
      description:
        'Increase your products purchase in marketplaces such as Amazon, Flipkart, etc.',
      icon: marketplaceReviewIcon,
    },
    {
      id: 9,
      name: 'Custom Campaign',
      description:
        'Need something unique? All social media options are available.',
      icon: customIcon,
    },
  ];

  const statusColorClass = {
    Running: styles.status_running,
    Completed: styles.status_completed,
    Draft: styles.status_draft,
    Pitches: styles.status_pitches,
  };

  return (
    <div className={styles.campaigns}>
      <div className={styles.campaigns_header}>
        <h3>My Campaigns</h3>
        <div className={styles.button_group}>
          <button className={styles.campaigns_header_btn_duplicate}>
            <img src={CopyBlueIcon} alt="" />
            <span>Duplicate Campaign</span>
          </button>
          <button
            className={styles.campaigns_header_btn_create}
            onClick={() => navigate('/brand/create_campaign')}
          >
            <img src={PlusWhiteIcon} alt="" />
            <span>New Campaign</span>
          </button>
        </div>
      </div>
      <div className={styles.stats_row}>
        {statsData.map((item, index) => {
          return (
            <div key={index} className={styles.stats_box}>
              <h4 className={styles.stats_box_title}>{item.title}</h4>
              <div className={styles.stats_box_value}>{item.value}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.campaigns_table_container}>
        <div className={styles.campaigns_table_container_card}>
          <div className={styles.campaigns_table_container_card_header}>
            <div className={styles.campaigns_table_container_card_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={styles.campaigns_table_container_card_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={styles.campaigns_table}>
            <thead>
              <tr className={styles.campaignFilterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          placeholder={column.placeholder}
                          type="text"
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return <option value={option}>{option}</option>;
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={styles.campaignTableHeader}>
                {tableSchema.map((column, index) => {
                  return <th>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {campaignsData?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>
                      <div className={styles.brand_name__logo}>
                        <img
                          className={styles.table_image_brand_big}
                          src={item.brandLogo}
                          alt="brandLogo"
                        />{' '}
                        {item.campaignName}
                      </div>
                    </td>
                    <td>{item.offerAmount}</td>
                    <td>{item.campaignAmount}</td>
                    <td>{item.brand}</td>
                    <td>{item.startDate}</td>
                    <td>{item.cashValue}</td>
                    <td>{item.productValue}</td>
                    <td>{item.totalBudget}</td>
                    <td>
                      {item?.campaignType?.split(',').map((itemId, index) => {
                        if (index > 0) {
                          return (
                            <div>
                              {
                                allObjecties.find(
                                  (item) => item.id === Number(itemId)
                                )?.name
                              }
                            </div>
                          );
                        } else {
                          return (
                            <div>
                              {
                                allObjecties.find(
                                  (item) => item.id === Number(itemId)
                                )?.name
                              }
                            </div>
                          );
                        }
                      })}
                    </td>
                    <td className={statusColorClass[item.status]}>
                      {item.status}
                    </td>
                    <td>
                      <button
                        className={styles.campaigns_table_btn_edit}
                        onClick={() => {
                          navigate(`/brand/campaign_overview/${item.id}`);
                        }}
                      >
                        <img src={SearchIcon} alt="" />
                      </button>
                    </td>
                    <td>
                      <button
                        className={styles.campaigns_table_btn_edit}
                        onClick={() =>
                          navigate(`/brand/create_campaign/${item.id}`)
                        }
                      >
                        <img src={PencilIcon} alt="" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={styles.campaigns_table_container_card_footer}>
            <div className={styles.recordShowingStats}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div
              className={
                styles.campaigns_table_container_card_footer_pagination
              }
            >
              <button
                className={
                  styles.campaigns_table_container_card_footer_pagination_prev
                }
              >
                Previous
              </button>
              <div
                className={styles.campaigns_table_container_card_footer_page_no}
              >
                1
              </div>
              <button
                className={
                  styles.campaigns_table_container_card_footer_pagination_next
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
