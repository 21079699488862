import React, { useEffect, useState } from 'react';
import modalStyles from '../../../../styles/brand/commonStyle/commonModal.module.css';
import Select from 'react-select';
import Axios from '../../../../axios';
import { useDispatch } from 'react-redux';
import { setOpenForm } from '../../../../redux/reducers/brand/profileSlice';

function AddSubBrandForm({ formTitle }) {
  const dispatch = useDispatch();
  const [sub_brand_id, setSub_brand_id] = useState('');
  const [name, setName] = useState('');
  const [about_us, setAboutus] = useState('');
  const [industry, setIndustry] = useState('');
  const [url, setUrl] = useState('');
  const [mobile_no, setMobile_no] = useState('');
  const [work_phone, setWork_phone] = useState('');
  const [specialitiesOptions, setSpecialitiesOptions] = useState();
  const [specialities, setSpecialities] = useState();
  const specialitiesChanges = (selectedOption) => {
    setSpecialities(selectedOption);
  };

  const getMasterValues = (pathQuery, path, setterFunction) => {
    if (!setterFunction || !path) {
      //   alert('Please fill all the fields');
      console.log('getMasterValues -> function call Error ');
    } else {
      if (pathQuery) {
        path = path + '?' + pathQuery;
      }

      let config = {
        method: 'get',
        url: '/api/v1/master/' + path,
      };
      Axios.request(config)
        .then((response) => {
          if (response.status === 200) {
            // navigate('/brand/dashboard');
            console.log(response.data.data);
            // changeKeyName
            // const geographyOptions = response.data.data.map((item) => ({
            //   value: item.geography_id,
            //   label: item.geography_name,
            // }));
            const options = response.data.data.map((item) => {
              let option = {};
              Object.keys(item).forEach((key) => {
                if (key.endsWith('_id')) {
                  option['value'] = item[key];
                } else if (key.endsWith('_name')) {
                  option['label'] = item[key];
                }
              });
              return option;
            });
            setterFunction(options);
            console.log(path, pathQuery, options);
            // setGeographyOptions(geographyOptions);
          } else {
            alert(response.data.error);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
    }
  };

  const addSubBrand = async (dataToUpdate) => {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/v1/user/addSubBrand',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
      data: dataToUpdate,
    };

    Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // dispatch(setOpenForm(''));
          alert('Added Successfully');
        }
      })
      .catch((error) => {
        console.log('error ', error.response.data);
        if (error.response.data.error.message)
          alert(error.response.data.message);
      });
  };

  useEffect(() => {
    getMasterValues(null, 'masterSpecialties', setSpecialitiesOptions);
  }, []);

  let formControler = {
    sub_brand_id: {
      value: sub_brand_id,
      onChange: setSub_brand_id,
      type: 'number',
      label: 'Sub Brand Id',
      placeholder: 'Sub Brand Id',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    name: {
      value: name,
      onChange: setName,
      type: 'text',
      label: 'Name',
      placeholder: 'Name',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    about_us: {
      value: about_us,
      onChange: setAboutus,
      type: 'text',
      label: 'About Us',
      placeholder: 'About Us',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    industry: {
      value: industry,
      onChange: setIndustry,
      type: 'text',
      label: 'Industry',
      placeholder: 'Industry',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    url: {
      value: url,
      onChange: setUrl,
      type: 'text',
      label: 'URL',
      placeholder: 'URL',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    mobile_no: {
      value: mobile_no,
      onChange: setMobile_no,
      type: 'number',
      label: 'Mobile Phone No.',
      placeholder: 'Mobile Phone No.',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    work_phone: {
      value: work_phone,
      onChange: setWork_phone,
      type: 'text',
      label: 'Whatsapp No.',
      placeholder: 'Whatsapp No.',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    specialities: {
      value: specialities,
      onChange: setSpecialities,
      type: 'select',
      option: specialitiesOptions,
      handelChange: specialitiesChanges,
      isMulti: true,
      label: 'Specialities',
      placeholder: 'Specialities',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
  };

  const keys = Object.keys(formControler);
  const formControlerGroups = [];

  for (let i = 0; i < keys.length; i += 2) {
    if (i + 1 < keys.length) {
      const group = [formControler[keys[i]], formControler[keys[i + 1]]];
      formControlerGroups.push(group);
    } else {
      const group = [formControler[keys[i]]];
      formControlerGroups.push(group);
    }
  }

  const formSubmitHandler = async () => {
    let data = {};
    for (let key in formControler) {
      if (
        formControler[key].value &&
        formControler[key].value !== formControler[key].initialValue
      ) {
        if (formControler[key].type === 'select') {
          if (formControler[key].isMulti) {
            data[key] = formControler[key].value.map((item) => item.value);
          } else {
            data[key] = formControler[key].value.value;
          }
        } else {
          data[key] = formControler[key].value;
          console.log(
            key,
            formControler[key].value,
            formControler[key].initialValue
          );
        }
      }
    }
    if (Object.keys(data).length === 0) {
      alert('No data to Add');
      return;
    }
    console.log('Add -------- ', data);
    let updateResult = await addSubBrand(data);
    console.log(updateResult);
  };

  return (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>{formTitle}</div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              dispatch(setOpenForm(''));
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          {formControlerGroups.map((group, index) => {
            return (
              <div className={modalStyles.single_row}>
                {group.map((item, index) => {
                  return (
                    <div>
                      <p>{item.label}</p>

                      {item.type === 'select' ? (
                        <div className={modalStyles.multiSelect}>
                          <Select
                            options={item.option}
                            isMulti={item.isMulti}
                            onChange={item.handelChange}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                width: '100%',
                              }),
                            }}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      ) : (
                        <input
                          type={item.type}
                          name={item.name}
                          id={item.id}
                          value={item.value}
                          onChange={(e) => {
                            item.onChange(e.target.value);
                          }}
                          placeholder={item.placeholder}
                          required={item.required}
                          disabled={item.disabled}
                          error={item.error}
                          helperText={item.helperText}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                dispatch(setOpenForm(''));
              }}
            >
              Cancel
            </button>
            <button
              className={modalStyles.btn_request}
              onClick={() => {
                formSubmitHandler();
              }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSubBrandForm;
