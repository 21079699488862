import React, { useState } from 'react';
import styles from '../../../styles/common/Login/Login.module.css';
import Axios from '../../../axios';
import { useNavigate } from 'react-router-dom';
import SocialButton from '../components/Social_button';
// import InputField from '../components/Input_field';
import socialMediaButtonStyles from '../../../styles/common/components/Social_button.module.css';
import googleButton from '../../../assets/common/social_media/google.png';
import facebookButton from '../../../assets/common/social_media/facebook.png';
import twitterButton from '../../../assets/common/social_media/twitter.png';
import {
  influencerSignUpSchema,
  brandSignUpSchema,
} from '../../../validator/signup_validation';

const BrandRegisterForm = ({ setOnBoardingType }) => {
  const [brandName, setBrandName] = useState('');
  const [officialEmail, setOfficialEmail] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [errorMsg, setErrorMsg] = useState('');

  const navigate = useNavigate();

  const handleRegister = () => {
    setErrorMsg('');
    // Validation
    const brandSignUpData = {
      brand_name: brandName,
      email: officialEmail,
      web_url: websiteURL,
      password: password,
      // confirm_password: confirmPassword,
      referral_code: referralCode,
    };

    const { error } = brandSignUpSchema.validate(brandSignUpData);
    if (error) {
      // alert(error.details[0].message);
      setErrorMsg(error.details[0].message);
      return;
    } else if (password !== confirmPassword) {
      setErrorMsg('Password and Confirm Password do not match');
      return;
    } else {
      let registerData = {
        user_type_id: 2,
        brand_name: brandName,
        email: officialEmail,
        web_url: websiteURL,
        password: password,
      };

      if (referralCode) {
        registerData.referral_code = referralCode;
      }

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/v1/auth/signup',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(registerData),
      };

      Axios.request(config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            // console.log('p1', response);
            // console.log('headers', response.headers.authorization);
            alert('Registered Successfully');
            // console.log('p2', response.data);
            // localStorage.setItem('accessToken', response.data.token);
            // const accessToken = response.headers
            //   .get('Authorization')
            //   .split(' ')[1];
            const accessToken = response.headers.authorization.split(' ')[1];
            // console.log('p3', accessToken);
            localStorage.setItem('accessToken', accessToken);
            navigate('/brand');
          }
        })
        .catch((error) => {
          console.log('error ', error);
          if (error.response.data.error.message)
            alert(error.response.data.error.message);
          // console.log('sk2', error.response.data.error.message);
        });
    }
  };

  return (
    <div className={styles.login_form_section}>
      <div className={socialMediaButtonStyles.social_media_buttons}>
        <SocialButton
          title="Google"
          socialButtonStyle={socialMediaButtonStyles.google_button}
          imgSrc={googleButton}
          buttonOnClick={() =>
            window.open(
              `https://api.hangama.com/api/v1/auth/google/callback?user_type_id=2`,
              '_self'
            )
          }
        ></SocialButton>
        <SocialButton
          title="Facebook"
          socialButtonStyle={socialMediaButtonStyles.facebook_button}
          imgSrc={facebookButton}
          buttonOnClick={() =>
            window.open(
              `https://api.hangama.com/api/v1/auth/facebook/callback?user_type_id=2`,
              '_self'
            )
          }
        ></SocialButton>
        <SocialButton
          title="Twitter"
          socialButtonStyle={socialMediaButtonStyles.twitter_button}
          imgSrc={twitterButton}
          buttonOnClick={() =>
            window.open(
              `https://api.hangama.com/api/v1/auth/twitter/callback?user_type_id=2`,
              '_self'
            )
          }
        ></SocialButton>
      </div>
      <div className={styles.or_login_with_section}>
        <hr></hr>
        <span className={styles.or_login_with}>Or Register With</span>
        <hr></hr>
      </div>
      <div className={styles.input_fields_div}>
        <span className={styles.formErrorMsg}>{errorMsg}</span>

        <input
          type="text"
          placeholder="Brand Name"
          className={styles.influencer_field}
          value={brandName}
          onChange={(e) => setBrandName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Official Email ID"
          className={styles.influencer_field}
          value={officialEmail}
          onChange={(e) => setOfficialEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Website URL"
          className={styles.influencer_field}
          value={websiteURL}
          onChange={(e) => setWebsiteURL(e.target.value)}
        />
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          className={styles.influencer_field}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Confirm Password"
          className={styles.influencer_field}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Referral Code (Optional) "
          className={styles.influencer_field}
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
          required
        />
      </div>
      <div className={styles.form_options_div}>
        <div className={styles.checkbox_div}>
          <input
            type="checkbox"
            className={styles.checkbox_input}
            checked={showPassword}
            onChange={togglePasswordVisibility}
          ></input>
          <label className={styles.checkbox_label}>Show password</label>
        </div>
      </div>
      <div className={styles.bottom_div}>
        <button
          onClick={() => {
            handleRegister();
          }}
          className={styles.login_button}
        >
          Register
        </button>
        <p className={styles.no_account}>
          Already have an account?&nbsp;
          <span
            onClick={() => {
              setOnBoardingType('login');
            }}
            className={styles.register_link}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};

export default BrandRegisterForm;
