import React, { useEffect, useState } from 'react';
import modalStyles from '../../../../styles/brand/commonStyle/commonModal.module.css';
import Select from 'react-select';
import Axios from '../../../../axios';
import {
  useUpdateFormControler,
  useUploadAsset,
} from './ProfileUpdateControler';
import { useDispatch } from 'react-redux';
import { setOpenForm } from '../../../../redux/reducers/brand/profileSlice';

function PersonalInfo({ preFiledData }) {
  const UploadAsset = useUploadAsset();
  const updateFormControler = useUpdateFormControler();
  const [profileImage, setProfileImage] = useState(preFiledData?.profileImage);

  const [corporate_name, setCorporate_name] = useState(
    preFiledData?.corporate_name
  );
  const [first_name, setFirst_name] = useState(preFiledData?.first_name);
  const [last_name, setLast_name] = useState(preFiledData?.last_name);
  const [email, setEmail] = useState(preFiledData?.email);
  const [mobile_no, setMobile_no] = useState(preFiledData?.mobile_no);
  const [whatsapp_no, setWhatsapp_no] = useState(preFiledData?.whatsapp_no);
  const [timeZone, setTimeZone] = useState(preFiledData?.time_zone);

  const dispatch = useDispatch();

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  let formControler = {
    profileImage: {
      value: profileImage,
      onChange: setProfileImage,
      type: 'file',
      label: 'Profile Photo',
      placeholder: 'Profile Photo',
      required: false,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.profileImage,
    },

    corporate_name: {
      value: corporate_name,
      onChange: setCorporate_name,
      type: 'text',
      label: 'Corporate Name',
      placeholder: 'Corporate Name',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.corporate_name,
    },
    first_name: {
      value: first_name,
      onChange: setFirst_name,
      type: 'text',
      label: 'First Name',
      placeholder: 'First Name',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.first_name,
    },
    last_name: {
      value: last_name,
      onChange: setLast_name,
      type: 'text',
      label: 'Last Name',
      placeholder: 'Last Name',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.last_name,
    },
    email: {
      value: email,
      onChange: setEmail,
      type: 'email',
      label: 'Email',
      placeholder: 'Email',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.email,
    },
    mobile_no: {
      value: mobile_no,
      onChange: setMobile_no,
      type: 'number',
      label: 'Mobile Phone No.',
      placeholder: 'Mobile Phone No.',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.mobile_no,
    },
    whatsapp_no: {
      value: whatsapp_no,
      onChange: setWhatsapp_no,
      type: 'number',
      label: 'Whatsapp No.',
      placeholder: 'Whatsapp No.',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.whatsapp_no,
    },

    time_zone: {
      value: timeZone,
      onChange: setTimeZone,
      type: 'text',
      label: 'Time Zone',
      placeholder: 'Time Zone',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.time_zone,
    },

    // profileImage: {
    //   value: profileImage,
    //   onChange: setProfileImage,
    //   type: 'text',
    //   label: 'Profile Image',
    //   placeholder: 'Profile Image',
    //   required: true,
    //   disabled: false,
    //   error: false,
    //   helperText: '',
    // },
  };

  const keys = Object.keys(formControler);
  const formControlerGroups = [];

  for (let i = 0; i < keys.length; i += 2) {
    if (i + 1 < keys.length) {
      const group = [formControler[keys[i]], formControler[keys[i + 1]]];
      formControlerGroups.push(group);
    } else {
      const group = [formControler[keys[i]]];
      formControlerGroups.push(group);
    }
  }

  const formSubmitHandler = async () => {
    let data = {};
    for (let key in formControler) {
      if (
        formControler[key].value &&
        formControler[key].value !== formControler[key].initialValue
      ) {
        if (formControler[key].type === 'select') {
          if (formControler[key].isMulti) {
            data[key] = formControler[key].value.map((item) => item.value);
          } else {
            data[key] = formControler[key].value.value;
          }
        } else {
          data[key] = formControler[key].value;
          console.log(
            key,
            formControler[key].value,
            formControler[key].initialValue
          );
        }
      }
    }
    if (Object.keys(data).length === 0) {
      alert('No data to update');
      return;
    } else {
      if (data.profileImage) {
        let uploadResult = await UploadAsset(
          document.getElementById('profileImageUpload').files[0],
          'IMAGE',
          'PROFILE'
        );

        data = data.filter((item) => item !== 'profileImage');
        if (data.length > 0) {
          let updateResult = await updateFormControler(
            data,
            'PERSONAL_INFORMATION'
          );
          console.log(updateResult);
        }

        console.log(uploadResult);
      } else {
        let updateResult = await updateFormControler(
          data,
          'PERSONAL_INFORMATION'
        );
        console.log(updateResult);
      }
    }
  };

  return (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>
            Personal Information
          </div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              dispatch(setOpenForm(''));
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          {formControlerGroups.map((group, index) => {
            return (
              <div className={modalStyles.single_row}>
                {group.map((item, index) => {
                  return (
                    <div>
                      <p>{item.label}</p>

                      {item.type === 'select' ? (
                        <div className={modalStyles.multiSelect}>
                          <Select
                            options={item.option}
                            isMulti={item.isMulti}
                            onChange={item.handelChange}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                width: '100%',
                              }),
                            }}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      ) : (
                        <input
                          type={item.type}
                          name={item.name}
                          id={item.id}
                          value={item.value}
                          onChange={(e) => {
                            item.onChange(e.target.value);
                          }}
                          placeholder={item.placeholder}
                          required={item.required}
                          disabled={item.disabled}
                          error={item.error}
                          helperText={item.helperText}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          {/* <p className={modalStyles.error_message}>
            You can not transfer amount less than $10 Or 100
          </p> */}
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                dispatch(setOpenForm(''));
              }}
            >
              Cancel
            </button>
            <button
              className={modalStyles.btn_request}
              onClick={() => {
                formSubmitHandler();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
