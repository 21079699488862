import React, { useEffect, useState } from 'react';
import styles from '../../../../styles/admin/campaigns/createCampaign/CreateCampaign.module.css';
import CheckIcon from '../../../../assets/brand/Check-white.svg';
import amazonIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/amazon.svg';
import bloggerIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/blogger.svg';
import facebookIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/facebook.svg';
import instagramIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/instagram.svg';
import linkedinIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/linkedin.svg';
import linkIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/link.svg';
import pinterestIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/pinterest.svg';
import tiktokIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/tiktok.svg';
import twitterIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/twitter.svg';
import youtubeIcon from '../../../../assets/brand/campaigns/createCampaign/socialIcons/youtube.svg';

import AmplifyIcon from '../../../../assets/brand/campaigns/createCampaign/objective/Amplify.png';
import blogReviewIcon from '../../../../assets/brand/campaigns/createCampaign/objective/blogReview.png';
import customIcon from '../../../../assets/brand/campaigns/createCampaign/objective/custom.png';
import marketplaceReviewIcon from '../../../../assets/brand/campaigns/createCampaign/objective/marketplaceReview.png';
import socialEngagementIcon from '../../../../assets/brand/campaigns/createCampaign/objective/socialEngagement.png';
import socialReviewIcon from '../../../../assets/brand/campaigns/createCampaign/objective/socialReview.png';
import socialShareIcon from '../../../../assets/brand/campaigns/createCampaign/objective/socialShare.png';
import videoReviewIcon from '../../../../assets/brand/campaigns/createCampaign/objective/videoReview.png';
import Axios from '../../../../axios';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { getCampaignDetails } from '../../../../generalApiCalls/Campaigns';

function CreateCampaign() {
  const [activeStep, setActiveStep] = useState(0);
  const [paymentMode, setPaymentMode] = useState('cash');
  const [campaignId, setCampaignId] = useState(useParams().campaignId || 0);

  const stepNumberAndName = [
    'Basic Info',
    'Objective',
    'Target Audience',
    'Influencer criteria',
    'Platform',
    'Talking point',
    'Payment',
  ];

  const allPlatforms = [
    { value: 'Facebook', label: 'Facebook', icon: facebookIcon },
    { value: 'Instagram', label: 'Instagram', icon: instagramIcon },
    { value: 'LinkedIn', label: 'LinkedIn', icon: linkedinIcon },
    { value: 'Twitter', label: 'Twitter', icon: twitterIcon },
    { value: 'YouTube', label: 'YouTube', icon: youtubeIcon },
    { value: 'Blog', label: 'Blog', icon: bloggerIcon },
    { value: 'Pinterest', label: 'Pinterest', icon: pinterestIcon },
    { value: 'TikTok', label: 'TikTok', icon: tiktokIcon },
    { value: 'Amazon', label: 'Amazon', icon: amazonIcon },
    { value: 'Other', label: 'Other', icon: linkIcon },
  ];

  // const allPlatforms = [
  //   {
  //     id: 1,
  //     name: 'Facebook',
  //     icon: facebookIcon,
  //   },
  //   {
  //     id: 2,
  //     name: 'Instagram',
  //     icon: instagramIcon,
  //   },
  //   {
  //     id: 3,
  //     name: 'Linkedin',
  //     icon: linkedinIcon,
  //   },
  //   {
  //     id: 4,
  //     name: 'Twitter',
  //     icon: twitterIcon,
  //   },
  //   {
  //     id: 5,
  //     name: 'Youtube',
  //     icon: youtubeIcon,
  //   },
  //   {
  //     id: 6,
  //     name: 'Blog',
  //     icon: bloggerIcon,
  //   },
  //   {
  //     id: 7,
  //     name: 'Pinterest',
  //     icon: pinterestIcon,
  //   },
  //   {
  //     id: 8,
  //     name: 'Tiktok',
  //     icon: tiktokIcon,
  //   },
  //   {
  //     id: 9,
  //     name: 'Amazon',
  //     icon: amazonIcon,
  //   },
  //   {
  //     id: 10,
  //     name: 'Other',
  //     icon: linkIcon,
  //   },
  // ];

  const allObjecties = [
    {
      id: 1,
      name: 'Brand Amplify',
      description:
        'Get influencers to promote your brand by posting vidoe and creatives on their page',
      icon: AmplifyIcon,
    },
    {
      id: 2,
      name: 'Social Share',
      description: 'Get influencers to amplify an existing social media post.',
      icon: socialShareIcon,
    },
    {
      id: 3,
      name: 'Social Engagement',
      description:
        'Get influencers to engage with an existing social media posts.',
      icon: socialEngagementIcon,
    },
    {
      id: 4,
      name: 'Social Review',
      description: 'Get influencers to post a review on social media.',
      icon: socialReviewIcon,
    },
    {
      id: 5,
      name: 'Blog Review',
      description: 'Get influencers to write a review on their blog.',
      icon: blogReviewIcon,
    },
    {
      id: 6,
      name: 'Video Review',
      description: 'Get influencers to post a video review on social media.',
      icon: videoReviewIcon,
    },
    {
      id: 7,
      name: 'Maketing Review',
      description: 'Get influencers to post a review on social media.',
      icon: marketplaceReviewIcon,
    },
    {
      id: 8,
      name: 'Marketplace Purchase',
      description:
        'Increase your products purchase in marketplaces such as Amazon, Flipkart, etc.',
      icon: marketplaceReviewIcon,
    },
    {
      id: 9,
      name: 'Custom Campaign',
      description:
        'Need something unique? All social media options are available.',
      icon: customIcon,
    },
  ];

  const getMasterValues = (pathQuery, path, setterFunction) => {
    if (!setterFunction || !path) {
      //   alert('Please fill all the fields');
      console.log('getMasterValues -> function call Error ');
    } else {
      if (pathQuery) {
        path = path + '?' + pathQuery;
      }

      let config = {
        method: 'get',
        url: '/api/v1/master/' + path,
      };
      Axios.request(config)
        .then((response) => {
          if (response.status === 200) {
            // navigate('/brand/dashboard');
            console.log(response.data.data);
            // changeKeyName
            // const geographyOptions = response.data.data.map((item) => ({
            //   value: item.geography_id,
            //   label: item.geography_name,
            // }));
            const options = response.data.data.map((item) => {
              let option = {};
              Object.keys(item).forEach((key) => {
                if (key.endsWith('_id') && option['value'] === undefined) {
                  option['value'] = item[key];
                } else if (key.endsWith('_name')) {
                  option['label'] = item[key];
                }
              });
              return option;
            });
            setterFunction(options);
            console.log(path, pathQuery, options);
            // setGeographyOptions(geographyOptions);
          } else {
            alert(response.data.error);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
    }
  };

  const [preFiledData, setPreFiledData] = useState(null);
  const [campaignName, setCampaignName] = useState('');
  const [brandName, setBrandName] = useState('');
  const [campaignStartDate, setCampaignStartDate] = useState('');
  const [campaignEndDate, setCampaignEndDate] = useState('');
  const [description, setDescription] = useState('');

  const [selectedObjectives, setSelectedObjectives] = useState([]);

  // const setCampaignDetails = () => {
  //   console.log('campaignId', campaignId);
  //   getCampaignDetails(campaignId).then((res) => {
  //     setPreFiledData(res);
  //   });
  // };

  // useEffect(() => {
  //   console.log('campaignId', campaignId);
  //   if (campaignId) {
  //     setCampaignDetails();
  //   }
  // }, []);

  const stepPartComponent = (stepNumber) => {
    return activeStep >= stepNumber ? (
      <div className={styles.createCampaign_oneStepContainer_active}>
        <div className={styles.createCampaign_oneStepContainer_line}></div>
        <div className={styles.createCampaign_oneStepContainer_circle}>
          <img src={CheckIcon} alt="check icon" />
        </div>
        <div className={styles.createCampaign_oneStepContainer_stepName}>
          {stepNumberAndName[stepNumber]}
        </div>
      </div>
    ) : (
      <div className={styles.createCampaign_oneStepContainer}>
        <div className={styles.createCampaign_oneStepContainer_line}></div>
        <div className={styles.createCampaign_oneStepContainer_circle}>
          <img src={CheckIcon} alt="check icon" />
        </div>
        <div className={styles.createCampaign_oneStepContainer_stepName}>
          {stepNumberAndName[stepNumber]}
        </div>
      </div>
    );
  };

  useEffect(() => {
    getMasterValues(null, 'masterGeography', setGeographyOptions);
    getMasterValues(null, 'masterLanguage', setLanguageOptions);
    getMasterValues(null, 'masterSpecialties', setSpecialitiesOptions);
  }, []);

  const [languageOptions, setLanguageOptions] = useState([]);
  const [specialitiesOptions, setSpecialitiesOptions] = useState([]);
  const [geographyOptions, setGeographyOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const genderOptions = [
    { value: 'female', label: 'Female' },
    { value: 'male', label: 'Male' },
    { value: 'other', label: 'Non-Binary' },
  ];

  const workedEarlierOptions = [
    { value: 'Not Required', label: 'Not Required' },
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  // target Audience
  const [minAge, setMinAge] = useState(preFiledData?.minAge || 0);
  const [maxAge, setMaxAge] = useState(preFiledData?.maxAge || 100);
  const [geography, setGeography] = useState(preFiledData?.geography);
  const [country, setCountry] = useState(preFiledData?.country);
  const [state, setState] = useState(preFiledData?.state);
  const [city, setCity] = useState(preFiledData?.city);
  const [gender, setGender] = useState(preFiledData?.gender);
  const [language, setLanguage] = useState(preFiledData?.language);

  // Influencer Criteria

  const [infu_language, infu_setLanguage] = useState(preFiledData?.language);
  const [infu_specialities, infu_setSpecialities] = useState(
    preFiledData?.specialities
  );
  const [infu_geography, infu_setGeography] = useState(preFiledData?.geography);
  const [infu_country, infu_setCountry] = useState(preFiledData?.country);
  const [minAudienceSize, setMinAudienceSize] = useState(
    preFiledData?.minAudienceSize
  );
  const [maxAudienceSize, setMaxAudienceSize] = useState(
    preFiledData?.maxAudienceSize
  );
  const [minOfferPrice, setMinOfferPrice] = useState(
    preFiledData?.minOfferPrice
  );
  const [maxOfferPrice, setMaxOfferPrice] = useState(
    preFiledData?.maxOfferPrice
  );
  const [workedEarlier, setWorkedEarlier] = useState(
    preFiledData?.workedEarlier
  );

  // platforms

  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  // Talking Point

  const [desiredOutcomes, setDesiredOutcomes] = useState(
    preFiledData?.desiredOutcomes
  );
  const [desiredReach, setDesiredReach] = useState(preFiledData?.desiredReach);
  const [desiredLeads, setDesiredLeads] = useState(preFiledData?.desiredLeads);
  const [noOfInfuencers, setNoOfInfuencers] = useState(
    preFiledData?.noOfInfuencers
  );
  const [PostFrequency, setPostFrequency] = useState(
    preFiledData?.PostFrequency
  );
  const [noOfPosts, setNoOfPosts] = useState(preFiledData?.noOfPosts);
  const [compensateWay, setCompensateWay] = useState(
    preFiledData?.compensateWay
  );
  const [requiredToPurchase, setRequiredToPurchase] = useState(
    preFiledData?.requiredToPurchase
  );
  const [cashValue, setCashValue] = useState(preFiledData?.cashValue);
  const [productName, setProductName] = useState(preFiledData?.productName);
  const [productValue, setProductValue] = useState(preFiledData?.productValue);
  const [productDeliveryMethod, setProductDeliveryMethod] = useState(
    preFiledData?.productDeliveryMethod
  );
  const [postingDos, setPostingDos] = useState(preFiledData?.postingDos);
  const [postingDonts, setPostingDonts] = useState(preFiledData?.postingDonts);
  const [instructionToInfuencers, setInstructionToInfuencers] = useState(
    preFiledData?.instructionToInfuencers
  );
  const [campaignContent, setCampaignContent] = useState(
    preFiledData?.campaignContent
  );
  const [linkBackUrl, setLinkBackUrl] = useState(preFiledData?.linkBackUrl);
  const [typeOfContentPost, setTypeOfContentPost] = useState(
    preFiledData?.typeOfContentPost
  );
  const [minVideoLength, setMinVideoLength] = useState(
    preFiledData?.minVideoLength
  );
  const [maxVideoLength, setMaxVideoLength] = useState(
    preFiledData?.maxVideoLength
  );

  // changes for Target Audience

  const geographyChanges = (selectedOption) => {
    console.log('selectedOption', selectedOption);
    setGeography(selectedOption);
    getMasterValues(
      'geographyId=' +
        selectedOption
          .map((option) => option.value)
          .join(',')
          .toString() +
        '&isMultiSelect=1',
      'masterCountry',
      setCountryOptions
    );
  };
  const countryChanges = (selectedOption) => {
    setCountry(selectedOption);
    getMasterValues(
      'countryId=' +
        selectedOption
          .map((option) => option.value)
          .join(',')
          .toString() +
        '&isMultiSelect=1',
      'masterState',
      setStateOptions
    );
  };
  const stateChanges = (selectedOption) => {
    setState(selectedOption);
    getMasterValues(
      'stateId=' +
        selectedOption
          .map((option) => option.value)
          .join(',')
          .toString() +
        '&isMultiSelect=1',
      'masterCity',
      setCityOptions
    );
  };
  const cityChanges = (selectedOption) => {
    setCity(selectedOption);
  };
  const languageChanges = (selectedOption) => {
    setLanguage(selectedOption);
  };

  // changes for Influencer Criteria

  const infu_geographyChanges = (selectedOption) => {
    console.log('selectedOption', selectedOption);
    infu_setGeography(selectedOption);
    getMasterValues(
      'geographyId=' +
        selectedOption
          .map((option) => option.value)
          .join(',')
          .toString() +
        '&isMultiSelect=1',
      'masterCountry',
      setCountryOptions
    );
  };
  const infu_countryChanges = (selectedOption) => {
    infu_setCountry(selectedOption);
    getMasterValues(
      'countryId=' +
        selectedOption
          .map((option) => option.value)
          .join(',')
          .toString() +
        '&isMultiSelect=1',
      'masterState',
      setStateOptions
    );
  };
  const infu_languageChanges = (selectedOption) => {
    infu_setLanguage(selectedOption);
  };

  const infu_specialitiesChanges = (selectedOption) => {
    infu_setSpecialities(selectedOption);
  };

  const workedEarlierChanges = (selectedOption) => {
    setWorkedEarlier(selectedOption);
  };

  // const languageDefaultValue = languageOptions.filter((option) =>
  //   preFiledData?.languages?.includes(option.value)
  // );

  // const specialitiesDefaultValue = specialitiesOptions.filter((option) =>
  //   preFiledData?.specialties?.includes(option.value)
  // );

  const formSubmit = async (data) => {
    try {
      const config = {
        method: 'post',
        url: '/api/v1/campaign/createCampaign',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: data,
      };

      const response = await Axios.request(config);

      if (
        (response.status === 201 || response.status === 200) &&
        response.data.success === true
      ) {
        console.log('response.data.data', response.data.campaignId);
        setCampaignId(response.data.campaignId);
        activeStep < 7 && setActiveStep(activeStep + 1);
      } else {
        alert(response.data.message);
        throw new Error('Failed to upload asset');
      }
    } catch (error) {
      console.log('error', error);
      alert(error.response.data.error.message);
      throw new Error('Failed to upload asset');
    }
  };

  const handelNext = async () => {
    if (activeStep === 0) {
      if (
        campaignName === '' ||
        brandName === '' ||
        campaignStartDate === '' ||
        campaignEndDate === '' ||
        description === ''
      ) {
        alert('Please fill all the fields');
        return;
      } else {
        const data = new FormData();
        data.append('campaignName', campaignName);
        data.append('brandName', brandName);
        data.append('campaignStartDate', campaignStartDate);
        data.append('campaignEndDate', campaignEndDate);
        data.append('description', description);
        data.append('formDataId', 1);
        data.append('asset', document.getElementById('brandLogo_id').files[0]);
        data.append('campaignId', campaignId);
        await formSubmit(data);
      }
    }
    if (activeStep === 1) {
      if (selectedObjectives.length === 0) {
        alert('Please select campaign type');
        return;
      } else {
        const data = {
          formDataId: '2',
          data: {
            campaignId: campaignId,
            objectiveData: selectedObjectives.join(','),
          },
        };
        await formSubmit(data);
      }
    }
    if (activeStep === 2) {
      console.log('minAge', minAge);
      console.log('maxAge', maxAge);
      const data = {
        formDataId: '3',
        data: {
          campaignId: campaignId,
          minAge: minAge,
          maxAge: maxAge,
          gender: gender?.map((option) => option.value).join(','),
          geography: geography?.map((option) => option.value).join(','),
          country: country?.map((option) => option.value).join(','),
          state: state?.map((option) => option.value).join(','),
          city: city?.map((option) => option.value).join(','),
          language: language?.map((option) => option.value).join(','),
        },
      };

      // console.log('data', data);
      await formSubmit(data);
    }
    if (activeStep === 3) {
      const data = {
        formDataId: '4',
        data: {
          campaignId: campaignId,
          specialities: infu_specialities
            ?.map((option) => option.value)
            .join(','),
          influencerGeo: infu_geography
            ?.map((option) => option.value)
            .join(','),
          influencerCountries: infu_country
            ?.map((option) => option.value)
            .join(','),
          language: infu_language?.map((option) => option.value).join(','),
          minAudienceSize: minAudienceSize,
          maxAudienceSize: maxAudienceSize,
          minOfferPrice: minOfferPrice,
          maxOfferPrice: maxOfferPrice,
          workedEarlier: workedEarlier?.value,
        },
      };

      console.log('data', data);
      await formSubmit(data);
    }
    if (activeStep === 4) {
      if (selectedPlatforms.length === 0) {
        alert('Please select campaign type');
        return;
      } else {
        const data = {
          formDataId: '5',
          data: {
            campaignId: campaignId,
            platformData: selectedPlatforms.join(','),
          },
        };
        console.log('selectedPlatforms', data);
        await formSubmit(data);
      }
    }
    if (activeStep === 5) {
      const data = {
        formDataId: '6',
        data: {
          campaignId: campaignId,
          desired_outcomes: desiredOutcomes,
          desired_reach: desiredReach,
          desired_leads: desiredLeads,
          no_of_influencers: noOfInfuencers,
          post_frequency: PostFrequency,
          no_of_post: noOfPosts,
          compensate_way: compensateWay,
          required_to_purchase: Number(requiredToPurchase),
          cash_value: cashValue,
          product_name: productName,
          product_value: productValue,
          product_deliver_method: productDeliveryMethod,
          posting_dos: postingDos,
          posting_donts: postingDonts,
          instruction_to_influencer: instructionToInfuencers,
          campaign_content: campaignContent,
          link_back_url: linkBackUrl,
          type_of_content_post: typeOfContentPost,
          min_video_length: minVideoLength,
          max_video_length: maxVideoLength,
        },
      };
      console.log('data', data);
      await formSubmit(data);
    }
  };

  return (
    <div className={styles.createCampaign}>
      <div className={styles.createCampaign_extraPadding}>
        {/* create campaign form with stepper form  */}
        <div>Add New Campaign</div>
        <div className={styles.createCampaign_allSteps}>
          {/* stepper form  */}
          {stepNumberAndName.map((stepName, index) => {
            return <div key={index}>{stepPartComponent(index)}</div>;
          })}
        </div>

        {/* form  */}
        <div className={styles.createCampaign_form}>
          {/* step 1  */}

          {activeStep === 0 && (
            <div className={styles.createCampaign_form}>
              <div className={styles.createCampaign_oneForm}>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Campaign Name</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input
                        type="text"
                        placeholder="Give Your Campaign A Name"
                        value={campaignName}
                        onChange={(e) => setCampaignName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Brand Name</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Brand Name"
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Brand Logo</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input type="file" id="brandLogo_id" />
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Campaign Start Date</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input
                        type="date"
                        value={campaignStartDate}
                        onChange={(e) => setCampaignStartDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Campaign End Date</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input
                        type="date"
                        value={campaignEndDate}
                        onChange={(e) => setCampaignEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_form_inputBox}>
                  <span>Description</span>
                  <div>
                    <div
                      className={
                        styles.createCampaign_form_inputBox_specialIcon
                      }
                    >
                      <img src={CheckIcon} alt="" />
                    </div>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeStep === 2 && (
            <div className={styles.createCampaign_form}>
              <div className={styles.createCampaign_oneForm}>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Min-Age</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <select
                        value={minAge}
                        onChange={(e) => setMinAge(e.target.value)}
                      >
                        <option value={13}>13</option>
                        <option value={16}>16</option>
                        <option value={17}>17</option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Max-Age</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <select
                        value={maxAge}
                        onChange={(e) => setMaxAge(e.target.value)}
                      >
                        <option value={17}>17</option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20</option>
                        <option value={21}>21</option>
                        <option value={22}>22</option>
                        <option value={23}>23</option>
                        <option value={24}>24</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Gender</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={genderOptions}
                        isMulti={true}
                        onChange={(e) => setGender(e)}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>* Audience Geography</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={geographyOptions}
                        onChange={geographyChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>* Targeted Countries</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={countryOptions}
                        onChange={countryChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>* State</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={stateOptions}
                        onChange={stateChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>District</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={stateOptions}
                        onChange={stateChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>City/Town</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={cityOptions}
                        onChange={cityChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Language</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={languageOptions}
                        onChange={languageChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeStep === 3 && (
            <div className={styles.createCampaign_form}>
              <div className={styles.createCampaign_oneForm}>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>* Specialties</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={specialitiesOptions}
                        onChange={infu_specialitiesChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>*Influencers Geography</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={geographyOptions}
                        onChange={infu_geographyChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>* Influencers Countries</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={countryOptions}
                        onChange={infu_countryChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>* Minimum Audience Size*</span>
                    <div className={styles.multiSelect}>
                      <input
                        value={minAudienceSize}
                        onChange={(e) => setMinAudienceSize(e.target.value)}
                        type="number"
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Maximum Audience Size*</span>
                    <div className={styles.multiSelect}>
                      <input
                        value={maxAudienceSize}
                        onChange={(e) => setMaxAudienceSize(e.target.value)}
                        type="number"
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Language</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={languageOptions}
                        onChange={infu_languageChanges}
                        isMulti={true}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Worked earlier with this company</span>
                    <div className={styles.multiSelect}>
                      <Select
                        options={workedEarlierOptions}
                        onChange={workedEarlierChanges}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Min Offer Price</span>
                    <div className={styles.multiSelect}>
                      <input
                        value={minOfferPrice}
                        onChange={(e) => setMinOfferPrice(e.target.value)}
                        type="number"
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Max Offer Price</span>
                    <div className={styles.multiSelect}>
                      <input
                        value={maxOfferPrice}
                        onChange={(e) => setMaxOfferPrice(e.target.value)}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeStep === 5 && (
            <div className={styles.createCampaign_form}>
              <div className={styles.createCampaign_oneForm}>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Desired OutComes</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input
                        type="text"
                        placeholder="Desired OutComes"
                        value={desiredOutcomes}
                        onChange={(e) => setDesiredOutcomes(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Desired Reach</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input
                        type="text"
                        placeholder="Desired Reach"
                        value={desiredReach}
                        onChange={(e) => setDesiredReach(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Desired Leads</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input
                        type="text"
                        placeholder="Desired Leads"
                        value={desiredLeads}
                        onChange={(e) => setDesiredLeads(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Numbers of Influencers</span>
                    <div>
                      <input
                        type="number"
                        placeholder="Numbers of Influencers"
                        value={noOfInfuencers}
                        onChange={(e) => setNoOfInfuencers(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Post Frequency</span>
                    <div>
                      {/* <input type="text" /> */}
                      <select
                        onChange={(e) => setPostFrequency(e.target.value)}
                      >
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Number of Post</span>
                    <div>
                      <input
                        type="number"
                        placeholder="Number of Post"
                        value={noOfPosts}
                        onChange={(e) => setNoOfPosts(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_paymentMode}>
                  <div className={styles.createCampaign_paymentMode_question}>
                    <div>
                      * In what ways will you compensate the influencers?
                    </div>
                    <div
                      className={
                        styles.createCampaign_paymentMode_question_options
                      }
                    >
                      <div>
                        <input
                          type="radio"
                          name="compensateWay"
                          value={'Product Only'}
                          checked={compensateWay === 'Product Only'}
                          onChange={(e) => setCompensateWay(e.target.value)}
                        />
                        <span>Product Only</span>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="compensateWay"
                          value={'Cash Only'}
                          checked={compensateWay === 'Cash Only'}
                          onChange={(e) => setCompensateWay(e.target.value)}
                        />
                        <span>Cash Only</span>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="compensateWay"
                          value={'Cash and Product'}
                          checked={compensateWay === 'Cash and Product'}
                          onChange={(e) => setCompensateWay(e.target.value)}
                        />
                        <span>Cash and Product Only</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.compensate_values}>
                    <div className={styles.compensate_value_product}>
                      <input
                        type="checkbox"
                        value={requiredToPurchase}
                        checked={requiredToPurchase}
                        onChange={(e) =>
                          setRequiredToPurchase(!requiredToPurchase)
                        }
                      />
                      <span>
                        {' '}
                        The influencer is required to purchase the product below
                        as part of the campaign?
                      </span>
                    </div>

                    <div className={styles.createCampaign_form_inputBox}>
                      <span>* Cash Amount</span>
                      <div>
                        <div>
                          <img src={CheckIcon} alt="" />
                        </div>
                        <input
                          type="number"
                          value={cashValue}
                          onChange={(e) => setCashValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className={styles.createCampaign_form_inputBox}>
                      <span>* Product Name(Required)</span>
                      <div>
                        <div>
                          <img src={CheckIcon} alt="" />
                        </div>
                        <input
                          type="text"
                          value={productName}
                          onChange={(e) => setProductName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className={styles.createCampaign_form_inputBox}>
                      <span>* Product Value(Required)</span>

                      <div>
                        <div>
                          <img src={CheckIcon} alt="" />
                        </div>
                        <input
                          type="text"
                          value={productValue}
                          onChange={(e) => setProductValue(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_paymentMode_question}>
                  <div>
                    * How will you deliver the free product to the influencer?
                  </div>
                  <div
                    className={
                      styles.createCampaign_paymentMode_question_options
                    }
                  >
                    <div>
                      <input
                        type="radio"
                        name="deliveryMode"
                        value={'Mailing Address'}
                        checked={productDeliveryMethod === 'Mailing Address'}
                        onChange={(e) =>
                          setProductDeliveryMethod(e.target.value)
                        }
                      />
                      <span>Mailing Address</span>
                    </div>

                    <div>
                      <input
                        type="radio"
                        name="deliveryMode"
                        value={'Email Address'}
                        checked={productDeliveryMethod === 'Email Address'}
                        onChange={(e) =>
                          setProductDeliveryMethod(e.target.value)
                        }
                      />
                      <span>Email Address</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  styles.createCampaign_oneForm + ' ' + styles.postingInfo
                }
              >
                <div className={styles.createCampaign_form_inputBox}>
                  <span>Posting Do's</span>
                  <div>
                    <div
                      className={
                        styles.createCampaign_form_inputBox_specialIcon
                      }
                    >
                      <img src={CheckIcon} alt="" />
                    </div>
                    <textarea
                      value={postingDos}
                      onChange={(e) => setPostingDos(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className={styles.createCampaign_form_inputBox}>
                  <span>Posting Don'ts</span>
                  <div>
                    <div
                      className={
                        styles.createCampaign_form_inputBox_specialIcon
                      }
                    >
                      <img src={CheckIcon} alt="" />
                    </div>
                    <textarea
                      value={postingDonts}
                      onChange={(e) => setPostingDonts(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className={styles.createCampaign_form_inputBox}>
                  <span>instruction To Influencers</span>
                  <div>
                    <div
                      className={
                        styles.createCampaign_form_inputBox_specialIcon
                      }
                    >
                      <img src={CheckIcon} alt="" />
                    </div>
                    <textarea
                      value={instructionToInfuencers}
                      onChange={(e) =>
                        setInstructionToInfuencers(e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
                <div className={styles.createCampaign_form_inputBox}>
                  <span>Campaign Content</span>
                  <div>
                    <div
                      className={
                        styles.createCampaign_form_inputBox_specialIcon
                      }
                    >
                      <img src={CheckIcon} alt="" />
                    </div>
                    <textarea
                      value={campaignContent}
                      onChange={(e) => setCampaignContent(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Link Back URL</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input
                        type="text"
                        placeholder="Link Back URL"
                        value={linkBackUrl}
                        onChange={(e) => setLinkBackUrl(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Type of content to post</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <select
                        value={typeOfContentPost}
                        onChange={(e) => setTypeOfContentPost(e.target.value)}
                      >
                        <option value={0} disabled={true}>
                          Type of content to post
                        </option>
                        <option value="video">Video</option>
                        <option value="Image">Image</option>
                        <option value="Text">Text</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Min Video Length</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <select
                        value={minVideoLength}
                        onChange={(e) => setMinVideoLength(e.target.value)}
                      >
                        <option value={0} disabled={true}>
                          Min Video Lenght
                        </option>
                        <option value="1sec">1 sec</option>
                        <option value="2sec">2 sec</option>
                        <option value="3sec">3 sec</option>
                        <option value="4sec">4 sec</option>
                        <option value="5sec">5 sec</option>
                        <option value="6sec">6 sec</option>
                        <option value="7sec">7 sec</option>
                        <option value="8sec">8 sec</option>
                        <option value="9sec">9 sec</option>
                        <option value="10sec">10 sec</option>
                        <option value="10+sec">10+ sec</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Max Video Length</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <select
                        value={maxVideoLength}
                        onChange={(e) => setMaxVideoLength(e.target.value)}
                      >
                        <option value={0} disabled={true}>
                          Max Video Lenght
                        </option>
                        <option value="30sec">30 sec</option>
                        <option value="45sec">45 sec</option>
                        <option value="60sec">60 sec</option>
                        <option value="90sec">90 sec</option>
                        <option value="120sec">120 sec</option>
                        <option value="120+sec">120+ sec</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.createCampaign_oneForm}>
                <h4>Campaign Supported Documents</h4>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Type of Document</span>
                    <div>
                      {/* <div>
                        <img src={CheckIcon} alt="" />
                      </div> */}
                      <select>
                        <option value="PDF">PDF</option>
                        <option value="DOCX">DOCX</option>
                        <option value="IMAGE">IMAGE</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Attach document (PDF/IMG)</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input type="file" />
                    </div>
                  </div>
                </div>
                <div className={styles.createCampaign_inputs_row}>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Type of Document</span>
                    <div>
                      {/* <div>
                        <img src={CheckIcon} alt="" />
                      </div> */}
                      <select>
                        <option value="PDF">PDF</option>
                        <option value="DOCX">DOCX</option>
                        <option value="IMAGE">IMAGE</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.createCampaign_form_inputBox}>
                    <span>Attach document (PDF/IMG)</span>
                    <div>
                      <div>
                        <img src={CheckIcon} alt="" />
                      </div>
                      <input type="file" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* step 1 */}
          {activeStep === 1 && (
            <div className={styles.createCampaign_allObjectives}>
              {allObjecties.map((item, index) => {
                return (
                  <div className={styles.createCampaign_oneObjective}>
                    <img src={item.icon} alt="" />
                    <div className={styles.createCampaign_oneObjective_name}>
                      {item.name}
                    </div>
                    <div className={styles.createCampaign_desc}>
                      {item.description}
                    </div>
                    <div className={styles.createCampaign_oneObjective_button}>
                      <input
                        type="checkbox"
                        name="objectiveSelection"
                        value={item.id}
                        checked={selectedObjectives.includes(item.id)}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedObjectives((prevSelectedObjectives) => {
                            if (
                              prevSelectedObjectives.includes(
                                parseInt(selectedValue)
                              )
                            ) {
                              return prevSelectedObjectives.filter(
                                (objective) =>
                                  objective !== parseInt(selectedValue)
                              );
                            } else {
                              return [
                                ...prevSelectedObjectives,
                                parseInt(selectedValue),
                              ];
                            }
                          });
                        }}
                      />
                      <span>Select</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* step 4 */}
          {activeStep === 4 && (
            <div className={styles.createCampaign_allObjectives}>
              {allPlatforms.map((item, index) => {
                return (
                  <div className={styles.createCampaign_onePlatform}>
                    <img src={item.icon} alt="" />
                    <div className={styles.createCampaign_oneObjective_name}>
                      {item.label}
                    </div>
                    <div className={styles.createCampaign_oneObjective_button}>
                      <input
                        type="checkbox"
                        name="platformSelection"
                        value={item.value}
                        checked={selectedPlatforms.includes(item.value)}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedPlatforms((prevSelectedPlatforms) => {
                            if (prevSelectedPlatforms.includes(selectedValue)) {
                              return prevSelectedPlatforms.filter(
                                (objective) => objective !== selectedValue
                              );
                            } else {
                              return [...prevSelectedPlatforms, selectedValue];
                            }
                          });
                        }}
                      />
                      <span>Select</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {/* footer  */}

      <div className={styles.createCampaign_footer}>
        <div className={styles.createCampaign_footer_buttons}>
          <button
            onClick={() => {
              activeStep > 0 && setActiveStep(activeStep - 1);
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              // activeStep < 7 && setActiveStep(activeStep + 1);
              handelNext();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateCampaign;
