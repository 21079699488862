import React from 'react';
import styles from '../../../styles/brand/helperComponents/SocialMediaTag.module.css';

import instagramIcon from '../../../assets/socialmediaIcons/white-filled/instagram.svg';
import facebookIcon from '../../../assets/socialmediaIcons/white-filled/facebook.svg';
import twitterIcon from '../../../assets/socialmediaIcons/white-filled/twitter.svg';
import tiktokIcon from '../../../assets/socialmediaIcons/white-filled/tiktok.svg';
import youtubeIcon from '../../../assets/socialmediaIcons/white-filled/youtube.svg';

function SocialMediaTag({ name, icon, count, socialLink }) {
  // number to M, K, B, T, etc
  const numberToMKB = (number) => {
    if (number >= 1000000000000) {
      return (number / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
    }
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return number;
  };

  const getIcon = (name) => {
    name = name.toLowerCase();
    switch (name) {
      case 'facebook':
        return facebookIcon;
      case 'instagram':
        return instagramIcon;
      case 'twitter':
        return twitterIcon;
      case 'tiktok':
        return tiktokIcon;
      case 'youtube':
        return youtubeIcon;
      default:
        return null;
    }
  };

  return (
    <div
      className={styles.InfluencerProfile_details_scoialCard}
      onClick={() => {
        socialLink && window.open(socialLink, '_blank');
      }}
    >
      <div>
        <img src={icon ? icon : getIcon(name)} alt="socialMedia" />
      </div>
      <span>{numberToMKB(count)}</span>
    </div>
  );
}

export default SocialMediaTag;
