import React from 'react';
import styles from '../../../styles/brand/payments/PaymentsHistory.module.css';
import tableStyles from '../../../styles/brand/commonStyle/commonTable.module.css';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';

function CampaignsWisePayments() {
  const paymentHistoryData = [
    {
      id: '1',
      influencersName: 'John',
      influencersId: '123456789',
      totalEarnings: 300,
      emg: 'Process',
      actualEarnings: 300,
      withdrawal: 300,
      balance: 300,
    },
    {
      id: '2',
      influencersName: 'John',
      influencersId: '123456789',
      totalEarnings: 300,
      emg: 'Process',
      actualEarnings: 300,
      withdrawal: 300,
      balance: 300,
    },
    {
      id: '3',
      influencersName: 'John',
      influencersId: '123456789',
      totalEarnings: 300,
      emg: 'Process',
      actualEarnings: 300,
      withdrawal: 300,
      balance: 300,
    },
    {
      id: '4',
      influencersName: 'John',
      influencersId: '123456789',
      totalEarnings: 300,
      emg: 'Process',
      actualEarnings: 300,
      withdrawal: 300,
      balance: 300,
    },
  ];

  const uniqueStatus = ['Process', 'Under Process'];

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'INFLUENCERS NAME',
      placeHolder: 'INFLUENCERS NAME',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'INFLUENCERS ID',
      placeHolder: 'INFLUENCERS ID',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'TOTAl EARNINGS',
      placeHolder: 'TOTAl EARNINGS',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'EMG',
      placeHolder: 'EMG',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: true,
    },
    {
      fieldName: 'ACTUAL EARNINGS',
      placeHolder: 'ACTUAL EARNINGS',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'WITHDRAWAL',
      placeHolder: 'WITHDRAWAL',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'BALANCE',
      placeHolder: 'BALANCE',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'DETAILS',
      placeHolder: 'DETAILS',
      searchType: 'textInput',
    },
  ];
  const statusColorClass = {
    Process: styles.status_process,
    'Under Process': styles.under_process,
  };

  return (
    <div className={styles.historyMain}>
      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}></div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {paymentHistoryData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.influencersName}</td>
                    <td>{item.influencersId}</td>
                    <td>{item.totalEarnings}</td>
                    <td>{item.emg}</td>
                    <td>{item.actualEarnings}</td>
                    <td>{item.withdrawal}</td>
                    <td>{item.balance}</td>
                    <td>
                      <button className={styles.detailsBtn}>Details</button>
                      <button className={styles.payBtn}>Pay</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignsWisePayments;
