import React from 'react';
import styles from '../../../styles/admin/clients/Clients.module.css';
import tableStyles from '../../../styles/admin/commonStyle/commonTable.module.css';
import EyeIcon from '../../../assets/common/eye-white.svg';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';
import { getAllBrands } from '../../../generalApiCalls/brand';
import { useNavigate } from 'react-router-dom';

function Clients() {
  const navigate = useNavigate();

  const cleinstCardData = [
    {
      title: 'TOTAL CLIENTS',
      value: 10,
      color: '#e3c700',
    },
    {
      title: 'ACTIVE',
      value: 7,
      color: '#2a76f4',
    },
    {
      title: 'FAVOURITE',
      value: 300,
      color: '#49a240',
    },
    {
      title: 'INACTIVE',
      value: 3,
      color: '#f16d75',
    },
  ];

  const uniqueStatusColorMapping = {
    Draft: '#f16d75',
    Running: '#49a240',
    Pitches: '#1d212f',
    Completed: '#49a240',
  };

  const uniqueStatus = Object.keys(uniqueStatusColorMapping);

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Company Name',
      placeHolder: 'Company Name',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Offer Amount',
      placeHolder: 'Offer Amount',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Campaign Amount',
      placeHolder: 'Campaign Amount',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Client Name',
      placeHolder: 'Client Name',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Registered Date',
      placeHolder: 'Registered Date',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Last Login',
      placeHolder: 'Last Login',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Completed Campaigns',
      placeHolder: 'Completed Campaigns',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Running Campaigns',
      placeHolder: 'Running Campaigns',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Status',
      placeHolder: 'Status',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: false,
    },
    {
      fieldName: 'Details',
      placeHolder: 'Details',
      searchType: 'textInput',
    },
  ];
  const statusColorClass = {
    Active: '#49a240',
    InActive: '#f16d75',
  };

  const [allClients, setAllClients] = useState([]);

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  const fetchAllBrands = (sortBy, sortOrder, page, limit, search) => {
    getAllBrands(sortBy, sortOrder, page, limit, search)
      .then((res) => {
        console.log('fetchAllBrands', res.data);
        // remape the data
        let reMappedData = res.data.map((item, index) => {
          return {
            id: item.user_id,
            companyName: item.organization_name,
            offerAmount: item.offer_amount,
            campaignAmount: item.campaign_amount,
            clientName: item.client_name,
            registeredDate: dateStringToDate(item.created_at),
            lastLogin: dateStringToDate(item.app_last_login),
            completedCampaigns: item.completed_campaigns,
            runningCampaigns: item.running_campaigns,
            status: 'Active',
          };
        });
        setAllClients(reMappedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useState(() => {
    fetchAllBrands('created_at', 'ASC', 1, 10, '');
  }, []);

  return (
    <div className={styles.historyMain}>
      <div className={styles.topCardsContainer}>
        {cleinstCardData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}></div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {allClients.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.companyName}</td>
                    <td>{item.offerAmount}</td>
                    <td>{item.campaignAmount}</td>
                    <td>{item.clientName}</td>
                    <td>{item.registeredDate}</td>
                    <td>{item.lastLogin}</td>
                    <td>{item.completedCampaigns}</td>
                    <td>{item.runningCampaigns}</td>
                    <td
                      style={{
                        color: statusColorClass[item.status],
                      }}
                    >
                      {item.status}
                    </td>
                    <td>
                      <button
                        className={styles.campaigns_table_btn_edit}
                        onClick={() => {
                          navigate('/admin/client/' + item.id);
                        }}
                      >
                        <img src={EyeIcon} alt="" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
