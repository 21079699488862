import React from 'react';
import styles from '../../../../styles/admin/clients/clientDetails/ClientDetails.module.css';

import { useState } from 'react';
import { getBrandDetails } from '../../../../generalApiCalls/brand';
import { useParams } from 'react-router-dom';

function ClientDetails() {
  const [brandDetails, setBrandDetails] = useState({});

  const brand_id = useParams().client_id;

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  const fetchBrandDetails = (brandId) => {
    getBrandDetails(brandId)
      .then((res) => {
        console.log('fetchBrandDetails', res.data);
        setBrandDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useState(() => {
    fetchBrandDetails(brand_id);
  }, []);

  const tableHeader = (title) => (
    <div className={styles.tableHeading}>
      <h5 className={styles.tableHeadingTitle}>{title}</h5>
    </div>
  );

  return (
    <div className={styles.clientMain}>
      <div className={styles.tabularContentContainer}>
        <div className={styles.table_container}>
          {tableHeader('Personal Information')}
          <div className={styles.table}>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Corporate Name</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.personal_info?.corporate_name}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>First Name</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.personal_info?.first_name}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Last Name</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.personal_info?.last_name}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Email</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.personal_info?.email}
                </div>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Mobile No.</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.personal_info?.mobile_no}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Whatsapp Mobile No.</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.personal_info?.whatsapp_no}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Account Status</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.personal_info?.account_status}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Timezone</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.personal_info?.time_zone}
                </div>
              </div>
            </div>
          </div>

          {brandDetails?.sub_brands?.map((subBrand) => (
            <>
              {tableHeader('Sub Brand Information')}
              <div className={styles.table}>
                <div className={styles.tableRow}>
                  <div className={styles.tableCell}>
                    <div className={styles.tableCellTitle}>Brand ID</div>
                    <div className={styles.tableCellData}>
                      {subBrand?.sub_brand_id}
                    </div>
                  </div>
                  <div className={styles.tableCell}>
                    <div className={styles.tableCellTitle}>Brand Name</div>
                    <div className={styles.tableCellData}>{subBrand?.name}</div>
                  </div>
                  <div className={styles.tableCell}>
                    <div className={styles.tableCellTitle}>About Brand</div>
                    <div className={styles.tableCellData}>
                      {subBrand?.about_us}
                    </div>
                  </div>
                  <div className={styles.tableCell}>
                    <div className={styles.tableCellTitle}>Industry</div>
                    <div className={styles.tableCellData}>
                      {subBrand?.industry}
                    </div>
                  </div>
                </div>
                <div className={styles.tableRow}>
                  <div className={styles.tableCell}>
                    <div className={styles.tableCellTitle}>URL</div>
                    <div className={styles.tableCellData}>{subBrand?.url}</div>
                  </div>
                  <div className={styles.tableCell}>
                    <div className={styles.tableCellTitle}>Work Phone</div>
                    <div className={styles.tableCellData}>
                      {subBrand?.work_phone}
                    </div>
                  </div>
                  <div className={styles.tableCell}>
                    <div className={styles.tableCellTitle}>Mobile No.</div>
                    <div className={styles.tableCellData}>
                      {subBrand?.mobile_no}
                    </div>
                  </div>
                  <div className={styles.tableCell}>
                    <div className={styles.tableCellTitle}>Categories</div>
                    <div className={styles.tableCellData}>
                      {subBrand?.specialties?.map((specialty) => (
                        <>{specialty?.label + ', '} </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}

          {/* {tableHeader('Brand Information')}
          <div className={styles.table}>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Brand ID</div>
                <div className={styles.tableCellData}>John</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Brand Name</div>
                <div className={styles.tableCellData}>John</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>About Brand</div>
                <div className={styles.tableCellData}>John</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Industry</div>
                <div className={styles.tableCellData}>John</div>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>URL</div>
                <div className={styles.tableCellData}>John</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Work Phone</div>
                <div className={styles.tableCellData}>John</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Mobile No.</div>
                <div className={styles.tableCellData}>John</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Categories</div>
                <div className={styles.tableCellData}>John</div>
              </div>
            </div>
          </div> */}

          {tableHeader('Plan Information')}
          <div className={styles.table}>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Current Plan</div>
                <div className={styles.tableCellData}>Starter</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>
                  Subscription Interval
                </div>
                <div className={styles.tableCellData}>Monthly</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>End Date</div>
                <div className={styles.tableCellData}>11/02/2021</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Users</div>
                <div className={styles.tableCellData}>1 User</div>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}># Active Campaigns</div>
                <div className={styles.tableCellData}>3 Campaigns</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>
                  # Marketplace Applications
                </div>
                <div className={styles.tableCellData}>50 Influencers</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>
                  Marketplace Visibility
                </div>
                <div className={styles.tableCellData}>30 days</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Categories</div>
                <div className={styles.tableCellData}>50 Influencers</div>
              </div>
            </div>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}># Campaign Pitches</div>
                <div className={styles.tableCellData}>50 Influencers</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Reporting</div>
                <div className={styles.tableCellData}></div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Pitch Influencers</div>
                <div className={styles.tableCellData}>Yes</div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Private Campaigns</div>
                <div className={styles.tableCellData}>No</div>
              </div>
            </div>
          </div>
          {tableHeader('Notification Preferences')}
          <div className={styles.table}>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Emails</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.notification_preferences?.emails
                    ? brandDetails?.notification_preferences?.emails
                    : 'Not Configured'}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>
                  Transaction Status Updates
                </div>
                <div className={styles.tableCellData}>
                  {brandDetails?.notification_preferences?.transaction_status
                    ? brandDetails?.notification_preferences?.transaction_status
                    : 'Not Configured'}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>New Message</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.notification_preferences?.new_message
                    ? brandDetails?.notification_preferences?.new_message
                    : 'Not Configured'}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>
                  New Marketplace Offers
                </div>
                <div className={styles.tableCellData}>
                  {brandDetails?.notification_preferences?.marketplace_offers
                    ? brandDetails?.notification_preferences?.marketplace_offers
                    : 'Not Configured'}
                </div>
              </div>
              <div className={styles.tableCell}>
                <div className={styles.tableCellTitle}>Push Subscriptions</div>
                <div className={styles.tableCellData}>
                  {brandDetails?.notification_preferences?.push_notifications
                    ? brandDetails?.notification_preferences?.push_notifications
                    : 'Not Configured'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetails;
