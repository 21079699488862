import Joi from 'joi';

const influencerSignUpSchema = Joi.object({
  first_name: Joi.string().min(4).max(50).required().messages({
    'string.empty': 'First name cannot be empty.',
  }),
  last_name: Joi.string().min(4).max(50).required().messages({
    'string.empty': 'Last name cannot be empty.',
  }),
  email: Joi.string()
    .max(100)
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      'string.base': 'Email should be a string.',
      'string.empty': 'Email cannot be empty.',
      'string.email': 'Invalid email format.',
      'string.max': 'Email should not exceed {#limit} characters.',
      'any.required': 'Email is required.',
    }),
  phone_no: Joi.string()
    .length(10)
    .pattern(/^[0-9]+$/, 'numbers')
    .required()
    .messages({
      'string.empty': 'Phone number cannot be empty.',
      'string.base': 'Phone number should be a string of numbers.',
      'string.length': 'Phone number should have exactly {#limit} digits.',
      'string.pattern.base': 'Phone number should only contain numbers.',
      'any.required': 'Phone number is required.',
    }),

  password: Joi.string().min(8).max(200).required().messages({
    'string.empty': 'Password cannot be empty.',
  }),

  referral_code: Joi.string().min(0).max(6),

  web_url: Joi.string().uri().messages({
    'string.base': 'Web URL should be a string.',
    'string.uri': 'Invalid URL format.',
  }),
});

const brandSignUpSchema = Joi.object({
  brand_name: Joi.string().max(50).required().messages({
    'string.base': 'Brand name should be a string.',
    'string.empty': 'Brand name cannot be empty.',
    'string.max': 'Brand name should not exceed {#limit} characters.',
    'any.required': 'Brand name is required.',
  }),
  email: Joi.string()
    .max(100)
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      'string.base': 'Email should be a string.',
      'string.empty': 'Email cannot be empty.',
      'string.email': 'Invalid email format.',
      'string.max': 'Email should not exceed {#limit} characters.',
      'any.required': 'Email is required.',
    }),
  web_url: Joi.string().uri().messages({
    'string.base': 'Web URL should be a string.',
    'string.uri': 'Invalid URL format.',
  }),
  password: Joi.string().min(8).max(200).required().messages({
    'string.empty': 'Password cannot be empty.',
  }),
  referral_code: Joi.string().min(0).max(6),
});

const signInSchema = Joi.object({
  user_type_id: Joi.number().valid(1, 2, 3).required().messages({
    'number.base': 'User type should be a number.',
    'number.invalid': 'Invalid user type.',
    'any.required': 'User type is required.',
  }),
  email: Joi.string()
    .max(100)
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      'string.base': 'Email should be a string.',
      'string.empty': 'Email cannot be empty.',
      'string.email': 'Invalid email format.',
      'string.max': 'Email should not exceed {#limit} characters.',
      'any.required': 'Email is required.',
    }),
  password: Joi.string().max(200).optional().messages({
    'string.base': 'Password should be a string.',
    'string.empty': 'Password cannot be empty.',
    'string.max': 'Password should not exceed {#limit} characters.',
    'any.required': 'Password is required.',
  }),
});

export { influencerSignUpSchema, brandSignUpSchema, signInSchema };
