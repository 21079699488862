import React from 'react';
import profileThumnailDefault from '../../../assets/common/chatSection/profileThumnail.svg';
import chatStyles from '../../../styles/common/chatSection/ChatBubble.module.css';

function ChatBubbleReceiver({ keyId, msg, msgReceiverName, profileThumnail }) {
  return (
    <div className={chatStyles.individual_chat_you} key={keyId}>
      <div className={chatStyles.individual_chat_you_internal}>
        <p className={chatStyles.individual_chat_you_title}>
          {msgReceiverName}
        </p>
        <div className={chatStyles.individual_chat_you_msg}>{msg}</div>
      </div>
      <img
        className={chatStyles.individual_chat_you_logo}
        src={profileThumnail ? profileThumnail : profileThumnailDefault}
        alt=""
      />
    </div>
  );
}

export default ChatBubbleReceiver;
