import React from 'react';
import styles from '../../../styles/brand/referral/Referral.module.css';
import tableStyles from '../../../styles/brand/commonStyle/commonTable.module.css';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';

function ReferralInfluencers() {
  const referralHistoryData = [
    {
      title: 'Referral Given',
      value: 55,
      color: '#e3c700',
    },
    {
      title: 'Converted',
      value: 55,
      color: '#2a76f4',
    },
    {
      title: 'Not Converted',
      value: 55,
      color: '#49a240',
    },
  ];

  const allInfluencerReferrals = [
    {
      id: '1',
      influencerName: 'John',
      socialPlatform: 'Instagram',
      platformLink: 'www.instagram.com',
      contactNo: '9990179856',
      referralDate: '05/Jan/2022',
      status: 'Converted',
    },
    {
      id: '2',
      influencerName: 'John',
      socialPlatform: 'Facebook',
      platformLink: 'www.facebook.com',
      contactNo: '9990179856',
      referralDate: '05/Jan/2022',
      status: 'Non Converted',
    },
    {
      id: '3',
      influencerName: 'John',
      socialPlatform: 'Twitter',
      platformLink: 'www.twitter.com',
      contactNo: '9990179856',
      referralDate: '05/Jan/2022',
      status: 'Converted',
    },
    {
      id: '4',
      influencerName: 'John',
      socialPlatform: 'Instagram',
      platformLink: 'www.instagram.com',
      contactNo: '9990179856',
      referralDate: '05/Jan/2022',
      status: 'Non Converted',
    },
  ];

  const uniqueStatus = ['Converted', 'Non Converted'];

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'INFLUENCER NAME',
      placeHolder: 'INFLUENCER NAME',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'SOCIAL PLATFORM',
      placeHolder: 'SOCIAL PLATFORM',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'PLATFORM LINK',
      placeHolder: 'PLATFORM LINK',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'CONTACT NO',
      placeHolder: 'CONTACT NO',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'REFERRAL DATE',
      placeHolder: 'REFERRAL DATE',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Status',
      placeHolder: 'Status',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: true,
    },
  ];
  const statusColorClass = {
    Converted: styles.status_converted,
    'Non Converted': styles.status_non_converted,
  };
  return (
    <div className={styles.referralMain}>
      <div className={styles.topCardsContainer}>
        {referralHistoryData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}>
          <div className={styles.code__button}>
            <div></div>
            <div className={styles.code__title}>Your Referral code is</div>
            <div className={styles.modal_button_container}>
              <div className={styles.modal_button}>Add Referral</div>
            </div>
          </div>
          <div className={styles.code__number}>#33345GhIF</div>
        </div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {allInfluencerReferrals.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.influencerName}</td>
                    <td>{item.socialPlatform}</td>
                    <td>{item.platformLink}</td>
                    <td>{item.contactNo}</td>
                    <td>{item.referralDate}</td>
                    <td className={statusColorClass[item.status]}>
                      {item.status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferralInfluencers;
