// import React from 'react';

// function PostedLinks() {
//   return <div>PostedLinks</div>;
// }

// export default PostedLinks;

import React from 'react';
import tableStyles from '../../../styles/brand/commonStyle/commonTable.module.css';
import styles from '../../../styles/brand/reports/PostedLinks.module.css';
import { useNavigate } from 'react-router-dom';

function PostedLinks() {
  const navigate = useNavigate();
  const campaignTxnSummaryData = [
    {
      id: 1,
      date: '2022-01-01',
      amount: 1000,
      status: 'Processed',
      txn_id: 'TXN00001',
    },
    {
      id: 2,
      date: '2022-01-05',
      amount: 2000,
      status: 'Under Process',
      txn_id: 'TXN00002',
    },
    {
      id: 3,
      date: '2022-01-10',
      amount: 1500,
      status: 'Processed',
      txn_id: 'TXN00003',
    },
    {
      id: 4,
      date: '2022-01-15',
      amount: 3000,
      status: 'Under Process',
      txn_id: 'TXN00004',
    },
    {
      id: 5,
      date: '2022-01-20',
      amount: 2500,
      status: 'Processed',
      txn_id: 'TXN00005',
    },
  ];

  const uniqueStatus = ['All', 'Processed', 'Under Process'];

  let tableSchema = [
    {
      fieldName: 'ID',
      placeHolder: 'ID',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Date',
      placeHolder: 'Date',
      searchType: 'dateRange',
      searchHeader: true,
    },
    {
      fieldName: 'Amount',
      placeHolder: 'Amount',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Status',
      placeHolder: 'Status',
      searchType: 'select',
      options: ['All', 'Processed', 'Under Process'],
      searchHeader: true,
    },
    {
      fieldName: 'Txn ID',
      placeHolder: 'Txn ID',
      searchType: 'textInput',
      searchHeader: true,
    },
  ];

  const statusColorClass = {
    Processed: styles.status_process,
    'Under Process': styles.under_process,
  };

  return (
    <div className={styles.postedLinks}>
      <div className={styles.postedLinks_header}>
        <h3>Posted Links</h3>
      </div>

      <div
        className={
          tableStyles.tabularContentContainer +
          ' ' +
          styles.tabularContentContainer
        }
      >
        <div className={tableStyles.table_container}>
          {campaignTxnSummaryData.length < 0 ? (
            <table className={tableStyles.common_table}>
              <thead>
                <tr className={tableStyles.filterSearch}>
                  {tableSchema.map((column, index) => {
                    return (
                      column.searchHeader &&
                      (column.searchType === 'textInput' ? (
                        <th key={index}>
                          <input
                            type="text"
                            placeholder={column.placeHolder}
                          ></input>
                        </th>
                      ) : (
                        <th key={index}>
                          <select name="" id="">
                            {uniqueStatus.map((optionValue, index) => {
                              return (
                                <option key={index} value={optionValue}>
                                  {optionValue}
                                </option>
                              );
                            })}
                          </select>
                        </th>
                      ))
                    );
                  })}
                </tr>
                <tr className={tableStyles.common_tableHeader}>
                  {tableSchema.map((column, index) => {
                    return <th key={index}>{column.fieldName}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {campaignTxnSummaryData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.date}</td>
                      <td>{item.amount}</td>
                      <td className={statusColorClass[item.status]}>
                        {item.status}
                      </td>
                      <td>{item.txn_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className={styles.no_data}>
              <p>
                No data found. Boo!{' '}
                <span
                  onClick={() => {
                    navigate('/brand/create_campaign');
                  }}
                >
                  Create a campaign
                </span>{' '}
                so you can start interacting with influencers and complete
                transactions. Then you'll see data here!
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PostedLinks;
