import React, { useState } from 'react';
import styles from '../../../../styles/brand/offers/oneInfluencerOffer/OneInfluencerOffer.module.css';

import instagramIcon from '../../../../assets/socialmediaIcons/white-filled/instagram.svg';
import facebookIcon from '../../../../assets/socialmediaIcons/white-filled/facebook.svg';
import twitterIcon from '../../../../assets/socialmediaIcons/white-filled/twitter.svg';

import userIcon from '../../../../assets/brand/influencerProfile/user.svg';
import userWhiteIcon from '../../../../assets/brand/influencerProfile/user-white.svg';

import homeIcon from '../../../../assets/brand/influencerProfile/home.svg';

import homeWhiteIcon from '../../../../assets/brand/influencerProfile/home-white.svg';

import Icon1 from '../../../../assets/brand/influencerProfile/Icon1.jpg';
import Icon2 from '../../../../assets/brand/influencerProfile/Icon2.jpg';
import Icon3 from '../../../../assets/brand/influencerProfile/Icon3.jpg';
import Icon4 from '../../../../assets/brand/influencerProfile/Icon4.jpg';
import Icon5 from '../../../../assets/brand/influencerProfile/Icon5.jpg';
import Icon6 from '../../../../assets/brand/influencerProfile/Icon6.jpg';
import Icon7 from '../../../../assets/brand/influencerProfile/Icon7.jpg';
import Icon8 from '../../../../assets/brand/influencerProfile/Icon8.jpg';

import SocialMediaTag from '../../helperComponents/SocialMediaTag';

function OneInfluencerOffer() {
  const influencerData = {
    photos: [Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8],
  };

  // users interest
  const usersInterest = [
    'Electronics & Apps',
    'Entertainment',
    'Fitness',
    'Pets',
    'Travel',
  ];

  // socialMedia followers
  const socialMediaFollowers = [
    {
      name: 'Instagram',
      icon: instagramIcon,
      count: 10000000,
      socialLink: 'https://www.instagram.com/',
    },
    {
      name: 'Facebook',
      icon: facebookIcon,
      count: 10000000,
      socialLink: 'https://www.facebook.com/',
    },
    {
      name: 'Twitter',
      icon: twitterIcon,
      count: 10000000,
      socialLink: 'https://twitter.com/',
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  // Top tabs of profile
  const tabs = [
    {
      name: 'Description',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 0,
    },

    {
      name: 'About the Influencer',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 1,
    },
    {
      name: 'Photos',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 2,
    },
  ];

  return (
    <div className={styles.InfluencerProfile}>
      <div className={styles.details}>
        {/* left section */}
        <div className={styles.details_left}>
          <div className={styles.details_card}>
            <div className={styles.details_card_profile}>
              <img src={Icon1} alt="profile" />
              <div className={styles.details_card_ReachAndSocial}>
                <div className={styles.details_card_Reach}>23M Reach</div>
                <div className={styles.details_card_Social}>
                  {/** top three social media */}

                  {socialMediaFollowers
                    .sort((a, b) => b.count - a.count)
                    .slice(0, 3)
                    .map((socialMedia, index) => (
                      // <SocialMediaTag {...socialMedia} />
                      <button key={index}>
                        <img src={socialMedia.icon} alt="socialmedia" />
                      </button>
                    ))}
                </div>
              </div>
            </div>
            <div className={styles.offer_price}>
              <img src={userWhiteIcon} alt="interest" /> <span>Price : 20</span>
            </div>
          </div>
          <div className={styles.details_card_makeOffer}>Send Pitch (20)</div>
        </div>
        <div className={styles.details_right}>
          <div className={styles.sectionSwitcher}>
            {
              // tabs
              tabs.map((tab, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setActiveTab(tab.tabNumber);
                  }}
                  className={activeTab === tab.tabNumber ? styles.active : ''}
                >
                  <img
                    src={
                      activeTab === tab.tabNumber ? tab.activeIcon : tab.icon
                    }
                    alt="tabs"
                  />
                  <span>{tab.name}</span>
                </button>
              ))
            }
          </div>
          {activeTab === 0 && (
            <div className={styles.profile_details}>
              <h3>About This offer</h3>
              <h2>Hello</h2>
              <ul>
                <li>
                  <img src={userIcon} alt="user" />
                  My name is <span>Abhi Patel</span>. I am an Instagram Hungama.
                </li>
                <li>
                  <img src={userIcon} alt="user" />
                  My instagram link -
                  <a href="https://www.instgram.com/_abhipatel777_1">
                    Instagram
                  </a>
                </li>
                <li>
                  <img src={userIcon} alt="user" />
                  My instagram User Id <span>@_abhipatel777_1</span>
                </li>
                <li>
                  <img src={userIcon} alt="user" />
                  My website
                  <a href="https://abhiyt.blogspot.com">
                    https://abhiyt.blogspot.com
                  </a>
                </li>
                <li>
                  <img src={userIcon} alt="user" />
                  My current instagram followers are <span>100k+</span>
                </li>
              </ul>
              <hr />

              <div className={styles.content}>
                <p>
                  Access the post and lots of comments on the post. I can easily
                  promote any item by pictures.
                </p>
                <p>
                  You can sponsor and collaborate with me, you can also sponsor
                  me
                </p>
                <p>
                  I came across your profile and found your products very
                  attractive.
                </p>
                <p>
                  Whatever you give me the product, I will show it well on my
                  Instagram, whoever wants to buy the product, I will tell you
                </p>
                <p>
                  I want you to cooperate with me, I will show you a good
                  collage, thank you I am interested in collaborating with you
                  and if you are interested
                </p>
                <p> Please reply to this message</p>
                <p> Thanks and regards</p>
                <p> My contact details are -</p>
                <p> Email: - contactabhiyt@gmail.com</p>
              </div>
              <div className={styles.interest_container}>
                {usersInterest.map((interest, index) => (
                  <div key={index} className={styles.interest}>
                    {interest}
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeTab === 1 && (
            <div>
              <h3>About the Hungama</h3>
              <div className={styles.details_scoialInfo}>
                {
                  // socialMedia followers
                  socialMediaFollowers.map((socialMedia, index) => (
                    <SocialMediaTag key={index} {...socialMedia} />
                  ))
                }
              </div>
              <h3>Bio</h3>
              <p>
                Hello,I am Abhi Patel and I'm a digital creator I love to review
                Lifestyle, and fashion,
              </p>
              <p>
                I found your brand a perfect fit for my niche. I just came
                across your products and I feel it would be a great fit for my
                audience who love these types of products. I would be able to
                put together an honest review on my Instagram as well as on the
                product page of amazon. If you are interested in offering my
                viewers a discount or promotional code I would be happy to
                include that in the post.
              </p>
              <p>
                If you require any additional information I would be happy to
                provide it.
              </p>
              <p>My mail address is below.</p>
              <p>website:- https://abhiyt.blogspot.com/</p>
              <p>Email:- contactabhiyt@gmail.com</p>
              <p>
                Instagram :- https://www.instagram.com/abhipatel777/ [
                @abhipatel777 ]
              </p>
              <p>Thank you for your time &gt;</p>
            </div>
          )}

          {activeTab === 2 && (
            <div className={styles.profile_photos_album}>
              {
                // photos
                influencerData.photos.map((photo, index) => (
                  <div key={index}>
                    <img src={photo} alt="portfolio" />
                  </div>
                ))
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OneInfluencerOffer;
