import React, { useState } from 'react';
import sectionTablestyles from '../../../styles/influencer/myProfile/SectionTablestyles.module.css';
import styles from '../../../styles/influencer/myProfile/MyProfile.module.css';
import ColumnTable from './ColumnTable';
import PencilIcon from '../../../assets/common/Pencil-alt-blue.svg';
import PersonalInfo from './forms/PersonalInfo';
import Axios from '../../../axios';
import { useDispatch, useSelector } from 'react-redux';
import AboutYou from './forms/AboutYou';
import CampaignCriteria from './forms/CampaignCriteria';
import NotificationPref from './forms/NotificationPref';
import SocialAccounts from './forms/SocialAccounts';
import { setOpenForm } from '../../../redux/reducers/influencer/profileSlice';
import ChangePasswordForm from './forms/ChangePasswordForm';
import PhotoAlubm from './forms/PhotoAlubm';

function MyProfile() {
  const allProfileData = useSelector((state) => state.influencerProfile);
  const dispatch = useDispatch();

  const [albumImageSrc, setAlbumImageSrc] = useState([]);
  const [albumImageSrcIds, setAlbumImageSrcIds] = useState([]);

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  let personalInfoData = [
    {
      value1: 'Profile Photo',
      value2: allProfileData?.personal_info?.image_url,
    },
    {
      value1: 'First Name',
      value2: allProfileData?.personal_info?.first_name,
    },
    {
      value1: 'Last Name',
      value2: allProfileData?.personal_info?.last_name,
    },
    {
      value1: 'Email',
      value2: allProfileData?.personal_info?.email,
    },
    {
      value1: 'Dob',
      value2: dateStringToDate(allProfileData?.personal_info?.dob),
    },
    {
      value1: 'Mobile Phone No.',
      value2: allProfileData?.personal_info?.mobile_no,
    },
    {
      value1: 'Whatsapp No.',
      value2: allProfileData?.personal_info?.whatsapp_no,
    },
    {
      value1: 'Geography',
      value2: allProfileData?.personal_info?.geography_id?.label,
    },
    {
      value1: 'Country',
      value2: allProfileData?.personal_info?.country_id?.label,
    },
    {
      value1: 'State / Province',
      value2: allProfileData?.personal_info?.state_id?.label,
    },
    {
      value1: 'City',
      value2: allProfileData?.personal_info?.city_id?.label,
    },
    {
      value1: 'Zip / Postal Code',
      value2: allProfileData?.personal_info?.zip_code,
    },
    {
      value1: 'Timezone',
      value2: allProfileData?.personal_info?.time_zone,
    },
    {
      value1: 'Gender',
      value2: allProfileData?.personal_info?.gender?.label,
    },
    {
      value1: 'Languages',
      value2: allProfileData?.personal_info?.languages
        ?.map((language) => language.label)
        .join(', '),
    },
    {
      value1: 'Specialities',
      value2: allProfileData?.personal_info?.specialties
        ?.map((specialty) => specialty.label)
        .join(', '),
    },
  ];

  let campaignCriteriaData = [
    {
      value1: 'Min Cash Payment',
      value2: allProfileData?.campaign_criteria?.min_cash_payment,
    },
    {
      value1: 'Min Products value',
      value2: allProfileData?.campaign_criteria?.min_product_payment,
    },
  ];

  let aboutYouData = [
    {
      value1: 'Bio',
      value2: allProfileData?.about_you?.bio,
    },
  ];
  let socialAccountsData = [
    // {
    //
    //   value1: 'Facbook',
    //   value2: 'Ajay',
    //   value3: 'facbook.com/hungamainfluencers',
    //   value4: '400M',
    // },
    // {
    //   value1: 'Twitter',
    //   value2: 'Vijay',
    //   value3: 'twitter.com/hungamainfluencers',
    //   value4: '20M',
    // },
  ];

  allProfileData?.social_accounts?.forEach((item) => {
    socialAccountsData.push({
      value1: item.social_platform,
      value2: item.referance_tag,
      value3: item.social_account_link,
      value4: item.followers_count,
    });
  });

  const convertToAllowBlock = (value) => {
    if (value === null || value === undefined) {
      return 'Not Configured';
    }
    if (value) {
      return 'Allow';
    } else {
      return 'Block';
    }
  };

  let notificationPreferencesData = [
    {
      value1: 'Emails',
      value2: convertToAllowBlock(
        allProfileData?.notification_preferences?.emails
      ),
    },
    {
      value1: 'Transaction Status Updates',
      value2: convertToAllowBlock(
        allProfileData?.notification_preferences?.transaction_status
      ),
    },
    {
      value1: 'New Message',
      value2: convertToAllowBlock(
        allProfileData?.notification_preferences?.new_message
      ),
    },
    {
      value1: 'New Marketplace Offers',
      value2: convertToAllowBlock(
        allProfileData?.notification_preferences?.new_marketplace_offers
      ),
    },
    {
      value1: 'Push Subscriptions',
      value2: convertToAllowBlock(
        allProfileData?.notification_preferences?.push_notifications
      ),
    },
  ];

  const sectionComponent = (sectionHeading, tableData, formSetter) => {
    return (
      <div className={sectionTablestyles.section}>
        <div className={sectionTablestyles.sectionHeading}>
          <span>{sectionHeading}</span>
          <button
            onClick={() => {
              dispatch(setOpenForm(sectionHeading));
            }}
          >
            <img src={PencilIcon} alt="PencilIcon" />
            Edit
          </button>
        </div>
        <ColumnTable tableData={tableData} />
      </div>
    );
  };

  const [section, setSection] = useState('My Account');

  return (
    <div className={styles.myProfile}>
      {allProfileData?.openForm === 'Personal Information' && (
        <PersonalInfo preFiledData={allProfileData.personal_info} />
      )}
      {allProfileData?.openForm === 'About You' && (
        <AboutYou preFiledData={allProfileData.about_you} />
      )}
      {allProfileData?.openForm === 'Campaign Criteria' && (
        <CampaignCriteria preFiledData={allProfileData.campaign_criteria} />
      )}
      {allProfileData?.openForm === 'Social Accounts' && (
        <SocialAccounts preFiledData={allProfileData.social_accounts} />
      )}
      {allProfileData?.openForm === 'Notification Preferences' && (
        <NotificationPref
          preFiledData={allProfileData.notification_preferences}
        />
      )}
      {allProfileData?.openForm === 'Change Password' && (
        <ChangePasswordForm formTitle={'ChangePassword'} />
      )}

      <div className={styles.header}>
        <div className={styles.title}>{section}</div>
        <div className={styles.header_right}>
          <div
            className={styles.header_button}
            onClick={() => {
              if (section === 'Photo Album') {
                setSection('My Account');
              } else {
                setSection('Photo Album');
              }
            }}
          >
            {section === 'Photo Album' ? 'My Account' : 'Photo Album'}
          </div>
          <div
            className={styles.header_button}
            onClick={() => {
              dispatch(setOpenForm('Change Password'));
            }}
          >
            Change Password
          </div>
        </div>
      </div>
      {section === 'My Account' && (
        <div className={sectionTablestyles.columns}>
          <div className={sectionTablestyles.column}>
            {sectionComponent('Personal Information', personalInfoData)}
            {sectionComponent('Campaign Criteria', campaignCriteriaData)}
          </div>
          <div className={sectionTablestyles.column}>
            {sectionComponent('Social Accounts', socialAccountsData)}
            {sectionComponent(
              'Notification Preferences',
              notificationPreferencesData
            )}
            {sectionComponent('About You', aboutYouData)}
          </div>
        </div>
      )}
      {section === 'Photo Album' && (
        <PhotoAlubm
          preFiledData={allProfileData.asset}
          albumImageSrcIds={albumImageSrcIds}
          albumImageSrc={albumImageSrc}
          setAlbumImageSrcIds={setAlbumImageSrcIds}
          setAlbumImageSrc={setAlbumImageSrc}
        />
      )}
    </div>
  );
}

export default MyProfile;
