import React, { useEffect, useState } from 'react';
import styles from '../../../../styles/influencer/influencer/influencerProfile/PhotoAlbum.module.css';
import uploadBanner from '../../../../assets/brand/influencerProfile/uploadBanner.svg';
import uploadPhoto from '../../../../assets/brand/influencerProfile/uploadPhoto.svg';
import { useUploadAsset } from './ProfileUpdateControler';
import { downloadAsset } from '../../../../generalApiCalls/asset';
import getProfile from '../../../../redux/reducers/brand/thunk';
import { useDispatch } from 'react-redux';

function PhotoAlubm({
  preFiledData,
  albumImageSrcIds,
  albumImageSrc,
  setAlbumImageSrcIds,
  setAlbumImageSrc,
}) {
  const uploadAsset = useUploadAsset();
  const dispatch = useDispatch();

  const [bannerImageSrc, setBannerImageSrc] = useState();
  const [profileImageSrc, setProfileImageSrc] = useState();

  const setImageSrc = (imageId, imageCategory, imageType, setterFunction) => {
    console.log('imageId', imageId);
    console.log('imageCategory', imageCategory);
    console.log('imageType', imageType);
    console.log('setterFunction', setterFunction);
    if (imageId === null || imageId === undefined) return;

    downloadAsset(imageId, imageCategory, imageType, 1).then((res) => {
      if (imageCategory === 'ALBUM') {
        console.log('albumImageSrcone', imageId, res);
        if (albumImageSrcIds.includes(res.id)) return;
        setAlbumImageSrcIds([...albumImageSrcIds, res.id]);
        setAlbumImageSrc([...albumImageSrc, res]);
        console.log('albumImageSrc', albumImageSrc);
      } else {
        setterFunction(res);
      }
    });
  };

  useEffect(() => {
    console.log('preFiledData', preFiledData);
    setImageSrc(preFiledData?.banner, 'BANNER', 'IMAGE', setBannerImageSrc);
    setImageSrc(preFiledData?.profile, 'PROFILE', 'IMAGE', setProfileImageSrc);
    preFiledData?.album?.map((item) => {
      setImageSrc(item, 'ALBUM', 'IMAGE', setAlbumImageSrc);
    });
  }, [preFiledData]);

  // useEffect(() => {
  //   console.log('preFiledData', preFiledData);
  //   setImageSrc(preFiledData?.banner, 'BANNER', 'IMAGE', setBannerImageSrc);
  //   setImageSrc(preFiledData?.profile, 'PROFILE', 'IMAGE', setProfileImageSrc);

  //   if (preFiledData?.album && preFiledData.album.length > 0) {
  //     Promise.all(
  //       preFiledData.album.map((item) => {
  //         return new Promise((resolve) => {
  //           setImageSrc(item, 'ALBUM', 'IMAGE', resolve);
  //         });
  //       })
  //     ).then((albumImages) => {
  //       const newAlbumImageSrcIds = albumImages.map((res) => res.id);
  //       setAlbumImageSrcIds([...albumImageSrcIds, ...newAlbumImageSrcIds]);
  //       setAlbumImageSrc([...albumImageSrc, ...albumImages]);
  //       console.log('albumImageSrc', albumImageSrc);
  //     });
  //   }
  // }, [preFiledData]);

  return (
    <div className={styles.InfluencerProfile}>
      <div className={styles.banner}>
        <img src={bannerImageSrc || uploadBanner} alt="banner" />
        {/* <img src={uploadBanner} alt="upload banner" /> */}

        <div className={styles.bannerChange_overlay}>
          <div>
            {bannerImageSrc ? (
              <button
                className={
                  styles.overlay_button + ' ' + styles.delete_photo_button
                }
              >
                Delete Banner
              </button>
            ) : (
              <button
                className={
                  styles.overlay_button + ' ' + styles.upload_photo_button
                }
              >
                Upload Banner
                <input
                  type="file"
                  id="banner_photo_upload"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const image = new Image();
                    image.src = URL.createObjectURL(file);

                    image.onload = () => {
                      const width = image.width;
                      const height = image.height;

                      if (width >= 1000 && height >= 480) {
                        // Dimensions are valid, proceed with uploading
                        uploadAsset(file, 'BANNER', 'IMAGE');
                        dispatch(getProfile());
                      } else {
                        alert(
                          'Invalid dimensions. Please upload an image with dimensions 1000px by 480px.'
                        );
                        // Display an error message or perform appropriate action
                        console.log(
                          'Invalid dimensions. Please upload an image with dimensions 1000px by 480px.'
                        );
                      }
                    };
                  }}
                />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className={styles.details}>
        <div className={styles.details_right}>
          <div className={styles.profile_photos_album}>
            {
              // photos
              albumImageSrc?.map((photo) => (
                <div className={styles.album_photo_container}>
                  <img src={photo} alt="portfolio" />
                  <div className={styles.album_photo_container_overlay}>
                    <button
                      className={
                        styles.overlay_button + ' ' + styles.delete_photo_button
                      }
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            }
            <div className={styles.album_photo_container}>
              <img src={uploadPhoto} alt="portfolio" />
              <div className={styles.album_photo_container_overlay}>
                <button
                  className={
                    styles.overlay_button + ' ' + styles.upload_photo_button
                  }
                >
                  Upload
                  <input
                    type="file"
                    id="album_photo_upload"
                    onChange={(e) => {
                      uploadAsset(e.target.files[0], 'ALBUM', 'IMAGE');
                      dispatch(getProfile());
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoAlubm;
