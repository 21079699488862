import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from '../../../../axios';
import styles from '../../../../styles/brand/influencer/listOfInfluencers/ListOfInfluencers.module.css';
import SocialMediaTag from '../../helperComponents/SocialMediaTag';
import walletIcon from '../../../../assets/brand/influencerProfile/wallet.svg';
import starIcon from '../../../../assets/brand/influencerProfile/star.svg';
import briefcaseIcon from '../../../../assets/brand/influencerProfile/briefcase.svg';
import SearchIcon from '../../../../assets/brand/Search.svg';
import Icon5 from '../../../../assets/brand/influencerProfile/Icon5.jpg';
import { getAllInfluenecersList } from '../../../../generalApiCalls/getAllOffers';

function ListOfInfluencers() {
  const navigate = useNavigate();

  // images on animation
  const [activeImageOnAnimation, setActiveImageOnAnimation] = useState([]);
  // history of image animation
  const [imageWasOnAnimation, setImageWasOnAnimation] = useState([]);

  // number to M, K, B, T, etc
  const numberToMKB = (number) => {
    if (number >= 1000000000000) {
      return (number / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
    }
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return number;
  };

  // fetchAllInfluencerData
  const [allInfleuncerData, setAllInfleuncerData] = useState([]);
  const fetchAllInfluencerData = async () => {
    const response = await getAllInfluenecersList(
      'min_product_payment',
      'ASC',
      '',
      1,
      1000
    );
    console.log('getAllInfluenecersList response ', response);
    // setAllInfleuncerData(response.data);
    let reMappedData = response.data.map((item) => {
      return {
        influencerId: item.id,
        name: item.first_name + ' ' + item.last_name,
        reach: item.reach,
        socialMediaReach: item.influ_social_platform,
        minCashPayment: item.min_cash_payment,
        minProductPayment: item.min_product_payment,
        interests: item.speciality_id,
        rating: 4.5,
      };
    });
    setAllInfleuncerData(reMappedData);
    console.log('reMappedData ', reMappedData);
  };

  const getMasterValues = (pathQuery, path, setterFunction) => {
    if (!setterFunction || !path) {
      //   alert('Please fill all the fields');
      console.log('getMasterValues -> function call Error ');
    } else {
      if (pathQuery) {
        path = path + '?' + pathQuery;
      }

      let config = {
        method: 'get',
        url: '/api/v1/master/' + path,
      };
      Axios.request(config)
        .then((response) => {
          if (response.status === 200) {
            // navigate('/brand/dashboard');
            console.log(response.data.data);
            // changeKeyName
            // const geographyOptions = response.data.data.map((item) => ({
            //   value: item.geography_id,
            //   label: item.geography_name,
            // }));
            const options = response.data.data.map((item) => {
              let option = {};
              Object.keys(item).forEach((key) => {
                if (key.endsWith('_id')) {
                  option['value'] = item[key];
                } else if (key.endsWith('_name')) {
                  option['label'] = item[key];
                }
              });
              return option;
            });
            setterFunction(options);
            console.log(path, pathQuery, options);
            // setGeographyOptions(geographyOptions);
          } else {
            alert(response.data.error);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
    }
  };

  const [geographyOptions, setGeographyOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [specialitiesOptions, setSpecialitiesOptions] = useState([]);

  useEffect(() => {
    getMasterValues(null, 'masterGeography', setGeographyOptions);
    getMasterValues(null, 'masterLanguage', setLanguageOptions);
    getMasterValues(null, 'masterSpecialties', setSpecialitiesOptions);
  }, []);

  useEffect(() => {
    fetchAllInfluencerData();
  }, []);

  // const allInfleuncerData = [
  //   {
  //     influencerId: '1',
  //     name: 'ADAM IDAHAM',
  //     reach: 23000000,
  //     socialMediaReach: {
  //       facebook: 1000,
  //       instagram: 1000,
  //       twitter: 1000,
  //     },
  //     walletAmount: 500,
  //     profession: 'Influencer',
  //     interests: [
  //       'TFashion',
  //       'Beauty',
  //       'Lifestyle',
  //       'TFashion',
  //       'Beauty',
  //       'Lifestyle',
  //     ],
  //     rating: 1,
  //   },
  //   {
  //     influencerId: '2',
  //     name: 'Tyler Greener',
  //     reach: 20400000,
  //     socialMediaReach: {
  //       facebook: 1000,
  //       instagram: 1000,
  //       twitter: 1000,
  //     },
  //     walletAmount: 500,
  //     profession: 'Influencer',
  //     interests: ['Fashion', 'Beauty', 'Lifestyle'],
  //     rating: 4.5,
  //   },
  //   {
  //     influencerId: '3',
  //     name: 'ADAM IDAHAM',
  //     reach: 23000000,
  //     socialMediaReach: {
  //       facebook: 1000,
  //       instagram: 1000,
  //       twitter: 1000,
  //     },
  //     walletAmount: 500,
  //     profession: 'Influencer',
  //     interests: ['Fashion', 'Beauty', 'Lifestyle'],
  //     rating: 4.5,
  //   },
  //   {
  //     influencerId: '4',
  //     name: 'Tyler Greener',
  //     reach: 20400000,
  //     socialMediaReach: {
  //       facebook: 1000,
  //       instagram: 1000,
  //       twitter: 1000,
  //     },
  //     walletAmount: 500,
  //     profession: 'Influencer',
  //     interests: ['Fashion', 'Beauty', 'Lifestyle'],
  //     rating: 4.5,
  //   },
  //   {
  //     influencerId: '5',
  //     name: 'ADAM IDAHAM',
  //     reach: 23000000,
  //     socialMediaReach: {
  //       facebook: 1000,
  //       instagram: 1000,
  //       twitter: 1000,
  //     },
  //     walletAmount: 500,
  //     profession: 'Influencer',
  //     interests: ['Fashion', 'Beauty', 'Lifestyle'],
  //     rating: 4.5,
  //   },
  //   {
  //     influencerId: '6',
  //     name: 'Tyler Greener',
  //     reach: 20400000,
  //     socialMediaReach: {
  //       facebook: 1000,
  //       instagram: 1000,
  //       twitter: 1000,
  //     },
  //     walletAmount: 500,
  //     profession: 'Influencer',
  //     interests: ['Fashion', 'Beauty', 'Lifestyle'],
  //     rating: 4.5,
  //   },
  //   {
  //     influencerId: '7',
  //     name: 'ADAM IDAHAM',
  //     reach: 23000000,
  //     socialMediaReach: {
  //       facebook: 1000,
  //       instagram: 1000,
  //       twitter: 1000,
  //     },
  //     walletAmount: 500,
  //     profession: 'Influencer',
  //     interests: ['Fashion', 'Beauty', 'Lifestyle'],
  //     rating: 4.5,
  //   },
  //   {
  //     influencerId: '8',
  //     name: 'Tyler Greener',
  //     reach: 20400000,
  //     socialMediaReach: {
  //       facebook: 1000,
  //       instagram: 1000,
  //       twitter: 1000,
  //     },
  //     walletAmount: 500,
  //     profession: 'Influencer',
  //     interests: ['Fashion', 'Beauty', 'Lifestyle'],
  //     rating: 4.5,
  //   },
  // ];

  // get influencers data profile image from backend based on influencerId

  function fetchInfluencerProfileImages(influencerId, imageSourceId) {
    // fetch image from backend based on influencerId
    document.getElementById(imageSourceId).src = Icon5;
    // console.log('fetched image for influencerId: ' + influencerId);
  }

  const [searchedInfluencerData, setSearchedInfluencerData] =
    useState(allInfleuncerData);

  function searchOnAllValues(keyword) {
    const searchTerms = keyword.toLowerCase().split(' ');
    const result = allInfleuncerData.filter((influencer) => {
      for (let term of searchTerms) {
        let termFound = false;
        for (let key in influencer) {
          if (
            typeof influencer[key] === 'string' ||
            influencer[key] instanceof Array
          ) {
            if (influencer[key].toString().toLowerCase().includes(term)) {
              termFound = true;
              break;
            }
          } else if (typeof influencer[key] === 'object') {
            for (let subKey in influencer[key]) {
              if (
                influencer[key][subKey].toString().toLowerCase().includes(term)
              ) {
                termFound = true;
                break;
              }
            }
          }
        }
        if (!termFound) {
          return false;
        }
      }
      return true;
    });

    // console.log(filteredInfluencers);
    setSearchedInfluencerData(result);
  }

  function sortInfluencers(property) {
    let result = searchedInfluencerData.sort((a, b) => {
      if (a[property] > b[property]) {
        return 1;
      } else if (a[property] < b[property]) {
        return -1;
      } else {
        return 0;
      }
    });
    console.log(result);
    setSearchedInfluencerData(result);
  }

  const total_influencers = 167518;

  return (
    <div className={styles.listOfInfluencers}>
      <div className={styles.listOfInfluencers_header}>
        <h3>All Influencers</h3>
        <div className={styles.listOfInfluencers_header_searchbar}>
          <div>
            <img src={SearchIcon} alt="search" />
            <input
              type="text"
              placeholder="Search by name or keyword"
              onChange={(e) => {
                searchOnAllValues(e.target.value);
              }}
            />
          </div>
          <div>
            <div className={styles.total_influencers_number}>
              <span>{numberToMKB(total_influencers)}</span>
              <span>influencers</span>
            </div>
            <div className={styles.sorting}>
              <span>sort</span>
              <select
                onChange={(e) => {
                  console.log(e.target.value);
                  sortInfluencers(e.target.value);
                }}
              >
                <option value="name">Name</option>
                <option value="reach">Reach</option>
                <option value="WalletAmount">Wallet Amount</option>
                <option value="profession">Profession</option>
                <option value="rating">Review score</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.listOfInfluencers_cards}>
        {allInfleuncerData.map((item, index) => {
          return (
            <div
              className={styles.listOfInfluencers_card}
              key={index}
              onMouseEnter={() => {
                setImageWasOnAnimation((prev) => {
                  return [...prev, item.influencerId + index];
                });
                setActiveImageOnAnimation((prev) => {
                  return [...prev, item.influencerId + index];
                });
              }}
              onMouseLeave={() => {
                setActiveImageOnAnimation((prev) => {
                  return prev.filter((id) => {
                    return id !== item.influencerId + index;
                  });
                });
              }}
            >
              <div
                className={styles.basicIntro}
                onClick={() => {
                  navigate(`/brand/influencer/${item.influencerId}`);
                }}
              >
                <img
                  // src={fetchInfluencerProfileImages(
                  //   item.influencerId,
                  //   item.influencerId + index
                  // )}
                  src={Icon5}
                  alt="profile"
                  id={item.influencerId + index}
                  className={
                    activeImageOnAnimation.includes(item.influencerId + index)
                      ? styles.profileImageScaleDown
                      : imageWasOnAnimation.includes(
                          item.influencerId + index
                        ) && styles.profileImageScaleNormal
                  }
                />
                <div>
                  <h5 className={styles.basicIntro_name}>{item.name}</h5>
                  <span className={styles.basicIntro_totalReach}>
                    {numberToMKB(item.reach)} Reach
                  </span>
                </div>
              </div>
              <div className={styles.socialTags}>
                {/* {Object.keys(item.socialMediaReach).map((key, index) => {
                  return (
                    <SocialMediaTag
                      key={index}
                      name={key}
                      count={item.socialMediaReach[key]}
                    />
                  );
                })} */}
                {item?.socialMediaReach?.slice(0, 3)?.map((item, index) => {
                  return (
                    <SocialMediaTag
                      key={index}
                      name={item.social_platform}
                      count={item.followers_count}
                    />
                  );
                })}
              </div>
              <div className={styles.interests}>
                {item?.interests?.map((tag, index) => {
                  return (
                    <div key={index}>
                      {
                        specialitiesOptions.find((item) => item.value === tag)
                          ?.label
                      }
                    </div>
                  );
                })}
              </div>
              <div className={styles.card_footer}>
                <div>
                  <img src={walletIcon} alt="wallet" />
                  <span>{item.minCashPayment}</span>
                </div>
                <div className={styles.vertical_separator_div}>
                  <div className={styles.vertical_separator}></div>
                </div>
                <div>
                  <img src={briefcaseIcon} alt="profession" />
                  <span>{item.minProductPayment}</span>
                </div>
                <div className={styles.vertical_separator_div}>
                  <div className={styles.vertical_separator}></div>
                </div>
                <div>
                  <img src={starIcon} alt="rating" />
                  <span>{item.rating}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ListOfInfluencers;
