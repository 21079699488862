import React, { useState } from 'react';
import Loginstyles from '../../../styles/common/Login/Login.module.css';
import styles from '../../../styles/common/Login/Login.module.css';
import Axios from '../../../axios';

const AdminLoginForm = () => {
  const [officialEmail, setOfficialEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    if (officialEmail === '' || password === '') {
      alert('Please fill all the fields');
    } else {
      let loginData = {
        user_type_id: 3,
        email: officialEmail,
        password: password,
      };

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/v1/auth/signin',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(loginData),
      };

      Axios.request(config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            alert('Logged In Successfully');
            const accessToken = response.headers.authorization.split(' ')[1];
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('userTypeId', 3);
            localStorage.setItem('userTypeValue', 'admin');
            // navigate('/influencer');
            // window.location.href = '/influencer';
            window.open('/admin/', '_self');
          }
        })
        .catch((error) => {
          console.log('error ', error);
          if (error.response.data.error.message)
            alert(error.response.data.error.message);
        });
    }
  };

  return (
    <div className={Loginstyles.login}>
      <div className={Loginstyles.card}>
        <div className={Loginstyles.section_name}>
          <span className={Loginstyles.influencer_active}>Admin</span>
        </div>
        <hr className={Loginstyles.healine_hr}></hr>
        <div className={styles.login_form_section}>
          <input
            type="email"
            placeholder="Email ID"
            className={styles.influencer_field}
            value={officialEmail}
            onChange={(e) => setOfficialEmail(e.target.value)}
            required
          />
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            className={styles.influencer_field}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <div className={styles.form_options_div}>
            <div className={styles.checkbox_div}>
              <input
                type="checkbox"
                className={styles.checkbox_input}
                checked={showPassword}
                onChange={togglePasswordVisibility}
              ></input>
              <label className={styles.checkbox_label}>Show password</label>
            </div>
            <p href="\shdhj" className={styles.forgot_password}>
              Forgot Password
            </p>
          </div>
          <div className={styles.bottom_div}>
            <button
              onClick={() => {
                handleLogin();
              }}
              className={styles.login_button}
            >
              Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginForm;
