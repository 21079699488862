import React, { useEffect, useState } from 'react';
import modalStyles from '../../../../styles/brand/commonStyle/commonModal.module.css';
import Select from 'react-select';
import useUpdateFormControler from './ProfileUpdateControler';
import { useDispatch } from 'react-redux';
import { setOpenForm } from '../../../../redux/reducers/brand/profileSlice';

function NotificationPref({ preFiledData }) {
  console.log('preFiledData NotificationPref', preFiledData);

  const dispatch = useDispatch();
  const updateFormControler = useUpdateFormControler();

  const [emails, setEmails] = useState(preFiledData?.emails);
  const [transaction_status, setTransaction_status] = useState(
    preFiledData?.transaction_status
  );
  const [new_message, setNew_message] = useState(preFiledData?.new_message);
  const [marketplace_offers, setMarketplace_offers] = useState(
    preFiledData?.marketplace_offers
  );
  const [push_notifications, setPush_notifications] = useState(
    preFiledData?.push_notifications
  );

  const options = [
    { value: 1, label: 'Allow' },
    { value: 0, label: 'Block' },
  ];

  const allowOrBlockDefaultValue = (defaultValue) => {
    console.log('defaultValue-------------------------', defaultValue);
    if (defaultValue === 1) {
      return options[0];
    }
    if (defaultValue === 0) {
      return options[1];
    }
    return null;
  };

  const formControler = {
    emails: {
      value: emails,
      onChange: setEmails,
      type: 'select',
      label: 'Emails',
      option: options,
      handelChange: setEmails,
      isMulti: false,
      placeholder: 'Emails',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.emails,
      defaultValue: allowOrBlockDefaultValue(preFiledData?.emails),
    },
    transaction_status: {
      value: transaction_status,
      onChange: setTransaction_status,
      type: 'select',
      label: 'Transaction Status',
      option: options,
      handelChange: setTransaction_status,
      isMulti: false,
      placeholder: 'Transaction Status',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.transaction_status,
      defaultValue: allowOrBlockDefaultValue(preFiledData?.transaction_status),
    },
    new_message: {
      value: new_message,
      onChange: setNew_message,
      type: 'select',
      label: 'New Message',
      option: options,
      handelChange: setNew_message,
      isMulti: false,
      placeholder: 'New Message',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.new_message,
      defaultValue: allowOrBlockDefaultValue(preFiledData?.new_message),
    },
    marketplace_offers: {
      value: marketplace_offers,
      onChange: setMarketplace_offers,
      type: 'select',
      label: 'Marketplace Offers',
      option: options,
      handelChange: setMarketplace_offers,
      isMulti: false,

      placeholder: 'Marketplace Offers',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.marketplace_offers,
      defaultValue: allowOrBlockDefaultValue(preFiledData?.marketplace_offers),
    },
    push_notifications: {
      value: push_notifications,
      onChange: setPush_notifications,
      type: 'select',
      label: 'Push Notifications',
      option: options,
      handelChange: setPush_notifications,
      isMulti: false,
      placeholder: 'Push Notifications',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.push_notifications,
      defaultValue: allowOrBlockDefaultValue(preFiledData?.push_notifications),
    },
  };

  const keys = Object.keys(formControler);
  const formControlerGroups = [];

  for (let i = 0; i < keys.length; i += 2) {
    if (i + 1 < keys.length) {
      const group = [formControler[keys[i]], formControler[keys[i + 1]]];
      formControlerGroups.push(group);
    } else {
      const group = [formControler[keys[i]]];
      formControlerGroups.push(group);
    }
  }

  const formSubmitHandler = async () => {
    let data = {};
    for (let key in formControler) {
      if (
        formControler[key].value &&
        formControler[key].value !== formControler[key].initialValue &&
        key !== 'language' &&
        key !== 'specialities'
      ) {
        if (formControler[key].type === 'select') {
          if (formControler[key].isMulti) {
            data[key] = formControler[key].value.map((item) => item.value);
          } else {
            data[key] = formControler[key].value.value;
          }
        } else {
          data[key] = formControler[key].value;
          console.log(
            key,
            formControler[key].value,
            formControler[key].initialValue
          );
        }
      }
    }
    if (Object.keys(data).length === 0) {
      alert('No data to update');
      return;
    }
    console.log('udateData -------- ', data);
    let updateResult = await updateFormControler(
      data,
      'NOTIFICATION_PREFERENCES'
    );
    console.log(updateResult);
  };

  return (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>
            Personal Information
          </div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              dispatch(setOpenForm(''));
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          {formControlerGroups.map((group, index) => {
            return (
              <div className={modalStyles.single_row}>
                {group.map((item, index) => {
                  return (
                    <div>
                      <p>{item.label}</p>

                      {item.type === 'select' ? (
                        <div className={modalStyles.multiSelect}>
                          <Select
                            options={item.option}
                            isMulti={item.isMulti}
                            onChange={item.handelChange}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                width: '100%',
                              }),
                            }}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      ) : (
                        <input
                          type={item.type}
                          name={item.name}
                          id={item.id}
                          value={item.value}
                          onChange={(e) => {
                            item.onChange(e.target.value);
                          }}
                          placeholder={item.placeholder}
                          required={item.required}
                          disabled={item.disabled}
                          error={item.error}
                          helperText={item.helperText}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          {/* <p className={modalStyles.error_message}>
            You can not transfer amount less than $10 Or 100
          </p> */}
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                dispatch(setOpenForm(''));
              }}
            >
              Cancel
            </button>
            <button
              className={modalStyles.btn_request}
              onClick={() => {
                formSubmitHandler();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationPref;
