import React from 'react';
import styles from '../../../styles/admin/payments/PaymentsHistory.module.css';
import tableStyles from '../../../styles/influencer/commonStyle/commonTable.module.css';
import cocaCola from '../../../assets/brand/campaigns/cocaCola.png';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';

function EarningDetails() {
  const campaignSummaryData = [
    { title: 'TOTAL EARNING', value: 55, color: '#e3c700' },
    { title: 'COMPLETED AMOUNT', value: 33, color: '#2a76f4' },
    { title: 'ACTIVE AMOUNT', value: 33, color: '#2a76f4' },
    { title: 'HUNGAMA CREDITS', value: 20, color: '#49a240' },
    { title: 'TOTAL PAID', value: 33, color: '#2a76f4' },
    { title: 'BALANCE', value: 20, color: '#49a240' },
  ];

  const campaignsListData = [
    {
      id: 1,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brandName: 'Coca Cola',
      proposedAmount: 300,
      procesedAmount: 400,
      remark: 'Test',
      status: 'Running',
      brandLogo: cocaCola,
    },
    {
      id: 2,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brandName: 'Coca Cola',
      proposedAmount: 300,
      procesedAmount: 400,
      remark: 'Test',
      status: 'Running',
      brandLogo: cocaCola,
    },
  ];

  const uniqueStatusColorMapping = {
    Draft: '#f16d75',
    Running: '#49a240',
    Pitches: '#1d212f',
    Completed: '#49a240',
  };

  const uniqueStatus = Object.keys(uniqueStatusColorMapping);

  const tableSchema = [
    // {
    //   fieldName: '#',
    //   placeHolder: '#',
    //   searchType: 'textInput',
    //   searchHeader: true,
    // },
    {
      fieldName: 'Campaign Name',
      placeHolder: 'Campaign Name',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Offer Amount',
      placeHolder: 'Offer Amount',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Campaign Amount',
      placeHolder: 'Campaign Amount',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Brand Name',
      placeHolder: 'Brand Name',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Proposed Amount',
      placeHolder: 'Proposed Amount',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Procesed Amount',
      placeHolder: 'Procesed Amount',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Remark',
      placeHolder: 'Remark',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Status',
      placeHolder: 'Status',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: true,
    },
  ];

  const [showModal, setShowModal] = useState(false);

  const popUPModal = (
    <div className={styles.modalMain}>
      <div className={styles.modal_container}>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_title}>Request Payment</div>
          <div
            className={styles.modal_header_close_button}
            onClick={() => {
              setShowModal(false);
            }}
          >
            X
          </div>
        </div>
        <div className={styles.modal_body}>
          <p>Amount in Wallet</p>
          <input type="number" name="" id="" />
          <p>Request Date</p>
          <input type="date" name="" id="" />
          <p>Request Amount</p>
          <input type="number" name="" id="" />
          <p className={styles.error_message}>
            You can not transfer amount less than $10 Or 100
          </p>
        </div>
        <div className={styles.modal_footer}>
          <div className={styles.btn_groups}>
            <button
              className={styles.btn_cancel}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button className={styles.btn_request}>Request</button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.historyMain}>
      {showModal && popUPModal}
      <div className={styles.topCardsContainer}>
        {campaignSummaryData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}></div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {campaignsListData.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* <td>{item.id}</td> */}
                    <td className={tableStyles.brand_name__logo}>
                      <img
                        className={tableStyles.table_image_brand}
                        src={item.brandLogo}
                        alt="brandLogo"
                      />{' '}
                      {item.campaignName}
                    </td>
                    <td>{item.offerAmount}</td>
                    <td>{item.campaignAmount}</td>
                    <td>{item.brandName}</td>
                    <td>{item.proposedAmount}</td>
                    <td>{item.procesedAmount}</td>
                    <td>{item.remark}</td>
                    <td
                      style={{
                        color: uniqueStatusColorMapping[item.status],
                      }}
                    >
                      {item.status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarningDetails;
