import React from 'react';
import styles from '../../../styles/admin/dashboard/DashboardContent.module.css';

import coco from '../../../assets/common/companyLogo/coco.png';
import burger from '../../../assets/common/companyLogo/burger.png';
import mcd from '../../../assets/common/companyLogo/mcd.png';
import kfc from '../../../assets/common/companyLogo/kfc.png';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function DashboardConetnt() {
  // this is the data for currency prefix and suffix
  const currencySuffixAndPrefix = {
    USD: { prefix: '$', suffix: 'USD' },
  };

  const navigate = useNavigate();

  const marketPlaceData = {
    title: 'Marketplace',
    data: [
      {
        title: 'Completed',
        value: 55,
        color: '#e3c700',
        toolpit: 'Total number of campaigns completed',
      },
      {
        title: 'Pitched',
        value: 55,
        color: '#2a76f4',
        toolpit: 'Total number of campaigns pitched to influencers by brand',
      },
      {
        title: 'Accepted',
        value: 55,
        color: '#49a240',
        toolpit: 'Total number of campaigns accepted by influencers',
      },
      {
        title: 'Active',
        value: 55,
        color: '#e3c700',
        toolpit: 'Total number of running campaigns',
      },
      {
        title: 'Draft',
        value: 55,
        color: '#2a76f4',
        toolpit: 'Total number of campaigns in drafted by brand',
      },
      {
        title: 'Need Action',
        value: 55,
        color: '#49a240',
        toolpit: 'Some issue raised need immediate action',
      },
    ],
  };

  const incomeData = {
    title: 'Earnings',
    data: [
      {
        title: 'Income',
        value: 55,
        color: '#e3c700',
      },
      {
        title: 'Payments',
        value: 55,
        color: '#2a76f4',
      },
      {
        title: 'Profit',
        value: 55,
        color: '#49a240',
      },
    ],
  };

  const runningCampaignStatusMapping = {
    Active: styles.campaign_status_active,
    Pitched: styles.campaign_status_pitched,
    Accepted: styles.campaign_status_accepted,
  };
  const runningCampaignsData = [
    {
      id: '1',
      campaignName: 'Coca Cola Zero Calories',
      status: 'Active',
      amount: 30,
      startDate: '01 Jan 2022',
      img: coco,
    },
    {
      id: '2',
      campaignName: 'Burger King',
      status: 'Pitched',
      amount: 30,
      startDate: '01 Jan 2022',
      img: burger,
    },
    {
      id: '3',
      campaignName: "McDonald's",
      status: 'Accepted',
      amount: 30,
      startDate: '01 Jan 2022',
      img: mcd,
    },
    {
      id: '4',
      campaignName: 'KFC',
      status: 'Active',
      amount: 30,
      startDate: '01 Jan 2022',
      img: kfc,
    },
    // {
    //   id: '5',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon2,
    // },
    // {
    //   id: '6',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon5,
    // },
    // {
    //   id: '7',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon2,
    // },
    // {
    //   id: '8',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon5,
    // },
    // {
    //   id: '9',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon2,
    // },
    // {
    //   id: '10',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon5,
    // },
  ];
  const selectedCampaignsData = {
    title: 'Coca Cola Zero Calories',
    category: ['Sports', 'Health'],
    targetAudience: {
      targetAudienceProfile: '18-25, Asian, Graduate',
      targetAudienceNumber: '1000',
      totalEngaged: '2%',
      totalValue: '100',
    },
    Description:
      'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter <br/> Designed by Almora in Austria, this Puma Shoes has',
  };

  function convertStringToHTML(string) {
    var tempContainer = document.createElement('div');
    tempContainer.innerHTML = string;
    return tempContainer.childNodes;
  }

  const oneCardWithNumber = (cardData) => (
    <div className={styles.container_design}>
      <div className={styles.background_overlay}></div>
      <div className={styles.titleBox}>
        <p>{cardData.title}</p>
        <select name="" id="">
          <option value="all">All</option>
          <option value="30">Last 30 days</option>
          <option value="60">Last 60 days</option>
          <option value="90">Last 90 days</option>
        </select>
      </div>
      <div className={styles.contentBoxForNumbers}>
        {cardData.data.map((data, index) => (
          <div
            key={index}
            title={data.toolpit}
            className={styles.cardWithNumber}
          >
            <p>{data.title}</p>
            {data.currency ? (
              <span style={{ color: data.color }}>
                {`
              ${currencySuffixAndPrefix[data.currency].prefix}${data.value}${
                  currencySuffixAndPrefix[data.currency].suffix
                }`}
              </span>
            ) : (
              <span style={{ color: data.color }}>{data.value}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  let runningCampaignsCard = (cardData, index) => (
    <div key={index} className={styles.runningCampaignsCard}>
      <div className={styles.campaignsImg}>
        <img src={cardData.img} alt="" />
      </div>
      <div className={styles.campaignDetails}>
        <div className={styles.runningCampaignsCard_left}>
          <h6>{cardData.campaignName}</h6>
          <p className={runningCampaignStatusMapping[cardData.status]}>
            {cardData.status}
          </p>
        </div>
        <div className={styles.runningCampaignsCard_right}>
          <h6>{cardData.amount}</h6>
          <p>{cardData.startDate}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.dashboardMain}>
      <div className={styles.dashboardMain_firstRow}>
        {oneCardWithNumber(marketPlaceData)}
        {oneCardWithNumber(incomeData)}
      </div>

      <div className={styles.dashboardMain_secondRow}>
        <div className={styles.dashboardMain_secondRow_left}>
          <h3>Running Campaigns</h3>
          {runningCampaignsData.map((data, index) => {
            return runningCampaignsCard(data, index);
          })}
          <button className={styles.view_all_button_container}>View All</button>
        </div>
        <div className={styles.dashboardMain_secondRow_right}>
          <h3>Engagement Details</h3>
          <div className={styles.details}>
            <img src={coco} alt="" />
            <div>
              <h6>{selectedCampaignsData.title}</h6>
              <p>{selectedCampaignsData.category}</p>
              <h6>Target Audience</h6>
              <table>
                <thead>
                  <tr>
                    <th>Target</th>
                    <th>Engage</th>
                    <th>Audience Profile</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {
                        selectedCampaignsData.targetAudience
                          .targetAudienceNumber
                      }
                    </td>
                    <td>{selectedCampaignsData.targetAudience.totalEngaged}</td>
                    <td>
                      {
                        selectedCampaignsData.targetAudience
                          .targetAudienceProfile
                      }
                    </td>
                    <td>{selectedCampaignsData.targetAudience.totalValue}</td>
                  </tr>
                </tbody>
              </table>
              <h6>Description</h6>
              <p>{selectedCampaignsData.Description}</p>
            </div>
          </div>
          <button className={styles.view_all_button_container}>View All</button>
        </div>
      </div>
    </div>
  );
}

export default DashboardConetnt;
