import { createSlice } from '@reduxjs/toolkit';
import { getProfile } from './thunk';

const initialState = {
  personal_info: {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    dob: null,
    image_url: null,
    mobile_no: null,
    whatsapp_no: null,
    geography_id: null,
    country_id: null,
    state_id: null,
    city_id: null,
    zip_code: null,
    time_zone: null,
    gender: null,
    languages: [],
    specialties: [],
  },
  campaign_criteria: {
    min_cash_payment: null,
    min_product_payment: null,
  },
  about_you: {
    bio: null,
  },
  notification_preferences: {
    emails: null,
    transaction_status: null,
    new_message: null,
    marketplace_offers: null,
    push_notifications: null,
  },
  asset: {},
  profileImageBlob: null,
  social_accounts: [],
  openForm: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileData: (state, action) => {
      state.personal_info = action.payload.personal_info;
      state.campaign_criteria = action.payload.campaign_criteria;
      state.about_you = action.payload.about_you;
      state.notification_preferences = action.payload.notification_preferences;
      state.social_accounts = action.payload.social_accounts;
      state.asset = action.payload.asset;
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload;
    },
    setProfileImageBlob: (state, action) => {
      state.profileImageBlob = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the fulfilled action for getProfile
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.personal_info = action.payload.personal_info;
      state.campaign_criteria = action.payload.campaign_criteria;
      state.about_you = action.payload.about_you;
      state.notification_preferences = action.payload.notification_preferences;
      state.social_accounts = action.payload.social_accounts;
      state.asset = action.payload.asset;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setProfileData, setOpenForm, setProfileImageBlob } =
  profileSlice.actions;
export default profileSlice.reducer;
