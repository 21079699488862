import React, { useEffect, useRef } from 'react';
import profileThumnail from '../../../assets/common/chatSection/profileThumnail.svg';
import useIntersection from './useIntersection';
import chatStyles from '../../../styles/common/chatSection/ChatBubble.module.css';

function ChatBubbleSender({
  keyId,
  msg,
  seen,
  msgSenderName,
  profileThumnailSrc,
  messageIdSeenList,
  setMessageIdSeenList,
  socket,
}) {
  const ref = useRef();
  // const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible */

  // useEffect(() => {
  //   if (inViewport && seen === false && !messageIdSeenList.includes(keyId)) {
  //     setMessageIdSeenList([...messageIdSeenList, keyId]);
  //     socket.current.emit('msg-seen', {
  //       messageId: keyId,
  //     });
  //   }
  // }, [inViewport]);

  return (
    <div
      ref={ref}
      className={chatStyles.individual_chat_sender}
      key={keyId}
      id={'msg' + keyId}
    >
      <img
        className={chatStyles.individual_chat_sender_logo}
        src={profileThumnailSrc ? profileThumnailSrc : profileThumnail}
        alt=""
      />
      <div className={chatStyles.individual_chat_sender_internal}>
        <p className={chatStyles.individual_chat_sender_title}>
          {msgSenderName}
        </p>
        <div className={chatStyles.individual_chat_sender_msg}>{msg}</div>
      </div>
    </div>
  );
}

export default ChatBubbleSender;
