import { useDispatch } from 'react-redux';
import Axios from './../axios';

const downloadAsset = async (
  assetId,
  assetCategory,
  assetType,
  userTypeBody
) => {
  console.log(
    'downloadAsset called',
    assetId,
    assetCategory,
    assetType,
    userTypeBody
  );

  let data = {
    assetId: assetId,
    assetCategory: assetCategory,
    assetType: assetType,
  };
  if (userTypeBody) {
    data.userTypeBody = userTypeBody;
  }

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'api/v1/asset/download',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    responseType: 'blob',
    data: data,
  };

  console.log('Token *****', localStorage.getItem('accessToken'));
  console.log('config', config);
  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          const fileBlobType = 'application/' + assetId.split('.').pop();
          const blob = new Blob([response.data], { type: fileBlobType });
          const blobUrl = URL.createObjectURL(blob);
          return blobUrl;
        }
      })
      .catch((error) => {
        console.log('error internal downloadAsset', error);
        return;
      });
  } catch (e) {
    console.log('error downloadAsset ', e);
  }
};

export { downloadAsset };
