import React, { useEffect, useState } from 'react';
import modalStyles from '../../../../styles/brand/commonStyle/commonModal.module.css';
import Select from 'react-select';
import Axios from '../../../../axios';
import { useUpdateFormControler } from './ProfileUpdateControler';
import { useDispatch } from 'react-redux';
import { setOpenForm } from '../../../../redux/reducers/influencer/profileSlice';
function AboutYou({ preFiledData }) {
  //   console.log('preFiledData AboutYou', preFiledData);
  const dispatch = useDispatch();
  const updateFormControler = useUpdateFormControler();

  const [bio, setBio] = useState(preFiledData?.bio);

  const formControler = {
    bio: {
      value: bio,
      onChange: setBio,
      type: 'textarea',
      label: 'Bio',
      placeholder: 'Bio',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
  };

  const keys = Object.keys(formControler);
  const formControlerGroups = [];

  for (let i = 0; i < keys.length; i += 2) {
    if (i + 1 < keys.length) {
      const group = [formControler[keys[i]], formControler[keys[i + 1]]];
      formControlerGroups.push(group);
    } else {
      const group = [formControler[keys[i]]];
      formControlerGroups.push(group);
    }
  }

  const formSubmitHandler = async () => {
    let data = {};
    for (let key in formControler) {
      if (
        formControler[key].value &&
        formControler[key].value !== formControler[key].initialValue &&
        key !== 'language' &&
        key !== 'specialities'
      ) {
        if (formControler[key].type === 'select') {
          if (formControler[key].isMulti) {
            data[key] = formControler[key].value.map((item) => item.value);
          } else {
            data[key] = formControler[key].value.value;
          }
        } else {
          data[key] = formControler[key].value;
          console.log(
            key,
            formControler[key].value,
            formControler[key].initialValue
          );
        }
      }
    }
    if (Object.keys(data).length === 0) {
      alert('No data to update');
      return;
    }
    console.log('udateData -------- ', data);
    let updateResult = await updateFormControler(data, 'ABOUT_YOU');
    console.log(updateResult);
  };

  return (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>
            Personal Information
          </div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              dispatch(setOpenForm(''));
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          {formControlerGroups.map((group, index) => {
            return (
              <div className={modalStyles.single_row}>
                {group.map((item, index) => {
                  return (
                    <div>
                      <p>{item.label}</p>

                      {item.type === 'select' ? (
                        <div className={modalStyles.multiSelect}>
                          <Select
                            options={item.option}
                            isMulti={item.isMulti}
                            onChange={item.handelChange}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                width: '100%',
                              }),
                            }}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      ) : item.type === 'textarea' ? (
                        <textarea
                          type={item.type}
                          name={item.name}
                          id={item.id}
                          value={item.value}
                          onChange={(e) => {
                            item.onChange(e.target.value);
                          }}
                          placeholder={item.placeholder}
                          required={item.required}
                          disabled={item.disabled}
                          error={item.error}
                          helperText={item.helperText}
                        />
                      ) : (
                        <input
                          type={item.type}
                          name={item.name}
                          id={item.id}
                          value={item.value}
                          onChange={(e) => {
                            item.onChange(e.target.value);
                          }}
                          placeholder={item.placeholder}
                          required={item.required}
                          disabled={item.disabled}
                          error={item.error}
                          helperText={item.helperText}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          {/* <p className={modalStyles.error_message}>
            You can not transfer amount less than $10 Or 100
          </p> */}
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                dispatch(setOpenForm(''));
              }}
            >
              Cancel
            </button>
            <button
              className={modalStyles.btn_request}
              onClick={() => {
                formSubmitHandler();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutYou;
