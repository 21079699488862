import React from 'react';
import styles from '../../../styles/brand/sidebar/Sidebar.module.css';
import { useNavigate } from 'react-router-dom';

function ListItem({
  item,
  activeMenuItem,
  setActiveMenuItem,
  sidebarActive,
  link,
}) {
  // Determine whether this item is currently active.
  const isActive = item.id === activeMenuItem;
  // Define the right arrow character for rendering sub-menu items.
  const rightArrow = '>';
  const navigate = useNavigate();

  return (
    <li
      key={item.id}
      className={styles.sidebarOneItem}
      onClick={() => {
        link && navigate(link);
      }}
    >
      <div
        className={`${styles.sidebarOneItemRow} ${
          isActive && styles.sidebarOneItemRow_Active
        }`}
        /**
         * Handle the click event for this item.
         * If the item is already active, set the active menu item to 0 (i.e. no item is active).
         * Otherwise, set the active menu item to the ID of this item.
         */

        onClick={() => {
          setActiveMenuItem(isActive ? 0 : item.id);
        }}
      >
        <span className={styles.shape1}></span>
        <span className={styles.shape2}></span>
        <div
          className={`${styles.sidebarOneItemIconContainer} ${
            isActive && styles.sidebarOneItemIconContainer_active
          }`}
        >
          <img
            className={styles.sidebarOneItemIcon}
            src={item.icon}
            alt={item.name}
          />
        </div>

        <div
          className={`${styles.sidebarOneItemName} ${
            isActive
              ? styles.sidebarOneItem_active_name
              : styles.sidebarOneItem_white
          }`}
        >
          {item.name}
        </div>
        {/* Render a right arrow icon if this item has a sub-menu. */}
        {item.rightIcon && (
          <div
            className={`${styles.sidebarOneItem_white} ${
              isActive
                ? styles.sidebarOneItemRowExpandActive
                : styles.sidebarOneItemRowExpandInactive
            }`}
          >
            {rightArrow}
          </div>
        )}
      </div>
      {/* Render sub-menu items if this item has a sub-menu and the sidebar is active. */}
      {item.subMenu && (
        <ul
          className={`${
            isActive
              ? styles.sidebarOneItemRowActive
              : styles.sidebarOneItemRowInactive
          }`}
        >
          {item.subMenu.map(
            (subItem) =>
              sidebarActive && (
                <li
                  key={subItem.id}
                  className={styles.subMenuItem}
                  onClick={() => {
                    console.log(subItem.link);
                    subItem.link && navigate(subItem.link);
                  }}
                >
                  {/* Render a right arrow icon for each sub-menu item. */}
                  <span className={styles.submenuIcon}>{rightArrow}</span>
                  <div className={styles.sidebarOneItem_white}>
                    {subItem.name}
                  </div>
                </li>
              )
          )}
        </ul>
      )}
    </li>
  );
}

export default ListItem;
