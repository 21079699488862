import React, { useEffect, useState } from 'react';
import modalStyles from '../../../../styles/brand/commonStyle/commonModal.module.css';
import Select from 'react-select';
import Axios from '../../../../axios';
import { useDispatch } from 'react-redux';
import { setOpenForm } from '../../../../redux/reducers/brand/profileSlice';

function ChangePasswordForm({ formTitle }) {
  const dispatch = useDispatch();

  const [password, setPasword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  let formControler = {
    old_password: {
      value: password,
      onChange: setPasword,
      type: 'password',
      label: 'Password',
      placeholder: 'Password',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    new_password: {
      value: newPassword,
      onChange: setNewPassword,
      type: 'password',
      label: 'New Password',
      placeholder: 'New Password',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    confirm_new_password: {
      value: confirmNewPassword,
      onChange: setConfirmNewPassword,
      type: 'password',
      label: 'Confirm New Password',
      placeholder: 'Confirm New Password',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
  };

  const keys = Object.keys(formControler);
  const formControlerGroups = [];

  for (let i = 0; i < keys.length; i += 2) {
    if (i + 1 < keys.length) {
      const group = [formControler[keys[i]], formControler[keys[i + 1]]];
      formControlerGroups.push(group);
    } else {
      const group = [formControler[keys[i]]];
      formControlerGroups.push(group);
    }
  }

  const changePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      alert('New Password and Confirm New Password does not match');
      return;
    }
    if (newPassword.length < 5) {
      alert('Password must be at least 5 characters long');
      return;
    }

    let dataToUpdate = {
      old_password: password,
      new_password: newPassword,
    };

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: '/api/v1/auth/changepassword',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
      data: dataToUpdate,
    };

    Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          alert('Updated Successfully');
          dispatch(setOpenForm(''));
        }
      })
      .catch((error) => {
        console.log('error ', error.response.data);
        if (error.response.data.error.message)
          alert(error.response.data.message);
      });
  };

  return (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>{formTitle}</div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              dispatch(setOpenForm(''));
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          {formControlerGroups.map((group, index) => {
            return (
              <div>
                {group.map((item, index) => {
                  return (
                    <div>
                      <p>{item.label}</p>

                      {item.type === 'select' ? (
                        <div className={modalStyles.multiSelect}>
                          <Select
                            options={item.option}
                            isMulti={item.isMulti}
                            onChange={item.handelChange}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                width: '100%',
                              }),
                            }}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      ) : (
                        <input
                          type={item.type}
                          name={item.name}
                          id={item.id}
                          value={item.value}
                          onChange={(e) => {
                            item.onChange(e.target.value);
                          }}
                          placeholder={item.placeholder}
                          required={item.required}
                          disabled={item.disabled}
                          error={item.error}
                          helperText={item.helperText}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                dispatch(setOpenForm(''));
              }}
            >
              Cancel
            </button>
            <button
              className={modalStyles.btn_request}
              onClick={() => {
                changePassword();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordForm;
