import React from 'react';
import styles from '../../../styles/brand/dashboard/DashboardContent.module.css';
import Hexagon from '../../../assets/brand/Hexagon.svg';

import Campaigns from '../../../assets/brand/dashboard/Campaigns.png';
import offer from '../../../assets/brand/dashboard/offer.png';
import referral from '../../../assets/brand/dashboard/referral.png';
import Icon2 from '../../../assets/brand/dashboard/Icon2.jpg';
import Icon5 from '../../../assets/brand/dashboard/Icon5.jpg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function DashboardConetnt() {
  // this is the data for currency prefix and suffix
  const currencySuffixAndPrefix = {
    USD: { prefix: '$', suffix: 'USD' },
  };

  const navigate = useNavigate();

  // images on animation
  const [activeImageOnAnimation, setActiveImageOnAnimation] = useState([]);
  // history of image animation
  const [imageWasOnAnimation, setImageWasOnAnimation] = useState([]);

  // this is the data for cards
  const allCardData = [
    {
      title: 'Campaigns',
      subTitle:
        'Create your first campaign and pitch influencers of your choice!',
      img: Campaigns,
      linkTitle: 'CREATE NEW CAMPAIGN! >',
      cardType: 'CardWithIcon',
      link: '/brand/create_campaign',
    },
    {
      title: 'Offers',
      subTitle: 'Get started by checking out influencer offers!',
      img: offer,
      linkTitle: 'VIEW INFLUENCER OFFERS! >',
      cardType: 'CardWithIcon',
      link: '/brand/list_of_influencer_offers',
    },
    {
      title: 'Balance',
      cardType: 'CardWithNumbers',
      data: [
        { title: 'Total', value: 500.0, currency: 'USD' },
        { title: 'Spent', value: 350.0, currency: 'USD' },
        { title: 'Available', value: 150.0, currency: 'USD' },
      ],
    },
  ];

  const allInfleuncerData = [
    {
      name: 'ADAM IDAHAM',
      reach: '2k Reach',
      about:
        'Hey you..Have you ever see people that can help you with your product better than',
      img: Icon5,
      influencerId: '1',
    },
    {
      name: 'Tyler Greener',
      reach: '204k Reach',
      about:
        'I am an inspiring Chef, trying to work my ways up towing my own restaurant! ',
      img: Icon2,
      influencerId: '2',
    },
  ];

  const oneCardWithIcon = (cardData) => (
    <div className={styles.container_design}>
      <div className={styles.background_overlay}></div>
      <div className={styles.titleBox}>
        <p>{cardData.title}</p>
      </div>
      <div className={styles.contentBox}>
        <img src={cardData.img} alt="" />
        <div>
          <p>{cardData.subTitle}</p>
          <div
            onClick={() => {
              navigate(cardData.link);
            }}
          >
            {cardData.linkTitle}
          </div>
        </div>
      </div>
    </div>
  );

  const oneCardWithNumber = (cardData) => (
    <div className={styles.container_design}>
      <div className={styles.background_overlay}></div>
      <div className={styles.titleBox}>
        <p>{cardData.title}</p>
      </div>
      <div className={styles.contentBoxForNumbers}>
        {cardData.data.map((data, index) => (
          <div key={index} className={styles.cardWithNumber}>
            <p>{data.title}</p>
            <span>
              {`
              ${currencySuffixAndPrefix[data.currency].prefix}${data.value}${
                currencySuffixAndPrefix[data.currency].suffix
              }`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  const oneInfleuncerCard = (cardData) => (
    <div
      className={styles.oneInfleuncerCard}
      onClick={() => {
        navigate(`/brand/influencer/${cardData.influencerId}`);
      }}
      onMouseEnter={() => {
        setImageWasOnAnimation((prev) => {
          return [...prev, cardData.influencerId];
        });
        setActiveImageOnAnimation((prev) => {
          return [...prev, cardData.influencerId];
        });
      }}
      onMouseLeave={() => {
        setActiveImageOnAnimation((prev) => {
          return prev.filter((id) => {
            return id !== cardData.influencerId;
          });
        });
      }}
    >
      <img
        src={cardData.img}
        className={
          activeImageOnAnimation.includes(cardData.influencerId)
            ? styles.profileImageScaleDown
            : imageWasOnAnimation.includes(cardData.influencerId) &&
              styles.profileImageScaleNormal
        }
        alt={`${cardData.name} infleuncer`}
      />
      <h3>{cardData.name}</h3>
      <span>{cardData.reach}</span>
      <p>{cardData.about}</p>
    </div>
  );

  const oneReferralCard = (
    <div className={styles.oneReferralCard}>
      <img src={referral} alt="referral" />
      <h3>
        Refer other <br /> brands!
      </h3>
      <p>
        Earn Hungama Credits 20% for referring other brands! It's quick and
        simple!
      </p>
      <button
        className={styles.referral_button}
        onClick={() => {
          navigate('/brand/referral_brands');
        }}
      >
        ₹ Earn Hungama Credits
      </button>
    </div>
  );

  return (
    <div className={styles.dashboardMain}>
      <div className={styles.dashboardMain_firstRow}>
        {allCardData.map((cardData, index) => {
          return cardData.cardType === 'CardWithIcon'
            ? oneCardWithIcon(cardData)
            : oneCardWithNumber(cardData);
        })}
      </div>

      <div className={styles.dashboardMain_secondRow}>
        <div className={styles.dashboardMain_secondRow_left}>
          <div className={styles.dashboardMain_secondRow_left_top}>
            <span>Recomended Infleuncer</span>
            <div
              onClick={() => {
                navigate('/brand/list_of_influencers');
              }}
            >
              View All Offers
            </div>
          </div>
          <div className={styles.dashboardMain_secondRow_left_bottom}>
            {allInfleuncerData.map((cardData, index) => {
              return oneInfleuncerCard(cardData);
            })}
          </div>
        </div>
        <div className={styles.dashboardMain_secondRow_right}>
          {oneReferralCard}
        </div>
      </div>
    </div>
  );
}

export default DashboardConetnt;
