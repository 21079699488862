// Importing React and useState hook
import React, { useEffect, useState } from 'react';

// Importing CSS module
import styles from '../../styles/brand/dashboard/Dashboard.module.css';

// Importing components
import DashboardConetnt from './dashboard/DashboardConetnt';
import Sidebar from './sidebar/Sidebar';
import Header from '../common/header/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Campaigns from './campaigns/Campaigns';
import OfferFromHangama from './offers/OfferFromHangama';
import OfferToBrand from './offers/OfferToBrand';
import ReferralBrand from './referral/ReferralBrand';
import ReferralInfluencers from './referral/ReferralInfluencers';
import Messages from './messages/Messages';
import Schedules from './schedules/Schedules';
import PaymentRequest from './wallet/PaymentRequest';
import EarningDetails from './wallet/EarningDetails';
import BadgesList from './badges/BadgesList';
import MyProfile from './myProfile/MyProfile';
import CampaignDetails from './campaigns/campaignDetails/CampaignDetails';

const InfluencerDashboard = () => {
  // Using useState hook to create sidebarActive state and setSidebarActive function
  const [sidebarActive, setSidebarActive] = useState(false);
  const [mobileHeaderHide, setMobileHeaderHide] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);

  const handleResize = () => {
    if (window.innerWidth > 700) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
    console.log('setIsDesktop ', setIsDesktop);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Returning JSX
  return (
    <div className={styles.brand_dashboard}>
      {/* Rendering Sidebar component */}
      <Sidebar
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />

      {/* Container for dashboard content */}
      <div className={styles.brand_dashboard_container}>
        {/* Rendering Header component */}
        <Header
          setSidebarActive={setSidebarActive}
          sidebarActive={sidebarActive}
          mobileHeaderHide={mobileHeaderHide}
          setMobileHeaderHide={setMobileHeaderHide}
          isDesktop={isDesktop}
          setIsDesktop={setIsDesktop}
        />

        {/* Rendering DashboardConetnt component */}

        <div className={styles.brand_dashboard_content}>
          <Routes>
            <Route path="/" element={<DashboardConetnt />} />

            <Route exact path="/campaigns" element={<Campaigns />} />
            <Route
              path="/campaigns/campaign_details/:campaign_id"
              element={<CampaignDetails />}
            />
            <Route exact path="/schedules" element={<Schedules />} />

            <Route exact path="/earning_details" element={<EarningDetails />} />
            <Route
              exact
              path="/payment_requests"
              element={<PaymentRequest />}
            />

            <Route
              exact
              path="/offers_from_hangama"
              element={<OfferFromHangama />}
            />
            <Route exact path="/offers_to_brand" element={<OfferToBrand />} />

            <Route exact path="/messages" element={<Messages />} />

            <Route exact path="/referral_brands" element={<ReferralBrand />} />
            <Route
              exact
              path="/referral_influencers"
              element={<ReferralInfluencers />}
            />
            <Route exact path="/profile" element={<MyProfile />} />
            <Route exact path="/badges_list" element={<BadgesList />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

// Exporting Dashboard component
export default InfluencerDashboard;
