import React, { useEffect } from 'react';
import styles from '../../../styles/influencer/campaigns/Campaigns.module.css';
import tableStyles from '../../../styles/influencer/commonStyle/commonTable.module.css';
import modalStyles from '../../../styles/brand/commonStyle/commonModal.module.css';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';
import {
  getAllCampaigns,
  sendConsentForCampaign,
} from '../../../generalApiCalls/Campaigns';
import { useNavigate } from 'react-router-dom';
// import PencilIcon from '../../../assets/common/Pencil-alt.svg';
import CheckIcon from '../../../assets/common/check-white.svg';
import EyeIcon from '../../../assets/common/eye-white.svg';

function Campaigns() {
  const navigate = useNavigate();
  const campaignSummaryData = [
    { title: 'COMPLETED', value: 55, color: '#e3c700' },
    { title: 'ACTIVE', value: 20, color: '#49a240' },
    { title: 'WAITLIST', value: 33, color: '#2a76f4' },
    { title: 'Approved', value: 55, color: '#000000' },
    { title: 'OPPORTUNITIES', value: 55, color: '#f16d75' },
  ];

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  const [campaignsListData, setCampaignsListData] = useState([]);
  const [totalCampaignsCount, setTotalCampaignsCount] = useState(0);

  const getAllCampaignsData = (sortBy, search, page, limit) => {
    getAllCampaigns('camp_name', '', 1, 10).then((res) => {
      if (res?.data?.length > 0) {
        setTotalCampaignsCount(res.totalSize);
        const reMappedData = res.data.map((item) => {
          return {
            id: item.id,
            campaignName: item.camp_name,
            offerAmount: item.offer_amount,
            campaignAmount: item.campaign_amount,
            brandName: item.brand_name,
            startDate: dateStringToDate(item.start_date),
            endDate: dateStringToDate(item.end_date),
            status: item.status,
            brandLogo: item.logo_path,
          };
        });
        setCampaignsListData(reMappedData);
      }
    });
  };

  useEffect(() => {
    getAllCampaignsData();
  }, []);

  const sendConsentForSelectedCampaign = (campaign_id, status) => {
    console.log('sendConsentForSelectedCampaign');
    sendConsentForCampaign(campaign_id, status).then((res) => {
      console.log(res);
      if (res === true) {
        getAllCampaignsData();
      }
    });
  };

  const uniqueStatusColorMapping = {
    Draft: '#f16d75',
    Running: '#49a240',
    Pitches: '#1d212f',
    Completed: '#49a240',
  };

  const uniqueStatus = Object.keys(uniqueStatusColorMapping);

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Campaign Name',
      placeHolder: 'Campaign Name',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Offer Amount',
      placeHolder: 'Offer Amount',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Campaign Amount',
      placeHolder: 'Campaign Amount',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Brand Name',
      placeHolder: 'Brand Name',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Start Date',
      placeHolder: 'Start Date',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'End Date',
      placeHolder: 'End Date',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'Status',
      placeHolder: 'Status',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: true,
    },
    {
      fieldName: 'Details',
      placeHolder: 'Details',
      searchType: 'textInput',
    },
    {
      fieldName: 'Action',
      placeHolder: 'Action',
      searchType: 'textInput',
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [actionCampaignId, setActionCampaignId] = useState(null);
  const [actionCampaignName, setActionCampaignName] = useState(null);

  const popUPModal = (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>
            {actionCampaignName}
          </div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              setShowModal(false);
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          <p>Do you want accpet / approved this campaign</p>
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                sendConsentForSelectedCampaign(actionCampaignId, 'rejected');
                setShowModal(false);
              }}
            >
              No
            </button>
            <button
              className={modalStyles.btn_request}
              onClick={() => {
                sendConsentForSelectedCampaign(actionCampaignId, 'waitlists');
                setShowModal(false);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.historyMain}>
      {showModal && popUPModal}
      <div className={styles.topCardsContainer}>
        {campaignSummaryData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}></div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {campaignsListData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className={tableStyles.shortColumn}>{item.id}</td>
                    <td>
                      <div className={tableStyles.brand_name__logo}>
                        <img
                          className={tableStyles.table_image_brand_big}
                          src={item.brandLogo}
                          alt="brandLogo"
                        />{' '}
                        {item.campaignName}
                      </div>
                    </td>
                    <td>{item.offerAmount}</td>
                    <td>{item.campaignAmount}</td>
                    <td>{item.brandName}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td
                      className={tableStyles.textAllignCenter}
                      style={{
                        color: uniqueStatusColorMapping[item.status],
                      }}
                    >
                      {item.status}
                    </td>

                    <td className={tableStyles.textAllignCenter}>
                      {/* <button
                        className={tableStyles.details_button}
                        onClick={() => {
                          navigate(`campaign_details/${item.id}`);
                        }}
                      >
                        Details
                      </button> */}
                      <button
                        onClick={() => {
                          navigate(`campaign_details/${item.id}`);
                        }}
                        className={styles.campaigns_table_btn_edit}
                      >
                        <img src={EyeIcon} alt="" />
                      </button>
                    </td>
                    <td className={tableStyles.textAllignCenter}>
                      {/* <button className={tableStyles.details_button}>
                        Action
                      </button> */}
                      <button
                        className={styles.campaigns_table_btn_edit}
                        onClick={() => {
                          setShowModal(true);
                          setActionCampaignId(item.id);
                          setActionCampaignName(
                            item.brandName + ' - ' + item.campaignName
                          );
                        }}
                      >
                        <img src={CheckIcon} alt="" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of {totalCampaignsCount} entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
