import React, { useEffect, useState } from 'react';
import styles from '../../../styles/brand/sidebar/Sidebar.module.css';
import HangamaDesktop from '../../../assets/common/logo-light.png';
import HangamaLight from '../../../assets/common/icon-light.png';
import HomeIcon from '../../../assets/brand/Home.svg';
import ServerIcon from '../../../assets/brand/server.svg';
import ListItem from './ListItem';

const Sidebar = ({ sidebarActive, setSidebarActive }) => {
  // state for active menu item id
  const [activeMenuItem, setActiveMenuItem] = useState(1);
  console.log(
    'console.log(window.location.pathname) ',
    window.location.pathname
  );

  // sidebar menu items data
  const menuItems = [
    { id: 1, name: 'Dashboard', icon: HomeIcon, link: '/brand' },
    { id: 2, name: 'Campaigns', icon: ServerIcon, link: '/brand/campaigns' },
    {
      id: 3,
      name: 'Offers',
      icon: ServerIcon,
      rightIcon: true,
      subMenu: [
        {
          id: 31,
          name: 'Influencer Offers',
          icon: HomeIcon,
          link: '/brand/list_of_influencer_offers',
        },
        {
          id: 32,
          name: 'Hungama Offers',
          icon: HomeIcon,
          link: '/brand/hungama_offers',
        },
      ],
    },
    { id: 4, name: 'Messages', icon: ServerIcon, link: '/brand/messages' },
    {
      id: 5,
      name: 'Referral',
      icon: ServerIcon,
      rightIcon: true,
      subMenu: [
        {
          id: 51,
          name: 'Referral Brand',
          icon: HomeIcon,
          link: '/brand/referral_brands',
        },
        {
          id: 52,
          name: 'Referral Influencers',
          icon: HomeIcon,
          link: '/brand/referral_influencers',
        },
      ],
    },
    {
      id: 6,
      name: 'Reports',
      icon: ServerIcon,
      rightIcon: true,
      subMenu: [
        {
          id: 61,
          name: 'Campaign Summary',
          icon: HomeIcon,
          link: '/brand/campaign_summary',
        },
        {
          id: 62,
          name: 'Campaign Transaction Summary',
          icon: HomeIcon,
          link: '/brand/campaign_transaction_summary',
        },
        {
          id: 63,
          name: 'Posted Links',
          icon: HomeIcon,
          link: '/brand/posted_links',
        },
      ],
    },
    {
      id: 7,
      name: 'Payments',
      icon: ServerIcon,
      rightIcon: true,
      subMenu: [
        {
          id: 71,
          name: 'Payments History',
          icon: HomeIcon,
          link: '/brand/payment_history',
        },
      ],
    },
  ];

  function getIdFromLink(link) {
    for (let item of menuItems) {
      if (item.link === link) {
        return item.id;
      } else if (item.subMenu) {
        for (let subItem of item.subMenu) {
          if (subItem.link === link) {
            return item.id;
          }
        }
      }
    }
    return null;
  }

  useEffect(() => {
    const id = getIdFromLink(window.location.pathname);
    if (id) {
      setActiveMenuItem(id);
    }
  }, []);

  return (
    <div
      onMouseOver={() => setSidebarActive(true)}
      className={sidebarActive ? styles.sidebarActive : styles.sidebar}
    >
      <div className={styles.sidebar_logo_container}>
        <img
          className={styles.sidebar_logo}
          src={sidebarActive ? HangamaDesktop : HangamaLight}
          alt="HangamaLight"
        />
      </div>

      <ul className={styles.sidebarItems}>
        {menuItems.map((item) => (
          <ListItem
            key={item.id}
            item={item}
            activeMenuItem={activeMenuItem}
            setActiveMenuItem={setActiveMenuItem}
            sidebarActive={sidebarActive}
            link={item.link ? item.link : null}
          />
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
