import { useDispatch } from 'react-redux';
import Axios from './../axios';

const getInfluencerDetails = async (influencer_id) => {
  console.log('getInfluencerDetails', 'called');

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'api/v1/brand/influencer/' + influencer_id,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  };
  console.log('Token *****', localStorage.getItem('accessToken'));
  console.log('config', config);
  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        return [];
      });
  } catch (e) {
    console.log('error getCampaignDetails ', e);
  }
};

export { getInfluencerDetails };
