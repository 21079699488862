import React, { useEffect, useState } from 'react';
import styles from '../../../styles/admin/sidebar/Sidebar.module.css';
import HangamaDesktop from '../../../assets/common/logo-light.png';
import HangamaLight from '../../../assets/common/icon-light.png';
import HomeIcon from '../../../assets/brand/Home.svg';
import ServerIcon from '../../../assets/brand/server.svg';
import ListItem from './ListItem';

const Sidebar = ({ sidebarActive, setSidebarActive }) => {
  // state for active menu item id
  const [activeMenuItem, setActiveMenuItem] = useState(1);
  console.log(
    'console.log(window.location.pathname) ',
    window.location.pathname
  );

  // sidebar menu items data
  const menuItems = [
    { id: 1, name: 'Dashboard', icon: HomeIcon, link: '/admin' },
    { id: 2, name: 'Campaigns', icon: ServerIcon, link: '/admin/campaigns' },
    {
      id: 3,
      name: 'Clients',
      icon: ServerIcon,
      link: '/admin/clients',
    },
    {
      id: 4,
      name: 'Influencers',
      icon: ServerIcon,
      link: '/admin/list_of_influencers',
    },
    {
      id: 5,
      name: 'Influencers Offers',
      icon: ServerIcon,
      link: '/admin/list_of_influencer_offers',
    },
    {
      id: 6,
      name: 'Hungama Offers',
      icon: ServerIcon,
      rightIcon: true,
      subMenu: [
        {
          id: 61,
          name: 'Offers to brand',
          icon: HomeIcon,
          link: '/admin/offer_to_brand',
        },
        {
          id: 62,
          name: 'Offers to influencers',
          icon: HomeIcon,
          link: '/admin/offer_to_influencers',
        },
      ],
    },
    {
      id: 7,
      name: 'Payments',
      icon: ServerIcon,
      rightIcon: true,
      subMenu: [
        {
          id: 71,
          name: 'Infleuncer Wise Payments',
          icon: HomeIcon,
          link: '/admin/influencers_wise_payments',
        },
        {
          id: 72,
          name: 'Campaigns Wise Payments',
          icon: HomeIcon,
          link: '/admin/campaigns_wise_payments',
        },
      ],
    },
  ];

  function getIdFromLink(link) {
    for (let item of menuItems) {
      if (item.link === link) {
        return item.id;
      } else if (item.subMenu) {
        for (let subItem of item.subMenu) {
          if (subItem.link === link) {
            return item.id;
          }
        }
      }
    }
    return null;
  }

  useEffect(() => {
    const id = getIdFromLink(window.location.pathname);
    if (id) {
      setActiveMenuItem(id);
    }
  }, []);

  return (
    <div
      onMouseOver={() => setSidebarActive(true)}
      className={sidebarActive ? styles.sidebarActive : styles.sidebar}
    >
      <div className={styles.sidebar_logo_container}>
        <img
          className={styles.sidebar_logo}
          src={sidebarActive ? HangamaDesktop : HangamaLight}
          alt="HangamaLight"
        />
      </div>

      <ul className={styles.sidebarItems}>
        {menuItems.map((item) => (
          <ListItem
            key={item.id}
            item={item}
            activeMenuItem={activeMenuItem}
            setActiveMenuItem={setActiveMenuItem}
            sidebarActive={sidebarActive}
            link={item.link ? item.link : null}
          />
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
