import React, { useEffect, useState } from 'react';
import modalStyles from '../../../../styles/brand/commonStyle/commonModal.module.css';
import Select from 'react-select';
import Axios from '../../../../axios';
import sectionTablestyles from '../../../../styles/influencer/myProfile/SectionTablestyles.module.css';
import {
  useUpdateFormControler,
  useDeleteSocialAccount,
} from './ProfileUpdateControler';
import { useDispatch } from 'react-redux';
import { setOpenForm } from '../../../../redux/reducers/influencer/profileSlice';

function SocialAccounts({ preFiledData }) {
  const dispatch = useDispatch();
  const updateFormControler = useUpdateFormControler();
  const deleteSocialAccount = useDeleteSocialAccount();
  const [newPlatform, setNewPlatform] = useState();
  const [newTag, setNewTag] = useState();
  const [newLink, setNewLink] = useState();
  const [newFollowersCount, setNewFollowersCount] = useState();
  const platformOptions = [
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Twitter', label: 'Twitter' },
    { value: 'TikTok', label: 'TikTok' },
    { value: 'YouTube', label: 'YouTube' },
    { value: 'Snapchat', label: 'Snapchat' },
    { value: 'Pinterest', label: 'Pinterest' },
    { value: 'Twitch', label: 'Twitch' },
    { value: 'LinkedIn', label: 'LinkedIn' },
    { value: 'Blog', label: 'Blog' },
    { value: 'Other', label: 'Other' },
  ];

  let socialAccountsData = [
    // {
    //   id: 1,
    //   platform: 'Facebook',
    //   tag: 'Ajay',
    //   link: 'facbook.com/hungamainfluencers',
    //   followersCount: '400M',
    // },
    // {
    //   id: 2,
    //   platform: 'Twitter',
    //   tag: 'Vijay',
    //   link: 'twitter.com/hungamainfluencers',
    //   followersCount: '20M',
    // },
  ];

  preFiledData?.forEach((item) => {
    socialAccountsData.push({
      id: item.id,
      platform: item.social_platform,
      tag: item.referance_tag,
      link: item.social_account_link,
      followersCount: item.followers_count,
    });
  });

  let formControler = [
    {
      value: newPlatform,
      onChange: setNewPlatform,
      type: 'select',
      label: 'Platform',
      option: platformOptions,
      handelChange: setNewPlatform,
      isMulti: false,
      placeholder: 'Platform',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    {
      value: newTag,
      onChange: setNewTag,
      type: 'text',
      label: 'Tag',
      option: platformOptions,
      handelChange: setNewTag,
      isMulti: false,
      placeholder: 'Tag',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    {
      value: newLink,
      onChange: setNewLink,
      type: 'text',
      label: 'Link',
      option: platformOptions,
      handelChange: setNewLink,
      isMulti: false,
      placeholder: 'Link',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
    {
      value: newFollowersCount,
      onChange: setNewFollowersCount,
      type: 'number',
      label: 'Followers Count',
      option: platformOptions,
      handelChange: setNewFollowersCount,
      isMulti: false,
      placeholder: 'Total Followers',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
    },
  ];

  let formControlerGroups = [];

  for (let i = 0; i < formControler.length; i += 2) {
    if (i + 1 < formControler.length) {
      const group = [formControler[i], formControler[i + 1]];
      formControlerGroups.push(group);
    } else {
      const group = [formControler[i]];
      formControlerGroups.push(group);
    }
  }

  const formSubmitHandler = async () => {
    if (!newPlatform || !newTag || !newLink || !newFollowersCount)
      return alert('Please fill all the fields');
    let data = {
      social_platform: newPlatform.value,
      referance_tag: newTag,
      social_account_link: newLink,
      followers_count: newFollowersCount,
    };
    let updateResult = await updateFormControler(data, 'SOCIAL_ACCOUNTS');
    console.log(updateResult);
  };

  return (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>Social Accounts</div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              dispatch(setOpenForm(''));
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          {formControlerGroups.map((group, index) => {
            return (
              <div className={modalStyles.single_row}>
                {group.map((item, index) => {
                  return (
                    <div>
                      <p>{item.label}</p>

                      {item.type === 'select' ? (
                        <div className={modalStyles.multiSelect}>
                          <Select
                            options={item.option}
                            isMulti={item.isMulti}
                            onChange={item.handelChange}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                width: '100%',
                              }),
                            }}
                          />
                        </div>
                      ) : (
                        <input
                          type={item.type}
                          name={item.name}
                          id={item.id}
                          value={item.value}
                          onChange={(e) => {
                            item.onChange(e.target.value);
                          }}
                          placeholder={item.placeholder}
                          required={item.required}
                          disabled={item.disabled}
                          error={item.error}
                          helperText={item.helperText}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          {/* <p className={modalStyles.error_message}>
            You can not transfer amount less than $10 Or 100
          </p> */}
        </div>
        {socialAccountsData.length > 0 && (
          <div className={modalStyles.modal_footer}>
            <div className={sectionTablestyles.sectionTableContainer}>
              <table
                className={
                  sectionTablestyles.sectionTable +
                  ' ' +
                  sectionTablestyles.sectionTable_borderLess
                }
              >
                <thead>
                  <tr>
                    <th>Platform</th>
                    <th>Tag</th>
                    <th>Link</th>
                    <th>Followers</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {socialAccountsData.map((data, index) => (
                    <tr key={index}>
                      <td className={sectionTablestyles.max_width}>
                        {data.platform}
                      </td>
                      <td className={sectionTablestyles.max_width}>
                        {data.tag}
                      </td>
                      <td className={sectionTablestyles.max_width}>
                        <a href={data.link}>{data.link}</a>
                      </td>
                      <td className={sectionTablestyles.max_width}>
                        {data.followersCount}
                      </td>
                      <td>
                        <button
                          className={sectionTablestyles.deleteButton}
                          onClick={() => {
                            deleteSocialAccount(data.id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                dispatch(setOpenForm(''));
              }}
            >
              Cancel
            </button>
            <button
              className={modalStyles.btn_request}
              onClick={() => {
                formSubmitHandler();
              }}
            >
              Add New Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialAccounts;
