// Note: InfluencerOffers component
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../../styles/admin/offers/InfluencerOffers.module.css';
import starIcon from '../../../assets/brand/influencerProfile/star.svg';
import Icon5 from '../../../assets/brand/influencerProfile/Icon5.jpg';

import SearchIcon from '../../../assets/brand/Search.svg';
import filterIcon from '../../../assets/brand/offers/filter.svg';

import offer1 from '../../../assets/brand/offers/offer_1.png';
import offer2 from '../../../assets/brand/offers/offer_2.webp';
import offer3 from '../../../assets/brand/offers/offer_3.webp';
import offer4 from '../../../assets/brand/offers/offer_4.webp';
import offer5 from '../../../assets/brand/offers/offer_5.webp';

import heartFillIcon from '../../../assets/brand/offers/heart-fill.svg';
import heartIcon from '../../../assets/brand/offers/heart.svg';

function InfluencerOffers() {
  const navigate = useNavigate();

  // number to M, K, B, T, etc
  const numberToMKB = (number) => {
    if (number >= 1000000000000) {
      return (number / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
    }
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return number;
  };

  const allOffersData = [
    {
      offerId: '1',
      offerHeading: 'Social Engagement on Intagram',
      influencerComment: 'I will comment. like. save and share to my story',
      influencerId: '1',
      name: 'ADAM IDAHAM',
      reach: 23000000,
      rating: 4.5,
      totalReviews: 4,
      startingCost: 60,
      offerPoster: offer1,
    },
    {
      offerId: '2',
      offerHeading: 'Social Engagement on Intagram',
      influencerComment: 'I will comment. like. save and share to my story',
      influencerId: '2',
      name: 'Tyler Greener',
      reach: 20400000,
      rating: 4.5,
      totalReviews: 4,
      startingCost: 60,
      offerPoster: offer2,
    },
    {
      offerId: '3',
      offerHeading: 'Social Engagement on Intagram',
      influencerComment: 'I will comment. like. save and share to my story',
      influencerId: '3',
      name: 'ADAM IDAHAM',
      reach: 23000000,
      rating: 4.5,
      totalReviews: 4,
      startingCost: 60,
      offerPoster: offer3,
    },
    {
      offerId: '4',
      offerHeading: 'Social Engagement on Intagram',
      influencerComment: 'I will comment. like. save and share to my story',
      influencerId: '4',
      name: 'Tyler Greener',
      reach: 20400000,
      rating: 4.5,
      totalReviews: 4,
      startingCost: 60,
      offerPoster: offer4,
    },
    {
      offerId: '5',
      offerHeading: 'Social Engagement on Intagram',
      influencerComment: 'I will comment. like. save and share to my story',
      influencerId: '5',
      name: 'ADAM IDAHAM',
      reach: 23000000,
      rating: 4.5,
      totalReviews: 4,
      startingCost: 60,
      offerPoster: offer5,
    },
    {
      offerId: '6',
      offerHeading: 'Social Engagement on Intagram',
      influencerComment: 'I will comment. like. save and share to my story',
      influencerId: '6',
      name: 'Tyler Greener',
      reach: 20400000,
      rating: 4.5,
      totalReviews: 4,
      startingCost: 60,
      offerPoster: offer1,
    },
  ];

  const likedOffersIds = ['1', '3', '5'];

  // get influencers data profile image from backend based on influencerId
  function fetchInfluencerProfileImages(influencerId, imageSourceId) {
    // fetch image from backend based on influencerId
    document.getElementById(imageSourceId).src = Icon5;
    // console.log('fetched image for influencerId: ' + influencerId);
  }
  const [searchedOffersData, setsearchedOffersData] = useState(allOffersData);
  // function searchOnAllValues(keyword) {
  //   const searchTerms = keyword.toLowerCase().split(' ');
  //   const result = allOffersData.filter((offer) => {
  //     for (let term of searchTerms) {
  //       let termFound = false;
  //       for (let key in offer) {
  //         if (typeof offer[key] === 'string' || offer[key] instanceof Array) {
  //           if (offer[key].toString().toLowerCase().includes(term)) {
  //             termFound = true;
  //             break;
  //           }
  //         } else if (typeof offer[key] === 'object') {
  //           for (let subKey in offer[key]) {
  //             if (offer[key][subKey].toString().toLowerCase().includes(term)) {
  //               termFound = true;
  //               break;
  //             }
  //           }
  //         }
  //       }
  //       if (!termFound) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   });
  //   // console.log(filteredInfluencers);
  //   setsearchedOffersData(result);
  // }

  const total_offers = 2433;
  return (
    <div className={styles.InfluencerOffers}>
      <div className={styles.InfluencerOffers_header}>
        <h3>Influencer Offers</h3>
        <div className={styles.InfluencerOffers_header_searchbar}>
          <img src={SearchIcon} alt="search" />
          <input
            type="text"
            placeholder="Search by name or keyword"
            onChange={(e) => {
              // searchOnAllValues(e.target.value);
            }}
          />
          <div className={styles.total_offers_number}>
            <span>{numberToMKB(total_offers)} </span>
            <span>results</span>
          </div>
          {/* <div > */}
          <button className={styles.advanced_filter}>
            <img src={filterIcon} alt="" />
            <span>Advanced Filters</span>
          </button>
          {/* </div> */}
        </div>
      </div>
      <div className={styles.InfluencerOffers_cards}>
        {searchedOffersData.map((item, index) => {
          return (
            <div
              className={styles.InfluencerOffers_card}
              key={index}
              onClick={(e) => {
                navigate(`/brand/influencer_offer/${item.influencerId}`);
              }}
            >
              <div className={styles.offer_banner}>
                <img src={item.offerPoster} alt="profile" />
              </div>

              <div className={styles.mid_section}>
                <div className={styles.offerHeading_container}>
                  <div className={styles.offer_social_tag}>
                    <img src={Icon5} alt="" />
                  </div>
                  <p className={styles.offerHeading_title}>
                    {item.offerHeading}
                  </p>
                </div>
                <p className={styles.offerHeading_comment}>
                  {item.influencerComment}
                </p>
              </div>

              <div className={styles.basicIntro}>
                <div className={styles.basicIntro_reach}>
                  <span className={styles.basicIntro_totalReach}>
                    {numberToMKB(item.reach)} Reach
                  </span>
                  <div className={styles.rating_box}>
                    <img src={starIcon} alt="rating" />
                    <span className={styles.rating_number}>{item.rating}</span>
                    <span className={styles.total_reviews}>
                      ({item.totalReviews})
                    </span>
                  </div>
                </div>
                <img
                  className={styles.favourite_icon}
                  src={heartIcon}
                  alt="heartIcon"
                />
              </div>

              <div className={styles.card_footer}>
                <div>
                  <img src={Icon5} alt="profilePic" />
                  <span className={styles.influencer_name}>{item.name}</span>
                </div>
                <span className={styles.starting_cost}>
                  Starting at : {item.startingCost}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InfluencerOffers;
