import React, { useState } from 'react';
import styles from '../../../styles/common/Login/Login.module.css';
import InfluencerForm from './InfluencerLoginForm';
import BrandForm from './BrandLoginForm';
import BrandRegisterForm from './BrandRegisterForm';
import InfluencerRegisterForm from './InfluencerRegisterForm';

const UserOnBoarding = () => {
  const [userType, setUserType] = useState('influencer');
  const [onBoardingType, setOnBoardingType] = useState('login');

  console.log(userType);
  console.log(onBoardingType);

  return (
    <div className={styles.login}>
      <div className={styles.card}>
        <div className={styles.section_name}>
          <span
            className={
              userType === 'influencer' ? styles.influencer_active : ''
            }
            onClick={() => setUserType('influencer')}
          >
            Influencer
          </span>
          <span
            className={userType === 'brand' ? styles.influencer_active : ''}
            onClick={() => setUserType('brand')}
          >
            Brand
          </span>
        </div>
        <hr className={styles.healine_hr}></hr>

        {/* {onBoardingType === 'login' && (
          <InfluencerForm setOnBoardingType={setOnBoardingType} />
        )} */}
        {/* {onBoardingType === 'login'
          ? (userType === 'influencer' && 
              <InfluencerForm setOnBoardingType={setOnBoardingType} />
            )
          : (userType === 'brand' && 
              <BrandForm setOnBoardingType={setOnBoardingType} />
            )}

         {onBoardingType === 'register'
          ?( userType === 'influencer' && 
              <InfluencerRegisterForm setOnBoardingType={setOnBoardingType} />
            )
          : (userType === 'brand' && 
              <BrandRegisterForm setOnBoardingType={setOnBoardingType} />
            )}  */}

        {onBoardingType === 'login' && userType === 'influencer' && (
          <InfluencerForm setOnBoardingType={setOnBoardingType} />
        )}
        {onBoardingType === 'login' && userType === 'brand' && (
          <BrandForm setOnBoardingType={setOnBoardingType} />
        )}
        {onBoardingType === 'register' && userType === 'influencer' && (
          <InfluencerRegisterForm setOnBoardingType={setOnBoardingType} />
        )}
        {onBoardingType === 'register' && userType === 'brand' && (
          <BrandRegisterForm setOnBoardingType={setOnBoardingType} />
        )}
      </div>
    </div>
  );
};

export default UserOnBoarding;
