import React from 'react';
import styles from '../../../styles/brand/payments/PaymentsHistory.module.css';
import tableStyles from '../../../styles/brand/commonStyle/commonTable.module.css';
import modalStyles from '../../../styles/brand/commonStyle/commonModal.module.css';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';

function PaymentsHistory() {
  const paymentCardData = [
    {
      title: 'TOTAL REQUEST',
      value: 10,
      color: '#e3c700',
    },
    {
      title: 'PROCESSED REQUEST',
      value: 7,
      color: '#2a76f4',
    },
    {
      title: 'PROCESSED AMOUNT',
      value: 300,
      color: '#e3c700',
      currency: 'USD',
    },
    {
      title: 'UNDER-PROCESS REQUEST',
      value: 3,
      color: '#49a240',
    },
    {
      title: 'UNDER-PROCESS AMOUNT',
      value: 200,
      color: '#49a240',
      currency: 'USD',
    },
  ];

  const paymentHistoryData = [
    {
      id: '1',
      requestedId: '#23384',
      requestDate: '01/Jan/2022',
      requestAmount: 300,
      processStatus: 'Process',
      transactionId: '123456789',
      paymentMode: 'Paypal',
      processedDate: '01/Jan/2022',
      processBy: 'John',
    },
    {
      id: '2',
      requestedId: '#23384',
      requestDate: '01/Jan/2022',
      requestAmount: 300,
      processStatus: 'Under Process',
      transactionId: '123456789',
      paymentMode: 'Paypal',
      processedDate: '01/Jan/2022',
      processBy: 'John',
    },
  ];

  const uniqueStatus = ['Process', 'Under Process'];

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'REQUEST ID',
      placeHolder: 'REQUEST ID',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'REQUEST DATE',
      placeHolder: 'REQUEST DATE',

      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'REQUEST AMOUNT',
      placeHolder: 'REQUEST AMOUNT',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'PROCESS STATUS',
      placeHolder: 'PROCESS STATUS',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: true,
    },
    {
      fieldName: 'TRANSACTION ID',
      placeHolder: 'TRANSACTION ID',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'PAYMENT MODE',
      placeHolder: 'PAYMENT MODE',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'PROCESSED DATE',
      placeHolder: 'PROCESSED DATE',
      searchType: 'textInput',
      searchHeader: true,
    },
    {
      fieldName: 'PROCESSED BY',
      placeHolder: 'PROCESSED BY',
      searchType: 'textInput',
      searchHeader: true,
    },
  ];
  const statusColorClass = {
    Process: styles.status_process,
    'Under Process': styles.under_process,
  };

  const [showModal, setShowModal] = useState(false);

  const popUPModal = (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>Request Payment</div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              setShowModal(false);
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          <p>Amount in Wallet</p>
          <input type="number" name="" id="" />
          <p>Request Date</p>
          <input type="date" name="" id="" />
          <p>Request Amount</p>
          <input type="number" name="" id="" />
          <p className={modalStyles.error_message}>
            You can not transfer amount less than $10 Or 100
          </p>
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button className={modalStyles.btn_request}>Request</button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.historyMain}>
      {showModal && popUPModal}
      <div className={styles.topCardsContainer}>
        {paymentCardData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}>
          <div className={styles.code__button}>
            <div></div>
            <div></div>
            <div className={styles.modal_button_container}>
              <div
                className={styles.modal_button}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Request Payment
              </div>
            </div>
          </div>
        </div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {paymentHistoryData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.requestedId}</td>
                    <td>{item.requestDate}</td>
                    <td>{item.requestAmount}</td>
                    <td className={statusColorClass[item.processStatus]}>
                      {item.processStatus}
                    </td>
                    <td>{item.transactionId}</td>
                    <td>{item.paymentMode}</td>
                    <td>{item.processedDate}</td>
                    <td>{item.processBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentsHistory;
