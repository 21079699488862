import React from 'react';
import Footer from './Footer';
import Hero from './Hero';
import MiddleSection from './MiddleSection';
import QA from './QA';
import Testimonial from './Testimonial';
import Header from './Header';

function Home() {
  return (
    <div>
      <Header />
      <Hero />
      <MiddleSection />
      <QA />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default Home;
