import { Link, useNavigate } from 'react-router-dom';

// Importing the styles for the Header component.
import styles from '../../../styles/brand/header/Header.module.css';

// Importing SVG icons and images for the Header component.
import UkFlag from '../../../assets/brand/uk-flag.svg';
import ChatIcon from '../../../assets/brand/Chat.svg';
import NotificationIcon from '../../../assets/brand/Notification.svg';
import ExpandIcon from '../../../assets/brand/Expand.svg';
import ProfileIcon from '../../../assets/brand/ProfileIcon.jpg';
import HamburgerIcon from '../../../assets/brand/Hamburger.svg';
import MenuthreeDot from '../../../assets/brand/menu-ui-icon.svg';

import userIcon from '../../../assets/common/profilePopUp/user.svg';
import logOutIcon from '../../../assets/common/profilePopUp/power-off.svg';

import { useEffect, useState } from 'react';

import { setProfileData } from '../../../redux/reducers/influencer/profileSlice.js';
import { setBrandProfileData } from '../../../redux/reducers/brand/profileSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import Axios from '../../../axios';
import getInfuencerProfile from '../../../redux/reducers/influencer/thunk';
import getBrandProfile from '../../../redux/reducers/brand/thunk';

// Defining the Header component with props.
const Header = ({
  setSidebarActive,
  sidebarActive,
  mobileHeaderHide,
  setMobileHeaderHide,
  isDesktop,
}) => {
  let userTypeValue = localStorage.getItem('userTypeValue');

  // Defining some constant variables.
  const [fullScreen, setFullScreen] = useState(false);
  const [logoutPopUp, setLogoutPopUp] = useState(false);
  const notificationCount = 5;
  const messageCount = 5;
  const userPersonalInfo = useSelector((state) => state);

  // Defining a function to toggle the sidebar state.
  function toggleSidebar() {
    setSidebarActive(!sidebarActive);
  }
  function toogleMobileHeader() {
    setMobileHeaderHide(!mobileHeaderHide);
  }

  const logout = () => {
    localStorage.removeItem('accessToken');
    navigate('/UserOnBoarding');
  };

  const navigate = useNavigate();

  // Defining a function to toggle full screen mode.
  function toggleFullScreen() {
    if (fullScreen) {
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      document.documentElement.requestFullscreen();
      setFullScreen(true);
    }
  }

  const allProfileData = useSelector((state) => state.influencerProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getProfile());
    // dispatch(getBrandProfile());
    if (userTypeValue === 'influencer') {
      dispatch(getInfuencerProfile());
    }
    if (userTypeValue === 'brand') {
      dispatch(getBrandProfile());
    }
  }, []);

  // Rendering the header bar  with icons, texts and a link to FAQ page.
  return (
    <div className={styles.brand_header}>
      <div className={styles.brand_header_left + ' animate'}>
        <img
          onClick={() => {
            toggleSidebar();
          }}
          className={styles.brand_header_menuIcon}
          alt="Menu"
          src={HamburgerIcon}
        ></img>
        <span>Welcome {allProfileData?.personal_info?.first_name}</span>
      </div>

      <img
        onClick={() => {
          toogleMobileHeader();
        }}
        className={styles.brand_header_menuIcon_dot}
        alt="Menu"
        src={MenuthreeDot}
      ></img>

      {(mobileHeaderHide || isDesktop) && (
        <div className={styles.brand_header_right}>
          <div className={styles.brand_header_faq}>
            <Link to="/faq">FAQ</Link>
            <div>
              <img
                className={styles.IconSizeBig}
                alt="UK Flag"
                src={UkFlag}
              ></img>
            </div>
          </div>
          <div className={styles.brand_header_right_middle}>
            <img
              alt="FullScreen"
              src={ExpandIcon}
              className={styles.IconSizeSmall}
              onClick={() => {
                toggleFullScreen();
              }}
            ></img>
            <div>
              <img alt="Notifications" src={NotificationIcon} />
              <div className={styles.countNumberPosition}>
                {notificationCount}
              </div>
            </div>
            <div>
              <img
                alt="Messages"
                src={ChatIcon}
                className={styles.IconSizeSmall}
              ></img>
              <div className={styles.countNumberPosition}>{messageCount}</div>
            </div>
          </div>
          <div>
            <img
              className={styles.IconSizeBig}
              alt="Profile"
              src={ProfileIcon}
              onClick={() => {
                setLogoutPopUp(!logoutPopUp);
              }}
            ></img>
            {logoutPopUp && (
              <div className={styles.profilePopUp}>
                <div className={styles.profilePopUp_info}>
                  {userTypeValue === 'admin' ? (
                    <div>Admin</div>
                  ) : (
                    <>
                      <div>Sonia Taylor</div>
                      <div>Web Designer</div>
                    </>
                  )}
                </div>
                <div className={styles.profilePopUp_cta_list}>
                  <hr />
                  {userTypeValue !== 'admin' && (
                    <>
                      <div
                        className={styles.profilePopUp_cta_container}
                        onClick={() => {
                          navigate(`/${userTypeValue}/profile`);
                          setLogoutPopUp(!logoutPopUp);
                        }}
                      >
                        <img alt="MyProfile" src={userIcon} />
                        <div>My Profile</div>
                      </div>
                      <hr />
                    </>
                  )}

                  <div
                    className={styles.profilePopUp_cta_container}
                    onClick={() => {
                      logout();
                    }}
                  >
                    <img alt="Sign Out" src={logOutIcon} />
                    <div>Sign Out</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// Exporting the Header component as a default export.
export default Header;
