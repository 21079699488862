import React from 'react';
import Chart from 'chart.js/auto';
import tableStyles from '../../../styles/brand/commonStyle/commonTable.module.css';
import styles from '../../../styles/brand/reports/CampaignSummary.module.css';

function CampaignSummary() {
  const chartData = {
    data: [
      {
        month: 'Jan',
        amount_used: 1000,
        total_budget: 5000,
      },
      {
        month: 'Feb',
        amount_used: 2000,
        total_budget: 4000,
      },
      {
        month: 'Mar',
        amount_used: 3000,
        total_budget: 5000,
      },
      {
        month: 'Apr',
        amount_used: 3500,
        total_budget: 4000,
      },
      {
        month: 'May',
        amount_used: 4000,
        total_budget: 4000,
      },
      {
        month: 'Jun',
        amount_used: 4500,
        total_budget: 3000,
      },
      {
        month: 'Jul',
        amount_used: 4800,
        total_budget: 5000,
      },
      {
        month: 'Aug',
        amount_used: 4900,
        total_budget: 2000,
      },
      {
        month: 'Sep',
        amount_used: 4950,
        total_budget: 4000,
      },
      {
        month: 'Oct',
        amount_used: 4975,
        total_budget: 5000,
      },
    ],
  };

  const paymentCardData = [
    {
      title: 'TOTAL REQUEST',
      value: 10,
      color: '#e3c700',
    },
    {
      title: 'PROCESSED REQUEST',
      value: 7,
      color: '#2a76f4',
    },
    {
      title: 'PROCESSED AMOUNT',
      value: 300,
      color: '#e3c700',
      currency: 'USD',
    },
    {
      title: 'UNDER-PROCESS REQUEST',
      value: 3,
      color: '#49a240',
    },
    {
      title: 'UNDER-PROCESS AMOUNT',
      value: 200,
      color: '#49a240',
      currency: 'USD',
    },
  ];

  const campaignSummaryData = [
    {
      Week: {
        pitchesSent: 10,
        pitchesAccepted: 7,
        transactionCompleted: 3,
      },
    },
    {
      Month: {
        pitchesSent: 10,
        pitchesAccepted: 7,
        transactionCompleted: 3,
      },
    },
    {
      Quarter: {
        pitchesSent: 10,
        pitchesAccepted: 7,
        transactionCompleted: 3,
      },
    },
    {
      Year: {
        pitchesSent: 10,
        pitchesAccepted: 7,
        transactionCompleted: 3,
      },
    },
  ];

  const tableSchema = [
    {
      fieldName: '',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Pitches Sent',
      placeHolder: 'Pitches Sent',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Pitches Accepted',
      placeHolder: 'Pitches Accepted',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Transaction Completed',
      placeHolder: 'Transaction Completed',
      searchType: 'textInput',
      searchHeader: false,
    },
  ];

  const chartRef = React.useRef(null);

  React.useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');
    let myChart = new Chart(myChartRef, {
      type: 'line',
      data: {
        labels: chartData.data.map((item) => item.month),
        datasets: [
          {
            label: 'Total Budget',
            data: chartData.data.map((item) => item.total_budget),
            fill: false,
            borderColor: '#6f42c1',
            borderWidth: 2,
            tension: 0.3, // add curve to line
          },
          {
            label: 'Amount Used',
            data: chartData.data.map((item) => item.amount_used),
            fill: false,
            borderColor: '#6c757d',
            borderWidth: 2,
            borderDash: [2, 2],
            tension: 0.3,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            suggestedMax: 5500, // add extra padding to top of y-axis
            padding: 10,
          },
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            intersect: false,
            mode: 'index',
            callbacks: {
              label: function (context) {
                var label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += context.formattedValue;
                return label;
              },
            },
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
        },
      },
    });

    return () => {
      myChart.destroy();
    };
  }, []);

  return (
    <div className={styles.campaignSummary}>
      <div className={styles.campaignSummary_header}>
        <h3>Campaign Summary</h3>
      </div>

      <div className={styles.summary_chart}>
        <canvas className={styles.summary_chart_canvas} ref={chartRef} />
      </div>

      <div
        className={
          tableStyles.tabularContentContainer +
          ' ' +
          styles.tabularContentContainer
        }
      >
        <div className={tableStyles.table_container}>
          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {campaignSummaryData.map((item, index) => {
                // let key_name = item.keys(item)[0];
                let key_name = Object.keys(item)[0];
                return (
                  <tr key={index}>
                    <td>{key_name}</td>
                    <td>{item[key_name].pitchesSent}</td>
                    <td>{item[key_name].pitchesAccepted}</td>
                    <td>{item[key_name].transactionCompleted}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CampaignSummary;
