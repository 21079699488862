import React, { useEffect, useState } from 'react';
import modalStyles from '../../../../styles/brand/commonStyle/commonModal.module.css';
import Select from 'react-select';
import Axios from '../../../../axios';
import {
  useUpdateFormControler,
  useUploadAsset,
} from './ProfileUpdateControler';
import { useDispatch } from 'react-redux';
import { setOpenForm } from '../../../../redux/reducers/influencer/profileSlice';

function PersonalInfo({ preFiledData }) {
  //   console.log('preFiledData', preFiledData);
  const dispatch = useDispatch();
  const updateFormControler = useUpdateFormControler();
  const UploadAsset = useUploadAsset();
  const [profileImage, setProfileImage] = useState('');

  const [first_name, setFirst_name] = useState(preFiledData?.first_name);
  const [last_name, setLast_name] = useState(preFiledData?.last_name);
  const [email, setEmail] = useState(preFiledData?.email);
  const [dob, setDob] = useState(preFiledData?.dob);
  const [mobile_no, setMobile_no] = useState(preFiledData?.mobile_no);
  const [whatsapp_no, setWhatsapp_no] = useState(preFiledData?.whatsapp_no);
  const [geography, setGeography] = useState(preFiledData?.geography_id?.value);
  const [country, setCountry] = useState(preFiledData?.country_id?.value);
  const [state, setState] = useState(preFiledData?.state_id?.value);
  const [city, setCity] = useState(preFiledData?.city_id?.value);
  const [zipCode, setZipCode] = useState(preFiledData?.zip_code);
  const [timeZone, setTimeZone] = useState(preFiledData?.time_zone);
  const [gender, setGender] = useState(preFiledData?.gender);
  const [language, setLanguage] = useState(preFiledData?.languages || []);
  const [specialities, setSpecialities] = useState(
    preFiledData?.specialties || []
  );

  const [geographyOptions, setGeographyOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [specialitiesOptions, setSpecialitiesOptions] = useState([]);
  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };
  const genderOptions = [
    { value: 'female', label: 'Female' },
    { value: 'male', label: 'Male' },
    { value: 'other', label: 'Non-Binary' },
  ];

  const genderChanges = (selectedOption) => {
    setGender(selectedOption);
  };
  const languageChanges = (selectedOption) => {
    setLanguage(selectedOption);
  };
  const specialitiesChanges = (selectedOption) => {
    setSpecialities(selectedOption);
  };
  const geographyChanges = (selectedOption) => {
    setGeography(selectedOption);
    getMasterValues(
      'geographyId=' + selectedOption.value,
      'masterCountry',
      setCountryOptions
    );
  };
  const countryChanges = (selectedOption) => {
    setCountry(selectedOption);
    getMasterValues(
      'countryId=' + selectedOption.value,
      'masterState',
      setStateOptions
    );
  };
  const stateChanges = (selectedOption) => {
    setState(selectedOption);
    getMasterValues(
      'stateId=' + selectedOption.value,
      'masterCity',
      setCityOptions
    );
  };
  const cityChanges = (selectedOption) => {
    setCity(selectedOption);
  };

  const genderDefaultValue = genderOptions.find(
    (option) => option.value === preFiledData?.gender?.value
  );

  const languageDefaultValue = languageOptions.filter((option) =>
    preFiledData?.languages?.includes(option.value)
  );

  const specialitiesDefaultValue = specialitiesOptions.filter((option) =>
    preFiledData?.specialties?.includes(option.value)
  );

  let formControler = {
    profileImage: {
      value: profileImage,
      onChange: setProfileImage,
      type: 'file',
      label: 'Profile Image',
      placeholder: 'Profile Image',
      id: 'profileImageUpload',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.profileImage,
    },
    first_name: {
      value: first_name,
      onChange: setFirst_name,
      type: 'text',
      label: 'First Name',
      placeholder: 'First Name',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.first_name,
    },
    last_name: {
      value: last_name,
      onChange: setLast_name,
      type: 'text',
      label: 'Last Name',
      placeholder: 'Last Name',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.last_name,
    },
    email: {
      value: email,
      onChange: setEmail,
      type: 'email',
      label: 'Email',
      placeholder: 'Email',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.email,
    },
    dob: {
      value: dateStringToDate(dob),
      onChange: setDob,
      type: 'date',
      label: 'Dob',
      placeholder: 'Dob',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.dob,
    },
    mobile_no: {
      value: mobile_no,
      onChange: setMobile_no,
      type: 'number',
      label: 'Mobile Phone No.',
      placeholder: 'Mobile Phone No.',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.mobile_no,
    },
    whatsapp_no: {
      value: whatsapp_no,
      onChange: setWhatsapp_no,
      type: 'number',
      label: 'Whatsapp No.',
      placeholder: 'Whatsapp No.',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.whatsapp_no,
    },
    geography_id: {
      value: geography,
      onChange: setGeography,
      type: 'select',
      option: geographyOptions,
      handelChange: geographyChanges,
      isMulti: false,
      label: 'Geography',
      placeholder: 'Geography',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.geography,
    },
    country_id: {
      value: country,
      onChange: setCountry,
      type: 'select',
      option: countryOptions,
      handelChange: countryChanges,
      isMulti: false,
      label: 'Country',
      placeholder: 'Country',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.country,
    },
    state_id: {
      value: state,
      onChange: setState,
      type: 'select',
      option: stateOptions,
      handelChange: stateChanges,
      isMulti: false,
      label: 'State',
      placeholder: 'State',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.state,
    },
    city_id: {
      value: city,
      onChange: setCity,
      type: 'select',
      option: cityOptions,
      handelChange: cityChanges,
      isMulti: false,
      label: 'City',
      placeholder: 'City',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.city,
    },
    zip_code: {
      value: zipCode,
      onChange: setZipCode,
      type: 'number',
      label: 'Zip Code',
      placeholder: 'Zip Code',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.zip_code,
    },
    time_zone: {
      value: timeZone,
      onChange: setTimeZone,
      type: 'text',
      label: 'Time Zone',
      placeholder: 'Time Zone',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.time_zone,
    },
    gender: {
      value: gender,
      onChange: setGender,
      type: 'select',
      option: genderOptions,
      handelChange: genderChanges,
      isMulti: false,
      label: 'Gender',
      placeholder: 'Gender',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.gender, // { "value": "male", "label": "Male" }
      defaultValue: genderDefaultValue,
    },
    languages: {
      value: language,
      onChange: setLanguage,
      type: 'select',
      option: languageOptions,
      handelChange: languageChanges,
      isMulti: true,
      label: 'Language',
      placeholder: 'Language',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.language, // [{ "value": "english", "label": "English" }, { "value": "hindi", "label": "Hindi" }]
      defaultValue: languageDefaultValue,
    },
    // profileImage: {
    //   value: profileImage,
    //   onChange: setProfileImage,
    //   type: 'text',
    //   label: 'Profile Image',
    //   placeholder: 'Profile Image',
    //   required: true,
    //   disabled: false,
    //   error: false,
    //   helperText: '',
    // },
    specialities: {
      value: specialities,
      onChange: setSpecialities,
      type: 'select',
      option: specialitiesOptions,
      handelChange: specialitiesChanges,
      isMulti: true,
      label: 'Specialities',
      placeholder: 'Specialities',
      required: true,
      disabled: false,
      error: false,
      helperText: '',
      initialValue: preFiledData?.specialities, // [{ "value": "english", "label": "English" }, { "value": "hindi", "label": "Hindi" }]
      defaultValue: specialitiesDefaultValue,
    },
  };

  const getMasterValues = (pathQuery, path, setterFunction) => {
    if (!setterFunction || !path) {
      //   alert('Please fill all the fields');
      console.log('getMasterValues -> function call Error ');
    } else {
      if (pathQuery) {
        path = path + '?' + pathQuery;
      }

      let config = {
        method: 'get',
        url: '/api/v1/master/' + path,
      };
      Axios.request(config)
        .then((response) => {
          if (response.status === 200) {
            // navigate('/brand/dashboard');
            console.log(response.data.data);
            // changeKeyName
            // const geographyOptions = response.data.data.map((item) => ({
            //   value: item.geography_id,
            //   label: item.geography_name,
            // }));
            const options = response.data.data.map((item) => {
              let option = {};
              Object.keys(item).forEach((key) => {
                if (key.endsWith('_id')) {
                  option['value'] = item[key];
                } else if (key.endsWith('_name')) {
                  option['label'] = item[key];
                }
              });
              return option;
            });
            setterFunction(options);
            console.log(path, pathQuery, options);
            // setGeographyOptions(geographyOptions);
          } else {
            alert(response.data.error);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
    }
  };

  useEffect(() => {
    getMasterValues(null, 'masterGeography', setGeographyOptions);
    getMasterValues(null, 'masterLanguage', setLanguageOptions);
    getMasterValues(null, 'masterSpecialties', setSpecialitiesOptions);
  }, []);

  const keys = Object.keys(formControler);
  const formControlerGroups = [];

  for (let i = 0; i < keys.length; i += 2) {
    if (i + 1 < keys.length) {
      const group = [formControler[keys[i]], formControler[keys[i + 1]]];
      formControlerGroups.push(group);
    } else {
      const group = [formControler[keys[i]]];
      formControlerGroups.push(group);
    }
  }

  const formSubmitHandler = async () => {
    let data = {};
    for (let key in formControler) {
      if (
        formControler[key].value &&
        formControler[key].value !== formControler[key].initialValue
      ) {
        if (formControler[key].type === 'select') {
          if (formControler[key].isMulti) {
            data[key] = formControler[key].value.map((item) => item.value);
          } else {
            data[key] = formControler[key].value.value;
          }
        } else {
          data[key] = formControler[key].value;
          console.log(
            key,
            formControler[key].value,
            formControler[key].initialValue
          );
        }
      }
    }
    if (Object.keys(data).length === 0) {
      alert('No data to update');
      return;
    } else {
      if (data.profileImage) {
        let uploadResult = await UploadAsset(
          document.getElementById('profileImageUpload').files[0],
          'PROFILE',
          'IMAGE'
        );

        data = data.filter((item) => item !== 'profileImage');
        if (data.length > 0) {
          let updateResult = await updateFormControler(
            data,
            'PERSONAL_INFORMATION'
          );
          console.log(updateResult);
        }

        console.log(uploadResult);
      } else {
        let updateResult = await updateFormControler(
          data,
          'PERSONAL_INFORMATION'
        );
        console.log(updateResult);
      }
    }
  };

  return (
    <div className={modalStyles.modalMain}>
      <div
        className={
          modalStyles.modal_container + ' ' + modalStyles.modal_container_big
        }
      >
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>
            Personal Information
          </div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              dispatch(setOpenForm(''));
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          {formControlerGroups.map((group, index) => {
            return (
              <div className={modalStyles.single_row}>
                {group.map((item, index) => {
                  return (
                    <div>
                      <p>{item.label}</p>

                      {item.type === 'select' ? (
                        <div className={modalStyles.multiSelect}>
                          <Select
                            options={item.option}
                            isMulti={item.isMulti}
                            onChange={item.handelChange}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                width: '100%',
                              }),
                            }}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      ) : (
                        <input
                          type={item.type}
                          name={item.name}
                          id={item.id}
                          value={item.value}
                          onChange={(e) => {
                            item.onChange(e.target.value);
                          }}
                          placeholder={item.placeholder}
                          required={item.required}
                          disabled={item.disabled}
                          error={item.error}
                          helperText={item.helperText}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          {/* <p className={modalStyles.error_message}>
            You can not transfer amount less than $10 Or 100
          </p> */}
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                dispatch(setOpenForm(''));
              }}
            >
              Cancel
            </button>
            <button
              className={modalStyles.btn_request}
              onClick={() => {
                formSubmitHandler();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
