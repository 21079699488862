import React, { useState } from 'react';
import socialMediaButtonStyles from '../../../styles/common/components/Social_button.module.css';
import styles from '../../../styles/common/Login/Login.module.css';
import Axios from '../../../axios';
import { useNavigate } from 'react-router-dom';
import SocialButton from '../components/Social_button';

import googleButton from '../../../assets/common/social_media/google.png';
import facebookButton from '../../../assets/common/social_media/facebook.png';
import twitterButton from '../../../assets/common/social_media/twitter.png';

const BrandLoginForm = ({ setOnBoardingType }) => {
  const navigate = useNavigate();
  const [officialEmail, setOfficialEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    if (officialEmail === '' || password === '') {
      alert('Please fill all the fields');
    } else {
      let loginData = {
        user_type_id: 2,
        email: officialEmail,
        password: password,
      };

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/v1/auth/signin',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(loginData),
      };
      Axios.request(config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            alert('Logged In Successfully');
            const accessToken = response.headers.authorization.split(' ')[1];
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('userTypeId', 2);
            localStorage.setItem('userTypeValue', 'brand');
            // navigate('/brand');
            window.open('/brand', '_self');
          }
        })
        .catch((error) => {
          console.log('error ', error);
          if (error.response.data.error.message)
            alert(error.response.data.error.message);
        });
    }
  };

  return (
    <div className={styles.login_form_section}>
      <div className={socialMediaButtonStyles.social_media_buttons}>
        <SocialButton
          title="Google"
          socialButtonStyle={socialMediaButtonStyles.google_button}
          imgSrc={googleButton}
          buttonOnClick={() =>
            window.open(
              `https://api.hangama.com/api/v1/auth/google/callback?user_type_id=2`,
              '_self'
            )
          }
        ></SocialButton>
        <SocialButton
          title="Facebook"
          socialButtonStyle={socialMediaButtonStyles.facebook_button}
          imgSrc={facebookButton}
          buttonOnClick={() =>
            window.open(
              `https://api.hangama.com/api/v1/auth/facebook/callback?user_type_id=2`,
              '_self'
            )
          }
        ></SocialButton>
        <SocialButton
          title="Twitter"
          socialButtonStyle={socialMediaButtonStyles.twitter_button}
          imgSrc={twitterButton}
          buttonOnClick={() =>
            window.open(
              `https://api.hangama.com/api/v1/auth/twitter/callback?user_type_id=2`,
              '_self'
            )
          }
        ></SocialButton>
      </div>
      <div className={styles.or_login_with_section}>
        <hr></hr>
        <span className={styles.or_login_with}>Or Login With</span>
        <hr></hr>
      </div>
      <div className={styles.input_fields_div}>
        <input
          type="email"
          name="email"
          placeholder="Email ID"
          className={styles.influencer_field}
          onChange={(e) => setOfficialEmail(e.target.value)}
        />
        <input
          type={showPassword ? 'text' : 'password'}
          name="password"
          placeholder="password"
          className={styles.influencer_field}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className={styles.form_options_div}>
        <div className={styles.checkbox_div}>
          <input
            type="checkbox"
            className={styles.checkbox_input}
            checked={showPassword} // Bind checked state to showPassword
            onChange={togglePasswordVisibility}
          ></input>
          <label className={styles.checkbox_label}>Show password</label>
        </div>
        <p href="\shdhj" className={styles.forgot_password}>
          Forgot Password
        </p>
      </div>
      <div className={styles.bottom_div}>
        <button
          onClick={() => {
            handleLogin();
          }}
          className={styles.login_button}
        >
          Log in
        </button>
        <p className={styles.no_account}>
          Don't have an account?&nbsp;
          {/* <a href="\register" className={styles.register_link}>
            Register
          </a> */}
          <span
            className={styles.register_link}
            onClick={() => {
              setOnBoardingType('register');
            }}
          >
            Register
          </span>
        </p>
      </div>
    </div>
  );
};

export default BrandLoginForm;
