import { useDispatch } from 'react-redux';
import Axios from '../../../../axios';
import { getProfile } from '../../../../redux/reducers/influencer/thunk';
import { setOpenForm } from '../../../../redux/reducers/influencer/profileSlice';

const INFLUENCER_PROFILE_UPDATE_TYPE = {
  PERSONAL_INFORMATION: 1,
  CAMPAIGN_CRITERIA: 2,
  SOCIAL_ACCOUNTS: 3,
  NOTIFICATION_PREFERENCES: 4,
  ABOUT_YOU: 5,
};

const useUpdateFormControler = () => {
  const dispatch = useDispatch();

  const updateFormControler = async (dataToUpdate, updateType) => {
    const userTypeId = parseInt(localStorage.getItem('userTypeId'));
    let data = {
      user_type_id: userTypeId,
      update_type_id: INFLUENCER_PROFILE_UPDATE_TYPE[updateType],
      data: dataToUpdate,
    };

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: '/api/v1/user/updateProfile',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
      data: data,
    };

    console.log('Token *****', localStorage.getItem('accessToken'));
    console.log('config', config);

    Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          alert('Updated Successfully');
          dispatch(getProfile());
          dispatch(setOpenForm(''));
        }
      })
      .catch((error) => {
        console.log('error ', error);
        if (error.response.data.error.message)
          alert(error.response.data.error.message);
      });
  };

  return updateFormControler;
};

const useDeleteSocialAccount = () => {
  const deleteSocialAccount = async (id) => {
    const userTypeId = parseInt(localStorage.getItem('userTypeId'));
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: '/api/v1/user/deleteSocialAccounts?id=' + id,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    };

    console.log('Token *****', localStorage.getItem('accessToken'));
    console.log('config', config);

    Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          alert('Deleted Successfully');
        }
      })
      .catch((error) => {
        console.log('error ', error.response.data);
        if (error.response.data.error.message)
          alert(error.response.data.error.message);
      });
  };

  return deleteSocialAccount;
};
const useUploadAsset = () => {
  const uploadAsset = async (file, assetCategory, assetType) => {
    try {
      const data = new FormData();
      data.append('asset', file);
      data.append('assetCategory', assetCategory);
      data.append('assetType', assetType);

      const config = {
        method: 'post',
        url: '/api/v1/asset/upload',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: data,
      };

      const response = await Axios.request(config);

      if (response.status === 200) {
        console.log('response.data.data', response.data.data);
        return response.data.assetId;
      } else {
        alert(response.data.error.message);
        throw new Error('Failed to upload asset');
      }
    } catch (error) {
      console.log('error', error);
      alert(error.response.data.error.message);
      throw new Error('Failed to upload asset');
    }
  };

  return uploadAsset;
};

const useDownloadAsset = () => {
  const downloadAsset = async (assetId, assetCategory, assetType) => {
    try {
      let data = JSON.stringify({
        assetId: assetId,
        assetCategory: assetCategory,
        assetType: assetType,
      });

      const config = {
        method: 'post',
        url: '/api/v1/asset/upload',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: data,
      };

      const response = await Axios.request(config);

      if (response.status === 200) {
        console.log('response.data.data', response.data.data);
        return response.data.assetId;
      } else {
        alert(response.data.error.message);
        throw new Error('Failed to upload asset');
      }
    } catch (error) {
      console.log('error', error);
      alert(error.response.data.error.message);
      throw new Error('Failed to upload asset');
    }
  };

  return downloadAsset;
};

export {
  useUpdateFormControler,
  useDeleteSocialAccount,
  useUploadAsset,
  useDownloadAsset,
};
export default useUpdateFormControler;
