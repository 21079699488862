import { createSlice } from '@reduxjs/toolkit';
import { getProfile } from './thunk';

const initialState = {
  personal_info: {
    id: 321,
    organization_name: 'brandTest',
    first_name: '',
    last_name: '',
    email: 'brandTest@gmail.com',
    image_url: null,
    mobile_no: null,
    whatsapp_no: null,
    time_zone: null,
  },
  notification_preferences: {
    emails: null,
    transaction_status: null,
    new_message: null,
    marketplace_offers: null,
    push_notifications: null,
  },
  sub_brands: [],
  openForm: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setBrandProfileData: (state, action) => {
      state.personal_info = action.payload.personal_info;
      state.notification_preferences = action.payload.notification_preferences;
      state.sub_brands = action.payload.sub_brands;
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the fulfilled action for getProfile
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.personal_info = action.payload.personal_info;
      state.notification_preferences = action.payload.notification_preferences;
      state.sub_brands = action.payload.sub_brands;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setBrandProfileData, setOpenForm } = profileSlice.actions;
export default profileSlice.reducer;
