import { useDispatch } from 'react-redux';
import Axios from './../axios';

const getAllBrands = async (sortBy, sortOrder, page, limit, search) => {
  console.log('getAllBrands', 'called');

  let data = {
    sortBy: sortBy,
    sortOrder: sortOrder,
    page: page,
    limit: limit,
  };

  if (search !== '') {
    data.search = search;
  }

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'api/v1/brand/brands',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    params: data,
  };
  console.log('Token *****', localStorage.getItem('accessToken'));
  console.log('config', config);
  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        return [];
      });
  } catch (e) {
    console.log('error getAllBrands ', e);
  }
};

const getBrandDetails = async (brand_id) => {
  console.log('getBrandDetails', 'called');

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'api/v1/brand/' + brand_id,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  };
  console.log('Token *****', localStorage.getItem('accessToken'));
  console.log('config', config);
  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        return [];
      });
  } catch (e) {
    console.log('error getBrandDetails ', e);
  }
};

export { getBrandDetails, getAllBrands };
