import React from 'react';
import tickBlueCircleBg from '../../../assets/common/chatSection/tickBlueCircleBg.svg';
import chatStyles from '../../../styles/common/chatSection/ChatBubble.module.css';

function ChatBubbleCommon({ keyId, msg, imgSrc = tickBlueCircleBg }) {
  return (
    <div className={chatStyles.common_chat} key={keyId}>
      <img src={imgSrc} alt="" />
      {msg}
    </div>
  );
}

export default ChatBubbleCommon;
