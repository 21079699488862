import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://localhost:8000',
  baseURL: 'https://api.hangama.com',
  // baseURL: 'http://192.168.0.173:8000',
});

const chatServiceAxios = axios.create({
  baseURL: 'http://localhost:8000',
  // baseURL: 'http://localhost:5000',
});

export default instance;

export { chatServiceAxios };
