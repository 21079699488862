import React, { useRef, useState } from 'react';
import styles from '../../../styles/common/chatSection/Messages.module.css';
import Icon1 from '../../../assets/brand/influencerProfile/Icon1.jpg';
import { useNavigate } from 'react-router-dom';
import ChatComponent from '../../common/chatSection/ChatComponent';
import { getAllBrands } from '../../../generalApiCalls/brand';

function Messages() {
  const navigate = useNavigate();
  const [user_list, setUser_list] = useState([]);

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  const fetchAllBrands = (sortBy, sortOrder, page, limit, search) => {
    getAllBrands(sortBy, sortOrder, page, limit, search)
      .then((res) => {
        console.log('fetchAllBrands', res.data);
        // remape the data
        let reMappedData = res.data
          .filter((item) => item.status !== 0)
          .map((item, index) => {
            return {
              id: item.user_id,
              userType: item.organization_name,
              userName: item.client_name,
              lastLogin: dateStringToDate(item.app_last_login),
              unseen_count: 5,
              image: Icon1,
            };
          });
        setUser_list(reMappedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useState(() => {
    fetchAllBrands('created_at', 'ASC', 1, 10, '');
  }, []);

  return (
    <div className={styles.messages_main}>
      <div className={styles.messages_container_title}>
        <h3>Messages</h3>
      </div>

      {/* <div
        className={
          tableStyles.tabularContentContainer +
          ' ' +
          styles.tabularContentContainer
        }
      >
        <div className={tableStyles.table_container}>
          <div className={styles.no_data}>
            <p>
              No data found. Boo!{' '}
              <span
                onClick={() => {
                  navigate('/brand/create_campaign');
                }}
              >
                Create a campaign
              </span>{' '}
              so you can start interacting with influencers. Then you'll see
              data here!
            </p>
          </div>
        </div>
      </div> */}

      <div className={styles.chatbox}>
        <div className={styles.users_list}>
          {user_list.map((user) => {
            return (
              <div className={styles.user} key={user.id}>
                <img
                  className={styles.user_image}
                  src={user.image}
                  alt="user"
                />
                <div className={styles.user_details_container}>
                  <p className={styles.user_name}>{user.userName}</p>
                  <p className={styles.user_type}>{user.userType}</p>
                </div>
                <div className={styles.unseen_count}>{user.unseen_count}</div>
              </div>
            );
          })}
        </div>
        {/* <ChatComponent /> */}
      </div>
    </div>
  );
}

export default Messages;
