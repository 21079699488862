import React from 'react';
import styles from '../../../styles/influencer/schedules/Schedules.module.css';
import modalStyles from '../../../styles/brand/commonStyle/commonModal.module.css';
import tableStyles from '../../../styles/influencer/commonStyle/commonTable.module.css';
import cocaCola from '../../../assets/brand/campaigns/cocaCola.png';
import PencilIcon from '../../../assets/common/Pencil-alt.svg';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';

function Schedules() {
  const campaignSummaryData = [
    { title: 'UPCOMING SCHEDULES', value: 55, color: '#e3c700' },
    { title: 'NEXT SCHEDULE DATE', value: 33, color: '#2a76f4' },
  ];

  const campaignsListData = [
    {
      id: 1,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brandName: 'Coca Cola',
      startDate: '01/Jan/2021',
      endDate: '08/Jan/2021',
      status: 'Running',
      notes: 'lorem ipsum',
      brandLogo: cocaCola,
    },
    {
      id: 2,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brandName: 'Coca Cola',
      startDate: '01/Jan/2021',
      endDate: '08/Jan/2021',
      status: 'Completed',
      notes: 'lorem ipsum',
      brandLogo: cocaCola,
    },
  ];

  const uniqueStatusColorMapping = {
    Draft: '#f16d75',
    Running: '#49a240',
    Pitches: '#1d212f',
    Completed: '#49a240',
  };

  const uniqueStatus = Object.keys(uniqueStatusColorMapping);

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Campaign Name',
      placeHolder: 'Campaign Name',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Offer Amount',
      placeHolder: 'Offer Amount',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Campaign Amount',
      placeHolder: 'Campaign Amount',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Brand Name',
      placeHolder: 'Brand Name',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Start Date',
      placeHolder: 'Start Date',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'End Date',
      placeHolder: 'End Date',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Status',
      placeHolder: 'Status',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: false,
    },
    {
      fieldName: 'Action',
      placeHolder: 'Action',
      searchHeader: false,
    },
  ];

  const [notes, setNotes] = useState('');
  const [showModal, setShowModal] = useState(false);

  const popUPModal = (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>Add Notes</div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              setShowModal(false);
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          <p>Notes</p>
          <textarea
            type="text"
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button className={modalStyles.btn_request} onClick={() => {}}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.historyMain}>
      {showModal && popUPModal}
      <div className={styles.topCardsContainer}>
        {campaignSummaryData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}></div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {campaignsListData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td className={tableStyles.brand_name__logo}>
                      {/* <img
                        className={tableStyles.table_image_brand}
                        src={item.brandLogo}
                        alt="brandLogo"
                      />{' '}
                      {item.campaignName} */}

                      <div className={tableStyles.brand_name__logo}>
                        <img
                          className={tableStyles.table_image_brand_big}
                          src={item.brandLogo}
                          alt="brandLogo"
                        />{' '}
                        {item.campaignName}
                      </div>
                    </td>
                    <td>{item.offerAmount}</td>
                    <td>{item.campaignAmount}</td>
                    <td>{item.brandName}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td
                      style={{
                        color: uniqueStatusColorMapping[item.status],
                      }}
                    >
                      {item.status}
                    </td>

                    <td>
                      <button
                        onClick={() => {
                          setShowModal(true);
                        }}
                        className={styles.campaigns_table_btn_edit}
                      >
                        <img src={PencilIcon} alt="" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedules;
