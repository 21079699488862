// Importing React and useState hook
import React, { useEffect, useState } from 'react';

// Importing CSS module
import styles from '../../styles/brand/dashboard/Dashboard.module.css';

// Importing components
import DashboardConetnt from './dashboard/DashboardConetnt';
import Sidebar from './sidebar/Sidebar';
import Header from '../common/header/Header';
import InfluencerProfile from './influencers/influencerProfile/InfluencerProfile';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Campaigns from './campaigns/Campaigns';
import CreateCampaign from './campaigns/createCampaign/CreateCampaign';
import PaymentsHistory from './payments/PaymentsHistory';
import ListOfInfluencers from './influencers/listOfInfluencers/ListOfInfluencers';
import InfluencerOffers from './offers/InfluencerOffers';
import OneInfluencerOffer from './offers/oneInfluencerOffer/OneInfluencerOffer';
import HungamaOffers from './offers/HungamaOffers';
import ReferralBrand from './referral/ReferralBrand';
import ReferralInfluencers from './referral/ReferralInfluencers';
import CampaignSummary from './reports/CampaignSummary';
import CampaignTransactionSummary from './reports/CampaignTransactionSummary';
import PostedLinks from './reports/PostedLinks';
import Messages from './messages/Messages';
import BrandMyProfile from './myProfile/BrandMyProfile';
import CampaignDetails from './campaigns/campaignDetails/CampaignDetails';

// Defining Dashboard component
const Dashboard = () => {
  // Using useState hook to create sidebarActive state and setSidebarActive function
  const [sidebarActive, setSidebarActive] = useState(false);
  const [mobileHeaderHide, setMobileHeaderHide] = useState(true);
  const [isDesktop, setIsDesktop] = useState(false);

  // const handleResize = () => {
  //   if (window.innerWidth > 700) {
  //     setMobileHeaderHide(true);
  //   } else {
  //     setMobileHeaderHide(false);
  //     setSidebarActive(false);
  //   }
  // };

  const handleResize = () => {
    if (window.innerWidth > 700) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
    console.log('setIsDesktop ', setIsDesktop);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Returning JSX
  return (
    <div className={styles.brand_dashboard}>
      {/* Rendering Sidebar component */}
      <Sidebar
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />

      {/* Container for dashboard content */}
      <div className={styles.brand_dashboard_container}>
        {/* Rendering Header component */}
        <Header
          setSidebarActive={setSidebarActive}
          sidebarActive={sidebarActive}
          mobileHeaderHide={mobileHeaderHide}
          setMobileHeaderHide={setMobileHeaderHide}
          isDesktop={isDesktop}
          setIsDesktop={setIsDesktop}
        />

        {/* Rendering DashboardConetnt component */}

        <div className={styles.brand_dashboard_content}>
          {/* <DashboardConetnt /> */}
          {/* <InfluencerProfile /> */}
          <Routes>
            <Route path="/" element={<DashboardConetnt />} />
            <Route
              exact
              path="/influencer/:influencer_id"
              element={<InfluencerProfile />}
            />

            <Route exact path="/campaigns" element={<Campaigns />} />
            <Route
              path="/create_campaign/:campaignId?"
              element={<CreateCampaign />}
            />
            <Route
              path="/campaign_overview/:campaignId?"
              element={<CampaignDetails />}
            />
            <Route
              exact
              path="/payment_history"
              element={<PaymentsHistory />}
            />
            <Route
              exact
              path="/list_of_influencers"
              element={<ListOfInfluencers />}
            />
            <Route
              exact
              path="/list_of_influencer_offers"
              element={<InfluencerOffers />}
            />
            <Route exact path="/messages" element={<Messages />} />
            <Route
              exact
              path="/influencer_offer/:offer_id"
              element={<OneInfluencerOffer />}
            />
            <Route exact path="/hungama_offers" element={<HungamaOffers />} />
            <Route exact path="/referral_brands" element={<ReferralBrand />} />
            <Route
              exact
              path="/referral_influencers"
              element={<ReferralInfluencers />}
            />
            <Route path="/campaign_summary" element={<CampaignSummary />} />
            <Route
              path="/campaign_transaction_summary"
              element={<CampaignTransactionSummary />}
            />
            <Route path="/posted_links" element={<PostedLinks />} />
            <Route exact path="/profile" element={<BrandMyProfile />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

// Exporting Dashboard component
export default Dashboard;
