import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from '../../../axios';

const logout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userTypeValue');
  localStorage.removeItem('userTypeId');
  window.open('/UserOnBoarding', '_self');
};

const getProfile = createAsyncThunk('profile/getProfile', async () => {
  try {
    let config = {
      method: 'get',
      url: '/api/v1/user/getProfile',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200) {
          console.log('response.data.data', response.data.data);
          return response.data.data;
        } else {
          logout();
          alert(response.data.error.message);
        }
      })
      .catch((error) => {
        logout();
        alert(error.response.data.error.message);
        throw new Error('Failed to fetch profile data');
      });
  } catch (error) {
    logout();
    throw new Error('Failed to fetch profile data');
  }
});

export { getProfile };
export default getProfile;
