import React from 'react';
import styles from '../../../styles/brand/offers/HungamaOffers.module.css';
import styles_hungama from '../../../styles/brand/offers/InfluencerOffers.module.css';

import PlusIcon from '../../../assets/brand/Plus.svg';
import CopyIcon from '../../../assets/brand/Copy.svg';

import PlusWhiteIcon from '../../../assets/brand/Plus-white.svg';
import CopyBlueIcon from '../../../assets/brand/Copy-blue.svg';
import PencilIcon from '../../../assets/brand/Pencil-alt.svg';

import cocaCola from '../../../assets/brand/campaigns/cocaCola.png';

import SearchIcon from '../../../assets/brand/Search.svg';
import filterIcon from '../../../assets/brand/offers/filter.svg';

import sallybot from '../../../assets/brand/offers/sallybot.png';

import { useNavigate } from 'react-router-dom';
function HungamaOffers() {
  const navigate = useNavigate();
  const statsData = [
    {
      title: 'Pitched',
      value: 0,
    },
    {
      title: 'Accpeted',
      value: 0,
    },

    {
      title: 'Declned',
      value: 0,
    },
    {
      title: 'Completed',
      value: 0,
    },
    {
      title: 'Need Action',
      value: 0,
    },
  ];

  const hungamaOffersData = [
    {
      id: 1,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Running',
      brandLogo: cocaCola,
    },
    {
      id: 2,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,

      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Completed',
      brandLogo: cocaCola,
    },
    {
      id: 3,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Draft',
      brandLogo: cocaCola,
    },
    {
      id: 4,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Pitches',
      brandLogo: cocaCola,
    },
    {
      id: 1,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Running',
      brandLogo: cocaCola,
    },
    {
      id: 2,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,

      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Completed',
      brandLogo: cocaCola,
    },
    {
      id: 3,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Draft',
      brandLogo: cocaCola,
    },
    {
      id: 4,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Pitches',
      brandLogo: cocaCola,
    },
    {
      id: 1,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Running',
      brandLogo: cocaCola,
    },
    {
      id: 2,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,

      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Completed',
      brandLogo: cocaCola,
    },
    {
      id: 3,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Draft',
      brandLogo: cocaCola,
    },
    {
      id: 4,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Pitches',
      brandLogo: cocaCola,
    },
    {
      id: 1,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Running',
      brandLogo: cocaCola,
    },
    {
      id: 2,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,

      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Completed',
      brandLogo: cocaCola,
    },
    {
      id: 3,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Draft',
      brandLogo: cocaCola,
    },
    {
      id: 4,
      campaignName: 'Coca Cola Zero Calories',
      offerAmount: 500,
      campaignAmount: 150,
      brand: 'Coca Cola',
      startDate: '01/Jan/2021',
      cashValue: 6,
      productValue: 8,
      totalBudget: 200,
      campaignType: 'Social Share',
      status: 'Pitches',
      brandLogo: cocaCola,
    },
  ];

  const statusColorClass = {
    Running: styles.status_running,
    Completed: styles.status_completed,
    Draft: styles.status_draft,
    Pitches: styles.status_pitches,
  };

  const total_offers = 0;

  return (
    <div className={styles.hungamaOffers}>
      <div className={styles.hungamaOffers_header}>
        <h3>Hangama Offers</h3>
        <div className={styles.button_group}>
          <button
            className={styles.hungamaOffers_header_btn_create}
            onClick={() => navigate('/brand/create_campaign')}
          >
            <img src={PlusWhiteIcon} alt="" />
            <span>Discover Offers</span>
          </button>
        </div>
      </div>
      <div className={styles.stats_row}>
        {statsData.map((item, index) => {
          return (
            <div key={index} className={styles.stats_box}>
              <h4 className={styles.stats_box_title}>{item.title}</h4>
              <div className={styles.stats_box_value}>{item.value}</div>
            </div>
          );
        })}
      </div>

      <div className={styles_hungama.InfluencerOffers_header_searchbar}>
        <img src={SearchIcon} alt="search" />
        <input
          type="text"
          placeholder="Search by name or keyword"
          onChange={(e) => {
            // searchOnAllValues(e.target.value);
          }}
        />
        <div className={styles_hungama.total_offers_number}>
          <span>{total_offers} </span>
          <span>results</span>
        </div>
        {/* <div > */}
        <button className={styles_hungama.advanced_filter}>
          <img src={filterIcon} alt="" />
          <span>Advanced Filters</span>
        </button>
        {/* </div> */}
      </div>

      {/* <div className={styles.hungamaOffers_table_container}>
        <div className={styles.hungamaOffers_table_container_card}>
          <div className={styles.hungamaOffers_table_container_card_header}>
            <div className={styles.hungamaOffers_table_container_card_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={styles.hungamaOffers_table_container_card_filter}>
              <span>Search</span>
              <input type="text"></input>
            </div>
          </div>

          <table className={styles.hungamaOffers_table}>
            <thead>
              <tr className={styles.campaignFilterSearch}>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <input type="text"></input>
                </th>
                <th>
                  <select name="" id="">
                    <option value="All">All</option>
                    <option value="Running">Running</option>
                    <option value="Completed">Completed</option>
                    <option value="Draft">Draft</option>
                    <option value="Pitches">Pitches</option>
                  </select>
                </th>
              </tr>
              <tr className={styles.campaignTableHeader}>
                <th>#</th>
                <th>
                  <img src="" alt="" />
                  Campaign Name
                </th>
                <th>Offer Amount</th>
                <th>Campaign Amount</th>
                <th>Brand</th>
                <th>Start Date</th>
                <th>Cash Value</th>
                <th>Product Value</th>
                <th>Total Budget</th>
                <th>Campaign Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {hungamaOffersData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td className={styles.brand_name__logo}>
                      <img
                        className={styles.table_image_brand}
                        src={item.brandLogo}
                        alt="brandLogo"
                      />{' '}
                      {item.campaignName}
                    </td>
                    <td>{item.offerAmount}</td>
                    <td>{item.campaignAmount}</td>
                    <td>{item.brand}</td>
                    <td>{item.startDate}</td>
                    <td>{item.cashValue}</td>
                    <td>{item.productValue}</td>
                    <td>{item.totalBudget}</td>
                    <td>{item.campaignType}</td>
                    <td className={statusColorClass[item.status]}>
                      {item.status}
                    </td>
                    <td>
                      <button className={styles.hungamaOffers_table_btn_edit}>
                        <img src={PencilIcon} alt="" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={styles.hungamaOffers_table_container_card_footer}>
            <div className={styles.recordShowingStats}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div
              className={
                styles.hungamaOffers_table_container_card_footer_pagination
              }
            >
              <button
                className={
                  styles.hungamaOffers_table_container_card_footer_pagination_prev
                }
              >
                Previous
              </button>
              <div
                className={styles.hungamaOffers_table_container_card_footer_page_no}
              >
                1
              </div>
              <button
                className={
                  styles.hungamaOffers_table_container_card_footer_pagination_next
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div className={styles.no_data_container}>
        <div className={styles.no_data_card}>
          <img src={sallybot} alt="" />
          <h2>Let's get you some offers!</h2>
          <p>
            You don't have any offers started yet. Let's change that! Head over
            to the discover offers page so you can pitch some influencers.
          </p>
          <button onClick={() => navigate('/brand/create_campaign')}>
            Launch Campaign Wizard
          </button>
        </div>
      </div>
    </div>
  );
}

export default HungamaOffers;
