import React from 'react';
import styles from '../../../styles/common/chatSection/Messages.module.css';
import Icon1 from '../../../assets/brand/influencerProfile/Icon1.jpg';
import { useNavigate } from 'react-router-dom';
import ChatComponent from '../../common/chatSection/ChatComponent';

function Messages() {
  const navigate = useNavigate();

  const user_list = [
    {
      id: 1,
      name: 'Jaydeep Kumar',
      type: 'Admin',
      unseen_count: 5,
      image: Icon1,
    },
    {
      id: 2,
      name: 'Jaydeep Kumar',
      type: 'Admin',
      unseen_count: 5,
      image: Icon1,
    },
    {
      id: 3,
      name: 'Jaydeep Kumar',
      type: 'Admin',
      unseen_count: 5,
      image: Icon1,
    },
    {
      id: 4,
      name: 'Jaydeep Kumar',
      type: 'Admin',
      unseen_count: 5,
      image: Icon1,
    },
    {
      id: 5,
      name: 'Jaydeep Kumar',
      type: 'Admin',
      unseen_count: 5,
      image: Icon1,
    },
    {
      id: 6,
      name: 'Jaydeep Kumar',
      type: 'Admin',
      unseen_count: 5,
      image: Icon1,
    },
    {
      id: 7,
      name: 'Jaydeep Kumar',
      type: 'Admin',
      unseen_count: 5,
      image: Icon1,
    },
    {
      id: 8,
      name: 'Jaydeep Kumar',
      type: 'Admin',
      unseen_count: 5,
      image: Icon1,
    },
    {
      id: 9,
      name: 'Jaydeep Kumar',
      type: 'Admin',
      unseen_count: 5,
      image: Icon1,
    },
  ];

  return (
    <div className={styles.messages_main}>
      <div className={styles.messages_container_title}>
        <h3>Messages</h3>
      </div>

      {/* <div
        className={
          tableStyles.tabularContentContainer +
          ' ' +
          styles.tabularContentContainer
        }
      >
        <div className={tableStyles.table_container}>
          <div className={styles.no_data}>
            <p>
              No data found. Boo!{' '}
              <span
                onClick={() => {
                  navigate('/brand/create_campaign');
                }}
              >
                Create a campaign
              </span>{' '}
              so you can start interacting with influencers. Then you'll see
              data here!
            </p>
          </div>
        </div>
      </div> */}

      <div className={styles.chatbox}>
        <div className={styles.users_list}>
          {user_list.map((user) => {
            return (
              <div className={styles.user} key={user.id}>
                <img
                  className={styles.user_image}
                  src={user.image}
                  alt="user"
                />
                <div className={styles.user_details_container}>
                  <p className={styles.user_name}>{user.name}</p>
                  <p className={styles.user_type}>{user.type}</p>
                </div>
                <div className={styles.unseen_count}>{user.unseen_count}</div>
              </div>
            );
          })}
        </div>
        <ChatComponent />
      </div>
    </div>
  );
}

export default Messages;
