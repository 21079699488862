import React from 'react';
import sectionTablestyles from '../../../styles/influencer/myProfile/SectionTablestyles.module.css';
import ProfileIcon from '../../../assets/brand/ProfileIcon.jpg';

function TwoColumnTable({ tableData }) {
  console.log('tableData', tableData);

  return (
    <div className={sectionTablestyles.sectionTableContainer}>
      <table className={sectionTablestyles.sectionTable}>
        <tbody>
          {tableData[0]?.value1 === 'Profile Photo' && (
            <tr className={sectionTablestyles.ProfileIcon}>
              <td>Profile Photo</td>
              <td>
                <img src={ProfileIcon} alt="ProfileIcon" />
              </td>
            </tr>
          )}

          {tableData.map(
            (data, index) =>
              data.value1 !== 'Profile Photo' && (
                <tr key={index}>
                  {Object.values(data).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TwoColumnTable;
