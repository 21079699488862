import Axios from './../axios';
const addReferral = async (data) => {
  console.log('addReferral', 'called');
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'api/v1/referral/addReferral',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    data: { data },
  };

  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        console.log('addReferral error', error);
      });
  } catch (e) {
    console.log('error addReferral ', e);
  }
};

const getAllReferral = async (referaltype, page, limit, search) => {
  console.log('getAllReferral', 'called');
  let data = {
    referral_type: referaltype,
    page: page,
    limit: limit,
  };

  if (search !== '' && search !== undefined) {
    data.search = search;
  }

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'api/v1/referral/listReferral',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    params: data,
  };
  console.log('Token *****', localStorage.getItem('accessToken'));
  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        console.log('getAllReferral error', error);
        return [];
      });
  } catch (e) {
    console.log('error getAllReferral ', e);
  }
};

export { addReferral, getAllReferral };
