import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './sections/brand/Dashboard';
import AdminDashboard from './sections/admin/AdminDashboard';
import UserOnBoarding from './sections/common/userOnBoarding/UserOnBoarding';
import InfluencerDashboard from './sections/influencer/InfluencerDashboard';
import Home from './sections/home/Home';
import GoogleSignIn from './sections/common/userOnBoarding/GoogleSignIn';
import FacebookSignIn from './sections/common/userOnBoarding/FacebookSignIn';
import AdminLoginForm from './sections/common/userOnBoarding/AdminLoginForm';
import TwitterSignIn from './sections/common/userOnBoarding/TwitterSignIn';

const App = () => {
  // Get the userType from localStorage
  const userTypeId = localStorage.getItem('userTypeId')
    ? parseInt(parseInt(localStorage.getItem('userTypeId')))
    : null;

  // Function to check if the user is authorized to access the brand section
  const isBrandUser = () => {
    return userTypeId === 2;
  };

  // Function to check if the user is authorized to access the influencer section
  const isInfluencerUser = () => {
    return userTypeId === 1;
  };

  const isSuperAdmin = () => {
    return userTypeId === 3;
  };

  // Protected route component for brand section
  const ProtectedBrandRoute = ({ element }) => {
    if (isBrandUser()) {
      return element;
    } else {
      return <Navigate to="/UserOnBoarding" />;
    }
  };

  // Protected route component for influencer section
  const ProtectedInfluencerRoute = ({ element }) => {
    if (isInfluencerUser()) {
      return element;
    } else {
      return <Navigate to="/UserOnBoarding" />;
    }
  };

  // Protected route component for admin section
  const ProtectedAdminRoute = ({ element }) => {
    if (isSuperAdmin()) {
      return element;
    } else {
      return <Navigate to="/adminlogin" />;
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/UserOnBoarding" element={<UserOnBoarding />} />
          <Route exact path="/adminlogin" element={<AdminLoginForm />} />
          <Route
            path="/admin/*"
            element={<ProtectedAdminRoute element={<AdminDashboard />} />}
          />
          <Route
            path="/brand/*"
            element={<ProtectedBrandRoute element={<Dashboard />} />}
          />
          <Route
            path="/influencer/*"
            element={
              <ProtectedInfluencerRoute element={<InfluencerDashboard />} />
            }
          />
          <Route path="/auth/google/success" exact element={<GoogleSignIn />} />
          <Route
            path="/auth/google/failure"
            exact
            element={<UserOnBoarding />}
          />

          <Route
            path="/auth/facebook/success"
            exact
            element={<FacebookSignIn />}
          />
          <Route
            path="/auth/twitter/success"
            exact
            element={<TwitterSignIn />}
          />
          <Route
            path="/auth/facebook/failure"
            exact
            element={<UserOnBoarding />}
          />

          {/* <Route path="*" element={<404Page />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
