import React from 'react';
import styles from '../../../styles/brand/helperComponents/DesignerBackgroundCard.module.css';

function DesignerBackgroundCard({ cardData }) {
  const currencySuffixAndPrefix = {
    USD: { prefix: '$', suffix: 'USD' },
  };

  return (
    <div className={styles.container_design}>
      <div className={styles.background_overlay}></div>
      <div className={styles.titleBox}>{cardData.title}</div>
      <div
        style={{ color: cardData.color }}
        className={styles.contentBoxForNumbers}
      >
        {cardData.currency ? (
          <div>
            {cardData.currency &&
              currencySuffixAndPrefix[cardData.currency].prefix}
            {cardData.value}{' '}
            {cardData.currency &&
              currencySuffixAndPrefix[cardData.currency].suffix}
          </div>
        ) : (
          <div>{cardData.value}</div>
        )}
      </div>
    </div>
  );
}

export default DesignerBackgroundCard;
