import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import jwt_decode from 'jwt-decode';
import { chatServiceAxios } from '../../../axios';

import chatStyles from '../../../styles/common/chatSection/ChatComponent.module.css';
import msgSend from '../../../assets/common/chatSection/msgSend.svg';
import profileThumnail from '../../../assets/common/chatSection/profileThumnail.svg';
import ChatBubbleCommon from './ChatBubbleCommon';
import ChatBubbleReceiver from './ChatBubbleReceiver';
import ChatBubbleSender from './ChatBubbleSender';
// import axios from '../../../axios';
import axios from '../../../axios';

const { nanoid } = require('nanoid');

function ChatComponent({ receiverId, otherPersonProfileUrl }) {
  const dispatch = useDispatch();
  const socket = useRef();

  const [yourProfileUrl, setYourProfileUrl] = useState(profileThumnail);

  // const currentUserType = useSelector((state) => state).currentUserType
  //   .currentUserType;
  const currentUserType = localStorage.getItem('userTypeValue');
  const userData = useSelector((state) => state).basicInfo;

  // Input controller
  const [msg, setMsg] = useState('');
  // All messages in chat

  const dummy_messages = [
    {
      id: 0,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a1',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a2',
      message: 'Started Chatting on 16th July 2021',
      senderType: 'Brand',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a1',
      isCommon: true,
      seen: false,
    },
    {
      id: 1,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a1',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a2',
      message: 'Hello1',
      senderType: 'Brand',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a1',
      isCommon: false,
      seen: false,
    },
    {
      id: 2,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a2',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a1',
      message: 'Hello2',
      senderType: 'Influencer',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a2',
      isCommon: false,
      seen: false,
    },
    {
      id: 3,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a1',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a2',
      message: 'Hello3',
      senderType: 'Brand',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a1',
      isCommon: false,
      seen: false,
    },
    {
      id: 4,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a2',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a1',
      message: 'Hello4',
      senderType: 'Influencer',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a2',
      isCommon: false,
      seen: false,
    },
    {
      id: 5,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a1',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a2',
      message: 'Hello5',
      senderType: 'Brand',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a1',

      isCommon: false,
      seen: false,
    },
    {
      id: 6,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a2',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a1',
      message: 'Hello6',
      senderType: 'Influencer',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a2',
      isCommon: false,
      seen: false,
    },
    {
      id: 7,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a1',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a2',
      message: 'Hello7',
      senderType: 'Brand',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a1',
      isCommon: false,
      seen: false,
    },
    {
      id: 8,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a2',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a1',
      message: 'Hello8',
      senderType: 'Influencer',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a2',
      isCommon: false,
      seen: false,
    },
    {
      id: 9,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a1',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a2',
      message: 'Hello9',
      senderType: 'Brand',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a1',
      isCommon: false,
      seen: false,
    },
    {
      id: 10,
      senderId: '60f0a7b3e6b3a1b0b4f7b3a2',
      receiverId: '60f0a7b3e6b3a1b0b4f7b3a1',
      message: 'Hello10',
      senderType: 'Influencer',
      messageId: '60f0a7b3e6b3a1b0b4f7b3a2',
      isCommon: false,
      seen: false,
    },
  ];

  const [messages, setMessages] = useState(dummy_messages);
  const [arrivalMessage, setArrivalMessage] = useState(null);

  const [takeToBottom, setTakeToBottom] = useState(true);

  const [messageIdSeenList, setMessageIdSeenList] = useState([]);

  // const host = 'https://api-chat.easyacquire.co';
  const host = 'http://localhost:8000/chatService';
  // const currentUserId = jwt_decode(localStorage.getItem('accessToken'))._id;

  // const sendChat = (event) => {
  //   event.preventDefault();
  //   if (msg.length > 0) {
  //     handleSendMsg(msg);
  //     setMsg('');
  //   }
  // };

  const currentUserId = localStorage.getItem('accessToken');

  useEffect(() => {
    if (currentUserType && currentUserId) {
      socket.current = io(host);
      socket.current.emit('adduser', currentUserId);
      console.log('socket connected');
    }
    // if (socket.current) {
    //   socket.current.on('msg-recieve', (msgObj) => {
    //     setArrivalMessage({
    //       senderId: msgObj?.senderId,
    //       receiverId: msgObj?.receiverId,
    //       message: msgObj?.message,
    //       senderType: msgObj?.senderType,
    //       messageId: msgObj?.messageId,
    //       isCommon: msgObj?.isCommon,
    //       seen: msgObj?.seen,
    //     });
    //   });
    // }
  }, []);

  // const getAllMessages = async () => {
  //   try {
  //     const response = await chatServiceAxios.post('/api/messages/getmsg', {
  //       from: currentUserId,
  //       to: receiverId,
  //       senderType: currentUserType,
  //     });
  //     if (response.data.success) {
  //       setMessages(response.data?.data);
  //     }
  //   } catch (err) {
  //     console.log('Error while getting Startup details', err);
  //     dispatch(
  //       showErrorMsg({
  //         hasError: true,
  //         errorMsg: 'Could not fetch mesages',
  //       })
  //     );
  //   }
  // };

  // useEffect(() => {
  //   if (currentUserType && receiverId) {
  //     getAllMessages();
  //   }
  // }, [receiverId]);

  const handleSendMsg = async (msgValue) => {
    const msgId = nanoid();
    socket.current.emit('send-msg', {
      receiverId: receiverId,
      senderId: currentUserId,
      message: msgValue,
      senderType: currentUserType,
      messageId: msgId,
      isCommon: false,
      seen: false,
    });
    await chatServiceAxios.post('/api/messages/addmsg', {
      senderId: currentUserId,
      receiverId: receiverId,
      message: msgValue,
      senderType: currentUserType,
      messageId: msgId,
      isCommon: false,
      seen: false,
    });

    setMessages([
      ...messages,
      {
        senderId: currentUserId,
        receiverId: receiverId,
        message: msgValue,
        senderType: currentUserType,
        messageId: msgId,
        isCommon: false,
        seen: false,
      },
    ]);
  };

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    const out = document.getElementById('messages_body');

    if (takeToBottom) {
      out.scrollTop = out.scrollHeight - out.clientHeight;
    }
  }, [messages]);

  const getFileData = async (assetIdValue) => {
    if (!assetIdValue) {
      return;
    }
    const imageExtension = 'image/' + assetIdValue.split('.').pop();
    try {
      const allDataBindingUp1 = {
        assetId: assetIdValue,
        assetType: 'IMAGE',
      };
      const allDataBindingUp2 = {
        accessToken: localStorage.getItem('accessToken'),
      };

      let x = await axios.post('/api/asset/download', allDataBindingUp1, {
        headers: allDataBindingUp2,
        responseType: 'blob',
      });

      const file = new Blob([x.data], { type: imageExtension });
      const fileURL = URL.createObjectURL(file);
      setYourProfileUrl(fileURL);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (currentUserType === currentUserType) {
      getFileData(userData?.profilePictureUrl);
    }
    if (currentUserType === currentUserType) {
      getFileData(userData?.privateInformation?.profilePictureUrl);
    }
  }, []);

  return (
    <div className={chatStyles.main}>
      <div
        className={chatStyles.messages_body}
        id="messages_body"
        onScroll={(e) => {
          const bottom =
            e.target.scrollHeight - e.target.scrollTop ===
            e.target.clientHeight;
          setTakeToBottom(bottom);
        }}
      >
        {messages?.map((msgObj, index) => {
          if (msgObj?.isCommon) {
            return (
              msgObj?.isCommon && (
                <ChatBubbleCommon
                  key={msgObj?.messageId}
                  keyId={msgObj?.messageId}
                  msg={msgObj?.message}
                />
              )
            );
          } else if (msgObj?.senderId === currentUserId) {
            return (
              <ChatBubbleReceiver
                keyId={msgObj?.messageId}
                key={msgObj?.messageId}
                msg={msgObj?.message}
                profileThumnail={yourProfileUrl}
                msgReceiverName="You"
              />
            );
          } else if (msgObj?.senderId !== currentUserId) {
            return (
              msgObj?.senderId !== currentUserId && (
                <ChatBubbleSender
                  keyId={msgObj?.messageId}
                  key={msgObj?.messageId}
                  msg={msgObj?.message}
                  seen={msgObj?.seen}
                  msgSenderName={
                    msgObj?.senderType.charAt(0).toUpperCase() +
                    msgObj?.senderType.substr(1).toLowerCase()
                  }
                  profileThumnailSrc={otherPersonProfileUrl}
                  setMessageIdSeenList={setMessageIdSeenList}
                  messageIdSeenList={messageIdSeenList}
                  socket={socket}
                />
              )
            );
          }
        })}
      </div>
      <div className={chatStyles.footer}>
        {/* <img src={msgSend} alt="" /> */}
        <textarea
          className={chatStyles.chat_msg_input}
          placeholder="Type message here"
          onChange={(e) => setMsg(e.target.value)}
          value={msg}
          // onKeyDown={(event) => {
          //   if (event.key === 'Enter' && !event.shiftKey) {
          //     sendChat(event);
          //   }
          // }}
        />
        <img
          className={chatStyles.chat_msg_send_logo}
          src={msgSend}
          alt=""
          // onClick={(event) => sendChat(event)}
        />
      </div>
    </div>
  );
}

export default ChatComponent;
