import { useDispatch } from 'react-redux';
import Axios from './../axios';

const getAllCampaigns = async (sort, filter, page, limit) => {
  console.log('getAllCampaigns', 'called');
  let data = {
    sort: sort,
    filter: filter,
    page: page,
    limit: limit,
  };

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'api/v1/campaign/listCampaigns',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    params: data,
  };

  console.log('Token *****', localStorage.getItem('accessToken'));
  console.log('config', config);
  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        return [];
      });
  } catch (e) {
    console.log('error getAllCampaigns ', e);
  }
};

const getCampaignDetails = async (campaignDetails) => {
  console.log('getCampaignDetails', 'called');

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'api/v1/campaign/' + campaignDetails,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  };
  console.log('Token *****', localStorage.getItem('accessToken'));
  console.log('config', config);
  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        return [];
      });
  } catch (e) {
    console.log('error getCampaignDetails ', e);
  }
};

const sendConsentForCampaign = async (campaign_id, status) => {
  console.log('sendConsentForCampaign', 'called');

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'api/v1/campaign/approveOrReject',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    data: {
      campaign_id: campaign_id,
      status: status,
    },
  };
  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return true;
        }
      })
      .catch((error) => {
        return false;
      });
  } catch (e) {
    console.log('error sendConsentForCampaign ', e);
    return false;
  }
};

export { getAllCampaigns, getCampaignDetails, sendConsentForCampaign };
