import React from 'react';
import styles from '../../../styles/influencer/badges/Badges.module.css';
import tableStyles from '../../../styles/influencer/commonStyle/commonTable.module.css';
import cocaCola from '../../../assets/brand/campaigns/cocaCola.png';
import EyeIcon from '../../../assets/common/eye-white.svg';

// badges
import badge_1 from '../../../assets/badges/badge_1.png';
import badge_2 from '../../../assets/badges/badge_2.png';
import badge_3 from '../../../assets/badges/badge_3.png';
import badge_4 from '../../../assets/badges/badge_4.png';
import badge_5 from '../../../assets/badges/badge_5.png';
import badge_6 from '../../../assets/badges/badge_6.png';
import badge_7 from '../../../assets/badges/badge_7.png';
import badge_8 from '../../../assets/badges/badge_8.png';

import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';

function BadgesList() {
  const campaignSummaryData = [
    { title: 'TOTAL BADGES', value: 55, color: '#e3c700' },
    { title: 'EARNED BADGES', value: 20, color: '#49a240' },
    { title: 'PENDING BADGES', value: 33, color: '#2a76f4' },
  ];

  // bages name and images
  const badges = [
    { img: badge_1, name: 'Top Influencers' },
    { img: badge_2, name: 'Top Influencers' },
    { img: badge_3, name: 'High Closer' },
    { img: badge_4, name: 'Top Influencers' },
    { img: badge_5, name: 'Top Influencers' },
    { img: badge_6, name: 'Top Influencers' },
    { img: badge_7, name: 'Top Influencers' },
    { img: badge_8, name: 'Top Influencers' },
  ];

  const badgesEarnedListData = [
    {
      id: 1,
      badgeIcon: badge_1,
      badgeName: 'Top Influencers',
      earnedDate: '01/Jan/2021',
      description: '01/Jan/2021',
      status: 'Active',
    },
    {
      id: 2,
      badgeIcon: badge_2,
      badgeName: 'Top Influencers',
      earnedDate: '01/Jan/2021',
      description: '01/Jan/2021',
      status: 'Active',
    },
    {
      id: 3,
      badgeIcon: badge_3,
      badgeName: 'Top Influencers',
      earnedDate: '01/Jan/2021',
      description: '01/Jan/2021',
      status: 'Active',
    },
    {
      id: 4,
      badgeIcon: badge_4,
      badgeName: 'Top Influencers',
      earnedDate: '01/Jan/2021',
      description: '01/Jan/2021',
      status: 'Active',
    },
    {
      id: 5,
      badgeIcon: badge_5,
      badgeName: 'Top Influencers',
      earnedDate: '01/Jan/2021',
      description: '01/Jan/2021',
      status: 'Active',
    },
    {
      id: 6,
      badgeIcon: badge_6,
      badgeName: 'Top Influencers',
      earnedDate: '01/Jan/2021',
      description: '01/Jan/2021',
      status: 'In Active',
    },
    {
      id: 7,
      badgeIcon: badge_7,
      badgeName: 'Top Influencers',
      earnedDate: '01/Jan/2021',
      description: '01/Jan/2021',
      status: 'In Active',
    },
    {
      id: 8,
      badgeIcon: badge_8,
      badgeName: 'Top Influencers',
      earnedDate: '01/Jan/2021',
      description: '01/Jan/2021',
      status: 'In Active',
    },
  ];

  const uniqueStatusColorMapping = {
    'In Active': '#f16d75',
    Active: '#49a240',
  };

  const uniqueStatus = Object.keys(uniqueStatusColorMapping);

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Badge Icon',
      placeHolder: 'Badge Icon',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Badge Name',
      placeHolder: 'Badge Name',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Earned Date',
      placeHolder: 'Earned Date',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Description',
      placeHolder: 'Description',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Status',
      placeHolder: 'Status',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: false,
    },
    {
      fieldName: 'Details',
      placeHolder: 'Details',
      searchType: 'textInput',
    },
  ];

  const [showModal, setShowModal] = useState(false);

  const popUPModal = (
    <div className={styles.modalMain}>
      <div className={styles.modal_container}>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_title}>Request Payment</div>
          <div
            className={styles.modal_header_close_button}
            onClick={() => {
              setShowModal(false);
            }}
          >
            X
          </div>
        </div>
        <div className={styles.modal_body}>
          <p>Amount in Wallet</p>
          <input type="number" name="" id="" />
          <p>Request Date</p>
          <input type="date" name="" id="" />
          <p>Request Amount</p>
          <input type="number" name="" id="" />
          <p className={styles.error_message}>
            You can not transfer amount less than $10 Or 100
          </p>
        </div>
        <div className={styles.modal_footer}>
          <div className={styles.btn_groups}>
            <button
              className={styles.btn_cancel}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button className={styles.btn_request}>Request</button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.historyMain}>
      {showModal && popUPModal}
      <div className={styles.topCardsContainer}>
        {campaignSummaryData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}></div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {badgesEarnedListData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td className={tableStyles.brand_name__logo_center}>
                      <img
                        className={tableStyles.table_image_brand_big}
                        src={item.badgeIcon}
                        alt="brandLogo"
                      />
                    </td>
                    <td>{item.badgeName}</td>
                    <td>{item.earnedDate}</td>
                    <td>{item.description}</td>
                    <td
                      style={{
                        color: uniqueStatusColorMapping[item.status],
                      }}
                    >
                      {item.status}
                    </td>

                    <td>
                      <button
                        onClick={() => {}}
                        className={styles.campaigns_table_btn_edit}
                      >
                        <img src={EyeIcon} alt="" />
                      </button>
                      {/* <button className={styles.details_button}>Details</button> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BadgesList;
