import React from 'react';
import styles from '../../../styles/influencer/referral/Referral.module.css';
import tableStyles from '../../../styles/brand/commonStyle/commonTable.module.css';
import modalStyles from '../../../styles/brand/commonStyle/commonModal.module.css';
import { useState } from 'react';
import DesignerBackgroundCard from '../helperComponents/DesignerBackgroundCardWithNumber';
import { useSelector } from 'react-redux';
import { addReferral, getAllReferral } from '../../../generalApiCalls/referral';

function ReferralBrand() {
  const stateVariables = useSelector((state) => state.influencerProfile);

  const [referralHistory, setReferralHistory] = useState([]);

  const [company_name, setCompanyName] = useState('');
  const [contact_person_name, setContactPersonName] = useState('');
  const [email, setEmail] = useState('');
  const [contact_no, setContactNo] = useState('');

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  // const [totalRecords, setTotalRecords] = useState(0);
  // const [search, setSearch] = useState('');
  const fetchReferralHistory = (referralType, page, limit, search) => {
    getAllReferral(referralType, page, limit, search)
      .then((res) => {
        console.log('fetchReferralHistory', res.data);
        // remape the data
        let reMappedData = res.data.map((item, index) => {
          return {
            id: index + 1,
            companyName: item.company_name,
            contactPersonName: item.contact_person,
            emailId: item.email,
            contactPersonNo: item.contact_no,
            referralDate: dateStringToDate(item.created_at),
            referralAmount: item.referral_amount,
            status: item.status,
          };
        });
        setReferralHistory(reMappedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetFormValues = () => {
    setCompanyName('');
    setContactPersonName('');
    setEmail('');
    setContactNo('');
  };

  const addNewReferral = () => {
    if (
      company_name === '' ||
      contact_person_name === '' ||
      email === '' ||
      contact_no === ''
    ) {
      alert('Please fill all the fields');
      return;
    }
    let data = {
      company_name: company_name,
      contact_person: contact_person_name,
      email: email,
      contact_no: contact_no,
      referral_type: 'BRAND',
    };
    addReferral(data)
      .then((res) => {
        console.log('addNewReferral', res.data);
        setShowModal(false);
        resetFormValues();
        fetchReferralHistory(2, 1, 10, '');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useState(() => {
    fetchReferralHistory(2, 1, 10, '');
  }, []);
  const referralHistoryData = [
    {
      title: 'Referral Given',
      value: 55,
      color: '#e3c700',
    },
    {
      title: 'Converted',
      value: 55,
      color: '#49a240',
    },
    {
      title: 'Not Converted',
      value: 55,
      color: '#f16d75',
    },
  ];
  const uniqueStatus = ['Converted', 'Non Converted'];

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'NAME OF COMPANY',
      placeHolder: 'NAME OF COMPANY',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'CONTACT PERSON',
      placeHolder: 'CONTACT PERSON',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'EMAIL ID',
      placeHolder: 'EMAIL ID',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'CONTACT No',
      placeHolder: 'CONTACT No',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'REFERRAL DATE',
      placeHolder: 'REFERRAL DATE',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'REFERRAL AMOUNT',
      placeHolder: 'REFERRAL AMOUNT',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'Status',
      placeHolder: 'Status',
      searchType: 'select',
      options: uniqueStatus,
      searchHeader: false,
    },
  ];
  const statusColorClass = {
    CONVERTED: styles.status_converted,
    'NOT CONVERTED': styles.status_non_converted,
  };
  const [showModal, setShowModal] = useState(false);

  const popUPModal = (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>Add Referral</div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              setShowModal(false);
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          <p>Company Name</p>
          <input
            type="text"
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
          />

          <p>Contact Person</p>
          <input
            type="text"
            onChange={(e) => {
              setContactPersonName(e.target.value);
            }}
          />
          <p>Email ID</p>
          <input
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <p>Contact No</p>
          <input
            type="text"
            onChange={(e) => {
              setContactNo(e.target.value);
            }}
          />
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className={modalStyles.btn_request}
              onClick={() => {
                addNewReferral();
              }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className={styles.referralMain}>
      {showModal && popUPModal}
      <div className={styles.topCardsContainer}>
        {referralHistoryData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}>
          <div className={styles.code__button}>
            <div></div>
            <div className={styles.code__title}>Your Referral code is</div>
            <div className={styles.modal_button_container}>
              <div
                className={styles.modal_button}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add Referral
              </div>
            </div>
          </div>
          <div className={styles.code__number}>
            {stateVariables?.personal_info?.user_ref_id}
          </div>
        </div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select name="" id="">
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {referralHistory?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td className={tableStyles.brand_name__logo}>
                      {/* <img
                        className={tableStyles.table_image_brand}
                        src={item.brandLogo}
                        alt="brandLogo"
                      />{' '} */}
                      {item.companyName}
                    </td>
                    <td>{item.contactPersonName}</td>
                    <td>{item.emailId}</td>
                    <td>{item.contactPersonNo}</td>
                    <td>{item.referralDate}</td>
                    <td>{item.referralAmount}</td>
                    <td className={statusColorClass[item.status]}>
                      {item.status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferralBrand;
