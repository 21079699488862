import React, { useEffect, useState } from 'react';
import styles from '../../../../styles/admin/influencer/influencerProfile/InfluencerProfile.module.css';

import instagramIcon from '../../../../assets/socialmediaIcons/white-filled/instagram.svg';
import facebookIcon from '../../../../assets/socialmediaIcons/white-filled/facebook.svg';
import twitterIcon from '../../../../assets/socialmediaIcons/white-filled/twitter.svg';
import youtubeIcon from '../../../../assets/socialmediaIcons/white-filled/youtube.svg';

import userIcon from '../../../../assets/brand/influencerProfile/user.svg';
import homeIcon from '../../../../assets/brand/influencerProfile/home.svg';

import starWhiteIcon from '../../../../assets/brand/influencerProfile/star-white.svg';
import briefcaseWhiteIcon from '../../../../assets/brand/influencerProfile/briefcase-white.svg';
import walletWhiteIcon from '../../../../assets/brand/influencerProfile/wallet-white.svg';
import homeWhiteIcon from '../../../../assets/brand/influencerProfile/home-white.svg';

// badges
import badge_1 from '../../../../assets/badges/badge_1.png';
import badge_2 from '../../../../assets/badges/badge_2.png';
import badge_3 from '../../../../assets/badges/badge_3.png';
import badge_4 from '../../../../assets/badges/badge_4.png';
import badge_5 from '../../../../assets/badges/badge_5.png';
import badge_6 from '../../../../assets/badges/badge_6.png';
import badge_7 from '../../../../assets/badges/badge_7.png';
import badge_8 from '../../../../assets/badges/badge_8.png';
import SocialMediaTag from '../../helperComponents/SocialMediaTag';
import { getInfluencerDetails } from '../../../../generalApiCalls/Influencer';
import { useParams } from 'react-router-dom';
import { downloadAsset } from '../../../../generalApiCalls/asset';

function InfluencerProfile() {
  const numberToMKB = (number) => {
    if (number >= 1000000000000) {
      return (number / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
    }
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return number;
  };

  const influencer_id = useParams().influencer_id;
  const [influencerData, setInfluencerData] = useState();

  const setInfluencerDetails = () => {
    console.log('influencerId', influencer_id);
    getInfluencerDetails(influencer_id).then((res) => {
      setInfluencerData(res.data);
      console.log('influencerData', res.data);
    });
  };

  useEffect(() => {
    setInfluencerDetails();
  }, []);

  // bages name and images
  const badges = {
    badge_1: badge_1,
    badge_2: badge_2,
    badge_3: badge_3,
    badge_4: badge_4,
    badge_5: badge_5,
    badge_6: badge_6,
    badge_7: badge_7,
    badge_8: badge_8,
  };

  // users interest
  const usersInterest = [
    'Electronics & Apps',
    'Entertainment',
    'Fitness',
    'Pets',
    'Travel',
  ];

  // name to icon mapping
  const socialMediaIcons = {
    instagram: instagramIcon,
    facebook: facebookIcon,
    twitter: twitterIcon,
    youtube: youtubeIcon,
  };

  const [activeTab, setActiveTab] = useState(0);

  // Top tabs of profile
  const tabs = [
    {
      name: 'About Me',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 0,
    },
    {
      name: 'Photos',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 1,
    },
    {
      name: 'Badges ',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 2,
    },
    {
      name: 'Work History',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 3,
    },
  ];

  const [bannerImageSrc, setBannerImageSrc] = useState();
  const [profileImageSrc, setProfileImageSrc] = useState();
  const [albumImageSrc, setAlbumImageSrc] = useState([]);
  const [albumImageSrcIds, setAlbumImageSrcIds] = useState([]);

  const setImageSrc = (imageId, imageCategory, imageType, setterFunction) => {
    if (imageId === null || imageId === undefined) return;

    downloadAsset(imageId, imageCategory, imageType, 1).then((res) => {
      if (imageCategory === 'ALBUM') {
        console.log('albumImageSrcone', res);
        // if res already exists in albumImageSrcIds, then don't add it
        if (albumImageSrcIds.includes(res.id)) return;
        setAlbumImageSrcIds([...albumImageSrcIds, res.id]);
        setterFunction([...albumImageSrc, res]);
      } else {
        setterFunction(res);
      }
    });
  };

  useEffect(() => {
    setImageSrc(
      influencerData?.asset?.banner,
      'BANNER',
      'IMAGE',
      setBannerImageSrc
    );
    setImageSrc(
      influencerData?.asset?.profile,
      'PROFILE',
      'IMAGE',
      setProfileImageSrc
    );
    influencerData?.asset?.album?.map((item) => {
      setImageSrc(item, 'ALBUM', 'IMAGE', setAlbumImageSrc);
    });
  }, [influencerData]);

  return (
    <div className={styles.InfluencerProfile}>
      <div className={styles.banner}>
        <img src={bannerImageSrc} alt="banner" />
        <div className={styles.banner_content}>
          <div className={styles.profileIcon}>
            <img src={profileImageSrc} alt="profile" />
          </div>
          <div className={styles.profileIcon_right}>
            <div className={styles.profileIcon_right_top}>
              <div className={styles.profileIcon_right_top_info}>
                <p>
                  {influencerData?.first_name + ' ' + influencerData?.last_name}
                </p>
                <div>{influencerData?.country_id?.label}</div>
              </div>
              <div className={styles.status}>
                {/* <div className={styles.ratings}>
                  {
                    // rating
                    [...Array(influencerData?.rating)]?.map((e, i) => (
                      <img src={starWhiteIcon} alt="rating star" />
                    ))
                  }
                </div>

                <span>/</span> */}
                <div className={styles.box_icon_number}>
                  <img src={walletWhiteIcon} alt="wallet" />
                  <span>{numberToMKB(influencerData?.min_cash_payment)}</span>
                </div>
                <span>/</span>
                <div className={styles.box_icon_number}>
                  <img src={briefcaseWhiteIcon} alt="briefcase" />
                  <span>
                    {numberToMKB(influencerData?.min_product_payment)}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.sectionSwitcher}>
              {
                // tabs
                tabs?.map((tab) => (
                  <button
                    onClick={() => {
                      setActiveTab(tab.tabNumber);
                    }}
                    className={activeTab === tab.tabNumber ? styles.active : ''}
                  >
                    <img
                      src={
                        activeTab === tab.tabNumber ? tab.activeIcon : tab.icon
                      }
                      alt="tabs"
                    />
                    <span>{tab.name}</span>
                  </button>
                ))
              }
            </div>
          </div>
        </div>
      </div>

      <div className={styles.details}>
        <div className={styles.details_card}>
          <div className={styles.details_card_profile}>
            <img src={profileImageSrc} alt="profile" />
            <div className={styles.details_card_ReachAndSocial}>
              <div className={styles.details_card_Reach}>
                {numberToMKB(influencerData?.reach)}
              </div>
              <div className={styles.details_card_Social}>
                {/** top three social media */}

                {influencerData?.influ_social_platform
                  ?.sort((a, b) => b.followers_count - a.followers_count)
                  .slice(0, 3)
                  .map((socialMedia) => (
                    // <SocialMediaTag {...socialMedia} />
                    <button>
                      <img
                        src={
                          socialMediaIcons[
                            socialMedia.social_platform.toLowerCase()
                          ]
                        }
                        alt={socialMedia.social_platform}
                      />
                    </button>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.details_card_paymentHeader}>Interest Info</div>
          <div className={styles.details_card_interest}>
            {
              // // users interest
              // usersInterest?.map((interest) => (
              //   <div>
              //     <img src={userIcon} alt="interest" /> <span>{interest}</span>
              //   </div>
              // ))

              influencerData?.speciality_id?.map((interestId) => (
                <div>
                  <img src={userIcon} alt="interest" />{' '}
                  <span>{interestId?.label}</span>
                </div>
              ))
            }
          </div>
          <div className={styles.details_card_paymentHeader}>Payment Info</div>
          <div className={styles.details_card_paymentMethods}>
            {/* <div>
              <span>Accepted Payment Type(s)</span>
              <span>Free Product & Cash</span>
            </div> */}
            <div>
              <span>Min Accepted Free Product Value</span>
              <span>{numberToMKB(influencerData?.min_product_payment)}</span>
            </div>
            <div>
              <span>Min Accepted Cash Value</span>
              <span>{numberToMKB(influencerData?.min_cash_payment)}</span>
            </div>
          </div>
        </div>
        <div className={styles.details_right}>
          {activeTab === 0 && (
            <>
              <h3>About Me</h3>
              <div className={styles.details_scoialInfo}>
                {/* {
                  // socialMedia followers
                  socialMediaFollowers.map((socialMedia) => (
                    <SocialMediaTag {...socialMedia} />
                  ))
                } */}

                {
                  // socialMedia followers
                  influencerData?.influ_social_platform?.map((socialMedia) => (
                    <SocialMediaTag
                      name={socialMedia?.social_platform}
                      icon={socialMediaIcons[socialMedia.social_platform]}
                      count={socialMedia.followers_count}
                      socialLink={socialMedia.social_account_link}
                    />
                  ))
                }
              </div>
              <h3>Bio</h3>
              <p>{influencerData?.bio}</p>
              <h3>Personal Info</h3>
              <table className={styles.my_table}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>State</th>
                    <th>Country</th>
                    <th>Languages</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {influencerData?.first_name +
                        ' ' +
                        influencerData?.last_name}
                    </td>
                    <td>{influencerData?.email}</td>
                    <td>
                      {influencerData?.state_id?.map((state) => state.label)}
                    </td>
                    <td>
                      {influencerData?.country_id?.map(
                        (country) => country.label
                      )}
                    </td>
                    <td>
                      {influencerData?.language_id?.map((language) => (
                        <span>{language.label}</span>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>{' '}
            </>
          )}

          {activeTab === 1 && (
            <div className={styles.profile_photos_album}>
              {
                // photos
                albumImageSrc?.map((photo) => (
                  <div>
                    <img src={photo} alt="portfolio" />
                  </div>
                ))
              }
            </div>
          )}
          {activeTab === 2 && (
            <div className={styles.profile_badges_album}>
              {
                // badges
                influencerData?.badges?.map((badge) => (
                  <div>
                    <img src={badges[badge]} alt="badges" />
                  </div>
                ))
              }
            </div>
          )}

          {activeTab === 3 && (
            <div className={styles.profile_work_history}>
              <h3>Work History</h3>
              <table className={styles.transactions}>
                <thead>
                  <tr>
                    <th>Current transactions</th>
                    <th>Completed transactions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InfluencerProfile;
