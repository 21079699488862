import { useDispatch } from 'react-redux';
import Axios from '../axios';

const getAllInfluenecersList = async (
  sortBy,
  sortOrder,
  search,
  page,
  limit
) => {
  console.log('getCampaignDetails', 'called');

  let data = {
    sortBy: sortBy,
    sortOrder: sortOrder,
    page: page,
    limit: limit,
  };

  if (search !== '') {
    data.search = search;
  }

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'api/v1/brand/allInfluencers',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    params: data,
  };
  console.log('Token *****', localStorage.getItem('accessToken'));
  console.log('config', config);
  try {
    return Axios.request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        return [];
      });
  } catch (e) {
    console.log('error getCampaignDetails ', e);
  }
};

export { getAllInfluenecersList };
