import React, { useState } from 'react';
import sectionTablestyles from '../../../styles/influencer/myProfile/SectionTablestyles.module.css';
import styles from '../../../styles/influencer/myProfile/MyProfile.module.css';
import ColumnTable from './ColumnTable';
import PencilIcon from '../../../assets/common/Pencil-alt-blue.svg';
import PersonalInfo from './forms/PersonalInfo';
import { useDispatch, useSelector } from 'react-redux';
import NotificationPref from './forms/NotificationPref';
import { setOpenForm } from '../../../redux/reducers/brand/profileSlice';
import UpdateSubBrandForm from './forms/UpdateSubBrandForm';
import AddSubBrandForm from './forms/AddSubBrandForm';
import ChangePasswordForm from './forms/ChangePasswordForm';

function BrandMyProfile() {
  const allProfileData = useSelector((state) => state.brandProfile);
  const dispatch = useDispatch();

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  let personalInfoData = [
    {
      value1: 'Profile Photo',
      value2: allProfileData?.personal_info?.image_url,
    },
    {
      value1: 'Corporate Name	',
      value2: allProfileData.personal_info.corporate_name,
    },
    {
      value1: 'First Name',
      value2: allProfileData.personal_info.first_name,
    },
    {
      value1: 'Last Name',
      value2: allProfileData.personal_info.last_name,
    },
    {
      value1: 'Email',
      value2: allProfileData.personal_info.email,
    },
    {
      value1: 'Mobile Phone No.',
      value2: allProfileData.personal_info.mobile_no,
    },
    {
      value1: 'Whatsapp No.',
      value2: allProfileData.personal_info.whatsapp_no,
    },
    {
      value1: 'Timezone',
      value2: allProfileData.personal_info.time_zone,
    },
  ];
  const planInfoData = [
    {
      value1: 'Current Plan',
      value2: 'Starter',
    },
    {
      value1: 'Subscription Interval',
      value2: 'Monthly',
    },
    {
      value1: 'Plan End Date',
      value2: 'Subscription Cancelled - 11/02/2021',
    },
    {
      value1: '# Users',
      value2: '1 User',
    },
    {
      value1: '# Active Campaigns',
      value2: '3 Campaigns',
    },
    {
      value1: '# Marketplace Applications',
      value2: '50 Influencers',
    },
    {
      value1: 'Marketplace Visibility',
      value2: '30 days',
    },
    {
      value1: '# Campaign Pitches',
      value2: '50 Influencers',
    },
    {
      value1: 'Reporting',
      value2: '',
    },
    {
      value1: 'Pitch Influencers',
      value2: 'Yes',
    },
    {
      value1: 'Private Campaigns',
      value2: 'No',
    },
    {
      value1: 'Dedicated Account Manager',
      value2: 'No',
    },
  ];

  let subBrandsObject = {};

  allProfileData?.sub_brands?.forEach((subBrand, index) => {
    let subBrandData = Object.keys(subBrand).map((key) => ({
      value1: key,
      value2: subBrand[key],
    }));
    // subBrandsObject[`Sub Brand ${index + 1}`] = subBrandData;
    subBrandsObject[`${index + 1}`] = subBrandData;
  });

  const convertToAllowBlock = (value) => {
    if (value === null || value === undefined) {
      return 'Not Configured';
    }
    if (value) {
      return 'Allow';
    } else {
      return 'Block';
    }
  };

  let notificationPreferencesData = [
    {
      value1: 'Emails',
      value2: convertToAllowBlock(
        allProfileData.notification_preferences.emails
      ),
    },
    {
      value1: 'Transaction Status Updates',
      value2: convertToAllowBlock(
        allProfileData.notification_preferences.transaction_status
      ),
    },
    {
      value1: 'New Message',
      value2: convertToAllowBlock(
        allProfileData.notification_preferences.new_message
      ),
    },
    {
      value1: 'New Marketplace Offers',
      value2: convertToAllowBlock(
        allProfileData.notification_preferences.new_marketplace_offers
      ),
    },
    {
      value1: 'Push Subscriptions',
      value2: convertToAllowBlock(
        allProfileData.notification_preferences.push_notifications
      ),
    },
  ];

  const sectionComponent = (sectionHeading, tableData) => {
    return (
      <div className={sectionTablestyles.section}>
        <div className={sectionTablestyles.sectionHeading}>
          <span>{sectionHeading}</span>
          <button
            onClick={() => {
              dispatch(setOpenForm(sectionHeading));
            }}
          >
            <img src={PencilIcon} alt="PencilIcon" />
            Edit
          </button>
        </div>
        <ColumnTable tableData={tableData} />
      </div>
    );
  };

  const subBrandSections = [];
  for (const subBrandKey in subBrandsObject) {
    let subBrandData = subBrandsObject[subBrandKey];
    subBrandData = subBrandData.filter((obj) => obj.value1 !== 'id');
    subBrandData = subBrandData.map((obj) => {
      if (obj.value1 === 'specialties') {
        obj.value2 = obj.value2.map((specialty) => specialty.label).join(', ');
      }
      return obj;
    });

    const sectionTitle = `Sub Brand ${subBrandKey}`;
    subBrandSections.push(sectionComponent(sectionTitle, subBrandData));
  }

  return (
    <div className={styles.myProfile}>
      {allProfileData?.openForm === 'Personal Information' && (
        <PersonalInfo preFiledData={allProfileData.personal_info} />
      )}
      {allProfileData?.openForm === 'Notification Preferences' && (
        <NotificationPref
          preFiledData={allProfileData.notification_preferences}
        />
      )}

      {allProfileData?.openForm === 'New Sub Brand' && (
        <AddSubBrandForm formTitle={'New Sub Brand'} />
      )}
      {allProfileData?.openForm === 'Change Password' && (
        <ChangePasswordForm formTitle={'ChangePassword'} />
      )}

      {allProfileData?.sub_brands?.map((subBrand, index) => {
        if (allProfileData?.openForm === `Sub Brand ${index + 1}`) {
          return (
            <UpdateSubBrandForm
              preFiledData={subBrand}
              formTitle={`Sub Brand ${index + 1}`}
            />
          );
        }
      })}

      <div className={styles.header}>
        <div className={styles.title}>My Account</div>
        <div className={styles.header_right}>
          <div
            className={styles.header_button}
            onClick={() => {
              dispatch(setOpenForm('New Sub Brand'));
            }}
          >
            + Add Brand User
          </div>
          <div
            className={styles.header_button}
            onClick={() => {
              dispatch(setOpenForm('Change Password'));
            }}
          >
            Change Password
          </div>
          <div className={styles.header_button}>Favorites</div>
          <div className={styles.header_button}>Blocklist</div>
        </div>
      </div>
      <div className={sectionTablestyles.columns}>
        <div className={sectionTablestyles.column}>
          {sectionComponent('Personal Information', personalInfoData)}
          {sectionComponent('Plan Information', planInfoData)}
        </div>
        <div className={sectionTablestyles.column}>
          {subBrandSections}
          {sectionComponent(
            'Notification Preferences',
            notificationPreferencesData
          )}
          {/* {sectionComponent('About You', aboutYouData, showFormAboutYou)} */}
        </div>
      </div>
    </div>
  );
}

export default BrandMyProfile;
