import React, { useEffect, useState } from 'react';
import styles from '../../../../styles/influencer/campaigns/campaignDetails/CampaignDetails.module.css';
import instagramIcon from '../../../../assets/socialmediaIcons/white-filled/instagram.svg';
import facebookIcon from '../../../../assets/socialmediaIcons/white-filled/facebook.svg';
import twitterIcon from '../../../../assets/socialmediaIcons/white-filled/twitter.svg';
import tiktokIcon from '../../../../assets/socialmediaIcons/white-filled/tiktok.svg';
import youtubeIcon from '../../../../assets/socialmediaIcons/white-filled/youtube.svg';

import AmplifyIcon from '../../../../assets/brand/campaigns/createCampaign/objective/Amplify.png';
import blogReviewIcon from '../../../../assets/brand/campaigns/createCampaign/objective/blogReview.png';
import customIcon from '../../../../assets/brand/campaigns/createCampaign/objective/custom.png';
import marketplaceReviewIcon from '../../../../assets/brand/campaigns/createCampaign/objective/marketplaceReview.png';
import socialEngagementIcon from '../../../../assets/brand/campaigns/createCampaign/objective/socialEngagement.png';
import socialReviewIcon from '../../../../assets/brand/campaigns/createCampaign/objective/socialReview.png';
import socialShareIcon from '../../../../assets/brand/campaigns/createCampaign/objective/socialShare.png';
import videoReviewIcon from '../../../../assets/brand/campaigns/createCampaign/objective/videoReview.png';

import homeIcon from '../../../../assets/brand/influencerProfile/home.svg';

import homeWhiteIcon from '../../../../assets/brand/influencerProfile/home-white.svg';

import Icon1 from '../../../../assets/brand/influencerProfile/Icon1.jpg';
import { getCampaignDetails } from '../../../../generalApiCalls/Campaigns';
import { useParams } from 'react-router-dom';

function CampaignDetails() {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      name: 'Description',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 0,
    },

    {
      name: 'Influencer Criteria',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 1,
    },
    {
      name: 'Talking Point',
      icon: homeIcon,
      activeIcon: homeWhiteIcon,
      tabNumber: 2,
    },
  ];

  const socialPlatformNametoIconMapping = (socialPlatformName) => {
    socialPlatformName = socialPlatformName.toLowerCase();
    switch (socialPlatformName) {
      case 'instagram':
        return instagramIcon;
      case 'facebook':
        return facebookIcon;
      case 'twitter':
        return twitterIcon;
      case 'tiktok':
        return tiktokIcon;
      case 'youtube':
        return youtubeIcon;
      default:
        return null;
    }
  };

  const allObjecties = [
    {
      id: 1,
      name: 'Brand Amplify',
      description:
        'Get influencers to promote your brand by posting vidoe and creatives on their page',
      icon: AmplifyIcon,
    },
    {
      id: 2,
      name: 'Social Share',
      description: 'Get influencers to amplify an existing social media post.',
      icon: socialShareIcon,
    },
    {
      id: 3,
      name: 'Social Engagement',
      description:
        'Get influencers to engage with an existing social media posts.',
      icon: socialEngagementIcon,
    },
    {
      id: 4,
      name: 'Social Review',
      description: 'Get influencers to post a review on social media.',
      icon: socialReviewIcon,
    },
    {
      id: 5,
      name: 'Blog Review',
      description: 'Get influencers to write a review on their blog.',
      icon: blogReviewIcon,
    },
    {
      id: 6,
      name: 'Video Review',
      description: 'Get influencers to post a video review on social media.',
      icon: videoReviewIcon,
    },
    {
      id: 7,
      name: 'Maketing Review',
      description: 'Get influencers to post a review on social media.',
      icon: marketplaceReviewIcon,
    },
    {
      id: 8,
      name: 'Marketplace Purchase',
      description:
        'Increase your products purchase in marketplaces such as Amazon, Flipkart, etc.',
      icon: marketplaceReviewIcon,
    },
    {
      id: 9,
      name: 'Custom Campaign',
      description:
        'Need something unique? All social media options are available.',
      icon: customIcon,
    },
  ];

  // const dispatch = useDispatch();

  const dateStringToDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month}-${day}`;
  };

  const campaignId = useParams().campaign_id;

  const [campaignDetailsData, setCampaignDetailsData] = useState({});

  const setCampaignDetails = () => {
    console.log('campaignId', campaignId);
    getCampaignDetails(campaignId).then((res) => {
      setCampaignDetailsData(res);
    });
  };

  useEffect(() => {
    setCampaignDetails();
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.details}>
        {/* left section */}
        <div className={styles.details_left}>
          <div className={styles.details_card}>
            <div className={styles.details_card_profile}>
              <img src={campaignDetailsData?.logo_path} alt="Brand Logo" />
              <div className={styles.details_card_ReachAndSocial}>
                <div className={styles.title}>
                  {campaignDetailsData?.camp_name}
                </div>
              </div>
            </div>

            <div className={styles.left_top}>
              <div>Cash Value</div>
              <p>{campaignDetailsData?.cash_value}</p>
              <div>Product Value</div>
              <p>{campaignDetailsData?.product_value}</p>
            </div>
          </div>
          <div className={styles.details_card_makeOffer}>
            Start Date : {dateStringToDate(campaignDetailsData?.start_date)}
          </div>
          <div className={styles.details_card_makeOffer}>
            End Date : {dateStringToDate(campaignDetailsData?.end_date)}
          </div>
        </div>
        <div className={styles.details_right}>
          <div className={styles.sectionSwitcher}>
            {
              // tabs
              tabs.map((tab, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setActiveTab(tab.tabNumber);
                  }}
                  className={activeTab === tab.tabNumber ? styles.active : ''}
                >
                  <img
                    src={
                      activeTab === tab.tabNumber ? tab.activeIcon : tab.icon
                    }
                    alt="tabs"
                  />
                  <span>{tab.name}</span>
                </button>
              ))
            }
          </div>
          {activeTab === 0 && (
            <div className={styles.tab_form}>
              <div className={styles.single_group}>
                <p>Descripton</p>
                <div className={styles.textarea_design}>
                  {campaignDetailsData?.description}
                </div>
              </div>
              <div className={styles.internal_heading}>Campaign Objective</div>
              <div className={styles.objectives}>
                {
                  // objectives
                  campaignDetailsData?.obj_id?.map((objective, index) => (
                    <div className={styles.objective_container} key={index}>
                      <img
                        src={
                          allObjecties.filter(
                            (obj) => obj.name === objective.label
                          )[0]?.icon
                        }
                        alt=""
                      />

                      <p>{objective.label}</p>
                    </div>
                  ))
                }
              </div>
              <div className={styles.internal_heading}>Target Audience</div>
              <div className={styles.single_row}>
                <div className={styles.label_input_container}>
                  <p>Min-Age</p>
                  <div>{campaignDetailsData?.t_min_age}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Max-Age</p>
                  <div>{campaignDetailsData?.t_max_age}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Gender</p>
                  <div>{campaignDetailsData?.t_gender}</div>
                </div>
              </div>
              <div className={styles.single_row}>
                <div className={styles.label_input_container}>
                  <p>Audience Geography</p>
                  <div>
                    {campaignDetailsData?.t_geography_id?.map((obj, index) => (
                      <> {obj.label + ' '}</>
                    ))}
                  </div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Targeted Countries</p>
                  <div>
                    {campaignDetailsData?.t_country_id?.map((obj, index) => (
                      <> {obj.label + ' '}</>
                    ))}
                  </div>
                </div>
                <div className={styles.label_input_container}>
                  <p>State</p>
                  <div>
                    {campaignDetailsData?.t_state_id?.map((obj, index) => (
                      <> {obj.label + ' '}</>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.single_row}>
                <div className={styles.label_input_container}>
                  <p>District</p>
                  <div>
                    {campaignDetailsData?.t_district_id?.map((obj, index) => (
                      <> {obj.label + ' '}</>
                    ))}
                  </div>
                </div>
                <div className={styles.label_input_container}>
                  <p>City/Town</p>
                  <div>
                    {campaignDetailsData?.t_city_id?.map((obj, index) => (
                      <> {obj.label + ' '}</>
                    ))}
                  </div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Language</p>
                  <div>
                    {campaignDetailsData?.t_language_id?.map((obj, index) => (
                      <> {obj.label + ' '}</>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 1 && (
            <div className={styles.tab_form + ' ' + styles.extra_top_margin}>
              <div className={styles.boxes_container}>
                <p>Specialties</p>
                <div className={styles.boxes}>
                  {
                    /* <div className={styles.one_box}>LifeStyle</div>
                  <div className={styles.one_box}>LifeStyle</div>
                  <div className={styles.one_box}>Marketing</div> */
                    campaignDetailsData?.specialties?.map(
                      (specialty, index) => (
                        <div className={styles.one_box} key={index}>
                          {specialty?.label}
                        </div>
                      )
                    )
                  }
                </div>
              </div>
              <div className={styles.boxes_container}>
                <p>Influencers Geography</p>
                <div className={styles.boxes}>
                  {/* <div className={styles.one_box}>LifeStyle</div>
                  <div className={styles.one_box}>Marketing</div> */}
                  {campaignDetailsData?.i_geography_id?.map(
                    (geography, index) => (
                      <div className={styles.one_box} key={index}>
                        {geography.label}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className={styles.boxes_container}>
                <p>Influencers Countries</p>
                <div className={styles.boxes}>
                  {/* <div className={styles.one_box}>LifeStyle</div>
                  <div className={styles.one_box}>Marketing</div> */}
                  {campaignDetailsData?.i_country_id?.map((country, index) => (
                    <div className={styles.one_box} key={index}>
                      {country.label}
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.boxes_container}>
                <p>Language</p>
                <div className={styles.boxes}>
                  {/* <div className={styles.one_box}>LifeStyle</div>
                  <div className={styles.one_box}>Marketing</div> */}
                  {campaignDetailsData?.i_language_id?.map(
                    (language, index) => (
                      <div className={styles.one_box} key={index}>
                        {language.label}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className={styles.single_row}>
                <div className={styles.label_input_container}>
                  <p>Minimum Audience Size</p>
                  <div>{campaignDetailsData?.i_min_audi_size}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Maximum Audience Size</p>
                  <div>{campaignDetailsData?.i_max_audi_size}</div>
                </div>
              </div>
              <div className={styles.single_row_radio}>
                <p>Worked Earlier With this Company</p>
                <input
                  type="radio"
                  name="workedEarlier"
                  checked={campaignDetailsData?.i_worked_earlier === 'Yes'}
                />{' '}
                Yes
                <input
                  type="radio"
                  name="workedEarlier"
                  checked={campaignDetailsData?.i_worked_earlier === 'No'}
                />{' '}
                No
              </div>
              <div className={styles.single_row}>
                <div className={styles.label_input_container}>
                  <p>Minimum Offer Price</p>
                  <div>{campaignDetailsData?.min_offer_price}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Maximum offer Price</p>
                  <div>{campaignDetailsData?.max_offer_price}</div>
                </div>
              </div>
              <div>Social Platform</div>
              <div className={styles.boxes}>
                {campaignDetailsData?.platform_ids?.map((platform, index) => (
                  <div className={styles.social_platform_container} key={index}>
                    <img
                      src={socialPlatformNametoIconMapping(platform.label)}
                      alt=""
                    />
                    <p>{platform.label}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div className={styles.tab_form + ' ' + styles.extra_top_margin}>
              <div className={styles.single_row}>
                <div className={styles.label_input_container}>
                  <p>Desired Outcomes</p>
                  <div>{campaignDetailsData?.desired_outcomes}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Desired Reach</p>
                  <div>{campaignDetailsData?.desired_reach}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Desired Leads</p>
                  <div>{campaignDetailsData?.desired_leads}</div>
                </div>
              </div>
              <div className={styles.single_row}>
                <div className={styles.label_input_container}>
                  <p>Number Of Influencers</p>
                  <div>{campaignDetailsData?.no_of_influencers}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Post Frequency</p>
                  <div>{campaignDetailsData?.post_frequency}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Number Of Post</p>
                  <div>{campaignDetailsData?.no_of_post}</div>
                </div>
              </div>
              <div className={styles.single_group}>
                <p>Posting Do's</p>
                <div className={styles.textarea_design}>
                  {campaignDetailsData?.posting_dos}
                </div>
              </div>
              <div className={styles.single_group}>
                <p>Posting Don'ts</p>
                <div className={styles.textarea_design}>
                  {campaignDetailsData?.posting_donts}
                </div>
              </div>
              <div className={styles.single_group}>
                <p>instruction To Influencers</p>
                <div className={styles.textarea_design}>
                  {campaignDetailsData?.instruction_to_influencer}
                </div>
              </div>
              <div className={styles.single_group}>
                <p>Campaign Content</p>
                <div className={styles.textarea_design}>
                  {campaignDetailsData?.campaign_content}
                </div>
              </div>
              <div className={styles.single_row}>
                <div className={styles.label_input_container}>
                  <p>Link Back URL</p>
                  <div>{campaignDetailsData?.link_back_url}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Type of content to post</p>
                  <div>{campaignDetailsData?.type_of_content_post}</div>
                </div>
              </div>
              <div className={styles.single_row}>
                <div className={styles.label_input_container}>
                  <p>Min Video Length</p>
                  <div>{campaignDetailsData?.min_video_length}</div>
                </div>
                <div className={styles.label_input_container}>
                  <p>Max Video Length</p>
                  <div>{campaignDetailsData?.max_video_length}</div>
                </div>
              </div>
              <div className={styles.internal_heading}>Campaign Documents</div>
              <table className={styles.document_list_table}>
                <thead>
                  <tr>
                    <th>Type of Document</th>
                    <th>Name</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PDF</td>
                    <td>Name of Document</td>
                    <td>
                      <button className={styles.download_button}>
                        Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CampaignDetails;
