import React, { useEffect } from 'react';
import styles from '../../../styles/influencer/dashboard/DashboardContent.module.css';
import { useNavigate } from 'react-router-dom';
// import { useState } from 'react';
import Chart from 'chart.js/auto';

import coco from '../../../assets/common/companyLogo/coco.png';
import burger from '../../../assets/common/companyLogo/burger.png';
import mcd from '../../../assets/common/companyLogo/mcd.png';
import kfc from '../../../assets/common/companyLogo/kfc.png';

// badges
import badge_1 from '../../../assets/badges/badge_1.png';
import badge_2 from '../../../assets/badges/badge_2.png';
import badge_3 from '../../../assets/badges/badge_3.png';
import badge_4 from '../../../assets/badges/badge_4.png';
import badge_5 from '../../../assets/badges/badge_5.png';
import badge_6 from '../../../assets/badges/badge_6.png';
import badge_7 from '../../../assets/badges/badge_7.png';
import badge_8 from '../../../assets/badges/badge_8.png';

function DashboardConetnt() {
  // this is the data for currency prefix and suffix
  const currencySuffixAndPrefix = {
    USD: { prefix: '$', suffix: 'USD' },
  };

  const navigate = useNavigate();
  const chartRef = React.useRef(null);

  useEffect(() => {
    const jsonData =
      '[{"label":"Lead","value":2},{"label":"Inculcate","value":4},{"label":"Reach","value":6},{"label":"Engage","value":8},{"label":"Enroll","value":10},{"label":"Align","value":12},{"label":"Knowledge","value":14}]';
    const data = JSON.parse(jsonData);
    const colors = ['red', 'green', 'blue', 'yellow', 'purple'];
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    const chartData = {
      labels: data.map((item) => item.label),
      datasets: [
        {
          label: 'Values',
          data: data.map((item) => item.value),
          backgroundColor: colors,
        },
      ],
    };

    const ctx = chartRef.current.getContext('2d');
    let myChart = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: chartOptions,
    });
    return () => {
      myChart.destroy();
    };
  }, []);

  // bages name and images
  const badges = [
    { img: badge_1, name: 'Top Influencers' },
    { img: badge_2, name: 'Top Influencers' },
    { img: badge_3, name: 'High Closer' },
    { img: badge_4, name: 'Top Influencers' },
    { img: badge_5, name: 'Top Influencers' },
    { img: badge_6, name: 'Top Influencers' },
    { img: badge_7, name: 'Top Influencers' },
    { img: badge_8, name: 'Top Influencers' },
  ];

  // Engagement
  const engagementData = {
    title: 'Engagement',
    data: [
      {
        title: 'Active',
        value: 2,
        color: '#e3c700',
      },
      {
        title: 'Accepted',
        value: 3,
        color: '#2a76f4',
      },
      {
        title: 'Confirmed',
        value: 1,
        color: '#49a240',
      },
    ],
  };

  // Marketplace
  const marketplaceData = {
    title: 'Marketplace',
    data: [
      {
        title: 'Completed',
        value: 200,
        color: '#e3c700',
      },
      {
        title: 'Available',
        value: 21,
        color: '#2a76f4',
      },
      {
        title: 'Opportunities',
        value: 8,
        color: '#49a240',
      },
    ],
  };

  // Earnings
  const earningsData = {
    title: 'Earnings',
    data: [
      {
        title: 'Total',
        value: 1000,
        currency: 'USD',
        color: '#e3c700',
      },
      {
        title: 'Processed',
        value: 677,
        currency: 'USD',
        color: '#2a76f4',
      },
      {
        title: 'Balance',
        value: 323,
        currency: 'USD',
        color: '#49a240',
      },
    ],
  };

  // Hungama Credits
  const hungamaCreditsData = {
    title: 'Hungama Credits',
    data: [
      {
        title: 'Earned',
        value: 200,
        color: '#e3c700',
      },
      {
        title: 'Processed',
        value: 21,
        color: '#2a76f4',
      },
      {
        title: 'Balance',
        value: 8,
        color: '#49a240',
      },
    ],
  };

  const performanceTableData = [
    {
      socialLink: 'Facebook',
      followers: 1000,
      likes: 1000,
      posts: 1000,
      engagement: 1000,
      campaigns: 1000,
    },
    {
      socialLink: 'Instagram',
      followers: 1000,
      likes: 1000,
      posts: 1000,
      engagement: 1000,
      campaigns: 1000,
    },
    {
      socialLink: 'Twitter',
      followers: 1000,
      likes: 1000,
      posts: 1000,
      engagement: 1000,
      campaigns: 1000,
    },
  ];

  const runningCampaignStatusMapping = {
    Active: styles.campaign_status_active,
    Completed: styles.campaign_status_completed,
    Accepted: styles.campaign_status_accepted,
  };

  const runningCampaignsData = [
    {
      id: '1',
      campaignName: 'Coca Cola Zero Calories',
      status: 'Active',
      amount: 30,
      startDate: '01 Jan 2022',
      img: coco,
    },
    {
      id: '2',
      campaignName: 'Burger King',
      status: 'Active',
      amount: 30,
      startDate: '01 Jan 2022',
      img: burger,
    },
    {
      id: '3',
      campaignName: "McDonald's",
      status: 'Accepted',
      amount: 30,
      startDate: '01 Jan 2022',
      img: mcd,
    },
    {
      id: '4',
      campaignName: 'KFC',
      status: 'Completed',
      amount: 30,
      startDate: '01 Jan 2022',
      img: kfc,
    },
    // {
    //   id: '5',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon2,
    // },
    // {
    //   id: '6',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon5,
    // },
    // {
    //   id: '7',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon2,
    // },
    // {
    //   id: '8',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon5,
    // },
    // {
    //   id: '9',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon2,
    // },
    // {
    //   id: '10',
    //   campaignName: 'Social Engagement on Intagram',
    //   status: 'Active',
    //   amount: 30,
    //   startDate: '01 Jan 2022',
    //   img: Icon5,
    // },
  ];

  const blogsData = [
    {
      id: '1',
      title: 'How to Create the Best Content Marketing Strategy for Your Niche',

      description:
        'Content marketing can serve as a valuable lead generation tool for your brand.[…]',
    },
    {
      id: '2',
      title: 'How You can Become a Brand Ambassador',
      description:
        'Based in the Philippines, Diana Rose Rosqueta started writing about her experiences[…]',
    },
    {
      id: '3',
      title: '5 Lifestyle Bloggers You Need to Follow in 2019',
      description:
        'There are many blogs within the lifestyle niche, but a few stand out above the rest. […]',
    },
  ];

  const selectedCampaignsData = {
    title: 'Coca Cola Zero Calories',
    category: ['Sports', 'Health'],
    targetAudience: {
      targetAudienceProfile: '18-25, Asian, Graduate',
      targetAudienceNumber: '1000',
      totalEngaged: '2%',
      totalValue: '100',
    },
    Description:
      'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter <br/> Designed by Almora in Austria, this Puma Shoes has',
  };

  function convertStringToHTML(string) {
    var tempContainer = document.createElement('div');
    tempContainer.innerHTML = string;
    return tempContainer.childNodes;
  }

  const oneCardWithNumber = (cardData) => (
    <div className={styles.container_design}>
      <div className={styles.background_overlay}></div>
      <div className={styles.titleBox}>
        <p>{cardData.title}</p>
        <select name="" id="">
          <option value="all">All</option>
          <option value="30">Last 30 days</option>
          <option value="60">Last 60 days</option>
          <option value="90">Last 90 days</option>
        </select>
      </div>
      <div className={styles.contentBoxForNumbers}>
        {cardData.data.map((data, index) => (
          <div
            key={index}
            title={data.toolpit}
            className={styles.cardWithNumber}
          >
            <p>{data.title}</p>
            {data.currency ? (
              <span style={{ color: data.color }}>
                {`
              ${currencySuffixAndPrefix[data.currency].prefix}${data.value}${
                  currencySuffixAndPrefix[data.currency].suffix
                }`}
              </span>
            ) : (
              <span style={{ color: data.color }}>{data.value}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  let runningCampaignsCard = (cardData, index) => (
    <div key={index} className={styles.runningCampaignsCard}>
      <div className={styles.campaignsImg}>
        <img src={cardData.img} alt="" />
      </div>
      <div className={styles.campaignDetails}>
        <div className={styles.runningCampaignsCard_left}>
          <h6>{cardData.campaignName}</h6>
          <p className={runningCampaignStatusMapping[cardData.status]}>
            {cardData.status}
          </p>
        </div>
        <div className={styles.runningCampaignsCard_right}>
          <h6>{cardData.amount}</h6>
          <p>{cardData.startDate}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.dashboardMain}>
      <div className={styles.dashboardMain_firstRow}>
        {oneCardWithNumber(engagementData)}
        {oneCardWithNumber(marketplaceData)}
        {oneCardWithNumber(earningsData)}
        {oneCardWithNumber(hungamaCreditsData)}
      </div>

      <div className={styles.dashboardMain_secondRow}>
        <div className={styles.dashboardMain_secondRow_left}>
          <h3>CURRENT ENGAGEMENT</h3>
          {runningCampaignsData.map((data, index) => {
            return runningCampaignsCard(data, index);
          })}
          <button className={styles.view_all_button_container}>View All</button>
        </div>
        <div className={styles.dashboardMain_secondRow_right}>
          <h3>Engagement Details</h3>
          <div className={styles.details}>
            <img src={coco} alt="" />
            <div>
              <h6>{selectedCampaignsData.title}</h6>
              <p>{selectedCampaignsData.category}</p>
              <h6>Target Audience</h6>
              <table>
                <thead>
                  <tr>
                    <th>Target</th>
                    <th>Engage</th>
                    <th>Audience Profile</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {
                        selectedCampaignsData.targetAudience
                          .targetAudienceNumber
                      }
                    </td>
                    <td>{selectedCampaignsData.targetAudience.totalEngaged}</td>
                    <td>
                      {
                        selectedCampaignsData.targetAudience
                          .targetAudienceProfile
                      }
                    </td>
                    <td>{selectedCampaignsData.targetAudience.totalValue}</td>
                  </tr>
                </tbody>
              </table>
              <h6>Description</h6>
              <p>{selectedCampaignsData.Description}</p>
            </div>
          </div>
          <button className={styles.view_all_button_container}>
            Read More
          </button>
        </div>
      </div>

      <div className={styles.dashboardMain_secondRow}>
        <div className={styles.dashboardMain_secondRow_left}>
          <h3>Dashboard Badges</h3>
          <div className={styles.badges_container}>
            {badges.map((badge, index) => (
              <div key={index} className={styles.badge}>
                <img src={badge.img} alt={badge.name} />
                <p>{badge.name}</p>
              </div>
            ))}
          </div>
          <button className={styles.view_all_button_container}>View All</button>
        </div>
        <div className={styles.dashboardMain_secondRow_right}>
          <h3>Performance Score</h3>
          <canvas className={styles.summary_chart_canvas} ref={chartRef} />

          <table className={styles.performanceTable}>
            <thead>
              <tr>
                <td>Social Link</td>
                <td>Followers</td>
                <td>Likes</td>
                <td>Posts</td>
                <td>Campaigns</td>
              </tr>
            </thead>
            <tbody>
              {performanceTableData.map((data, index) => (
                <tr key={index}>
                  <td>{data.socialLink}</td>
                  <td>{data.followers}</td>
                  <td>{data.likes}</td>
                  <td>{data.posts}</td>
                  <td>{data.campaigns}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* <div className={styles.dashboardMain_secondRow}>
        <div className={styles.dashboardMain_secondRow_left}>
          <h3>IMPROVE YOUR SKILLS!</h3>
          <button className={styles.view_all_button_container}>View All</button>
        </div>
        <div className={styles.dashboardMain_secondRow_right}>
          <h3>NEW MARKETPLACE OPPORTUNITIES</h3>
          <button className={styles.view_all_button_container}>View All</button>
        </div>
      </div> */}
    </div>
  );
}

export default DashboardConetnt;
