import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import brandProfileReducer from './reducers/brand/profileSlice';
import influencerProfileReducer from './reducers/influencer/profileSlice';

const reducer = {
  influencerProfile: influencerProfileReducer,
  brandProfile: brandProfileReducer,
};

// const preloadedState = {};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  //   preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export { store };
