import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../../axios';

const TwitterSignIn = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const navigate = useNavigate();

  const verifyAuthTokenFromTwitterSignIn = async () => {
    try {
      const requestHeader = {
        authToken: token,
      };
      const response = await axios.get('/api/v1/auth/verifyToken', {
        headers: requestHeader,
      });

      if (response.status === 200 && response.data.isValidToken) {
        setAccessToken(token, response.data.userTypeId);
      }
    } catch (err) {
      if (err.response.status === 401 && !err.response.data.isValidToken) {
        console.log('Invalid Token');
        navigate('/useronboarding');
      }
    }
  };

  const setAccessToken = (token, userTypeId) => {
    try {
      localStorage.setItem('accessToken', token);
      localStorage.setItem('userTypeId', userTypeId);
      localStorage.setItem(
        'userTypeValue',
        userTypeId === 1 ? 'influencer' : 'brand'
      );
      navigate(userTypeId === 1 ? '/influencer' : '/brand');
    } catch (err) {
      console.log('Error in setting token', err);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await verifyAuthTokenFromTwitterSignIn();
    }
    fetchData();
  }, []);

  return <div></div>;
};

export default TwitterSignIn;
