import React from 'react';
import styles from '../../../../styles/admin/campaigns/campaignDetails/CampaignDetails.module.css';
import tableStyles from '../../../../styles/brand/commonStyle/commonTable.module.css';
import modalStyles from '../../../../styles/brand/commonStyle/commonModal.module.css';
import PencilIcon from '../../../../assets/common/Pencil-alt.svg';
import SearchIcon from '../../../../assets/common/search-white.svg';
import CheckIcon from '../../../../assets/common/check-white.svg';

import { useState } from 'react';
import DesignerBackgroundCard from '../../helperComponents/DesignerBackgroundCardWithNumber';
import { useNavigate } from 'react-router-dom';

function CampaignDetails() {
  const navigate = useNavigate();
  const paymentCardData = [
    {
      title: 'TOTAL INTERESTED',
      value: 10,
      color: '#e3c700',
    },
    {
      title: 'ACCEPTED',
      value: 3,
      color: '#49a240',
    },
    {
      title: 'REJECTED',
      value: 200,
      color: '#FF0000',
    },
  ];

  const offersListData = [
    {
      id: '1',
      influencerName: 'Influencer 1',
      influencerId: '123456',
      additionalOffer: 'Yes',
      minimumAmount: '1000',
      interestedOn: '01/Jan/2022',
      status: 'Accepted',
    },
    {
      id: '2',
      influencerName: 'Influencer 2',
      influencerId: '123456',
      additionalOffer: 'No',
      minimumAmount: '1000',
      interestedOn: '01/Jan/2022',
      status: 'Rejected',
    },
    {
      id: '3',
      influencerName: 'Influencer 3',
      influencerId: '123456',
      additionalOffer: 'Yes',
      minimumAmount: '1000',
      interestedOn: '01/Jan/2022',
      status: 'Interested',
    },
  ];
  const statusColorClass = {
    Interested: '#e3c700',
    Accepted: '#49a240',
    Rejected: '#FF0000',
  };

  const uniqueStatus = Object.keys(statusColorClass);

  const tableSchema = [
    {
      fieldName: '#',
      placeHolder: '#',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'INFLUENCER NAME',
      placeHolder: 'INFLUENCER NAME',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'ADDITIONAL OFFER',
      placeHolder: 'ADDITIONAL OFFER',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'MINIMUM AMOUNT',
      placeHolder: 'MINIMUM AMOUNT',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'INTERESTED ON',
      placeHolder: 'INTERESTED ON',
      searchType: 'textInput',
      searchHeader: false,
    },
    {
      fieldName: 'STATUS',
      placeHolder: 'STATUS',
      searchType: 'select',
      options: uniqueStatus,
    },
    {
      fieldName: 'ACTION',
      placeHolder: 'ACTION',
      searchHeader: false,
    },
    {
      fieldName: 'INFLUENCER DETAILS',
      placeHolder: 'INFLUENCER DETAILS',
      searchHeader: false,
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [actionOfferId, setActionOfferId] = useState('');
  const [actionOfferName, setActionOfferName] = useState('');

  const popUPModal = (
    <div className={modalStyles.modalMain}>
      <div className={modalStyles.modal_container}>
        <div className={modalStyles.modal_header}>
          <div className={modalStyles.modal_header_title}>
            {actionOfferName}
          </div>
          <div
            className={modalStyles.modal_header_close_button}
            onClick={() => {
              setShowModal(false);
            }}
          >
            X
          </div>
        </div>
        <div className={modalStyles.modal_body}>
          <p>Do you want to accept this offer</p>
        </div>
        <div className={modalStyles.modal_footer}>
          <div className={modalStyles.btn_groups}>
            <button
              className={modalStyles.btn_cancel}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Reject
            </button>
            <button className={modalStyles.btn_request} onClick={() => {}}>
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.historyMain}>
      {showModal && popUPModal}
      <div className={styles.topCardsContainer}>
        {paymentCardData.map((cardData, index) => {
          return <DesignerBackgroundCard key={index} cardData={cardData} />;
        })}
      </div>

      <div className={styles.tabularContentContainer}>
        <div className={styles.tabularContentContainer_header}>
          <div className={styles.code__button}>
            <div></div>
            <h3>Camapaign Details</h3>
            <div></div>
          </div>
        </div>
        <div
          className={tableStyles.table_container + ' ' + styles.table_container}
        >
          <div className={tableStyles.table_container_header}>
            <div className={tableStyles.table_container_record_count}>
              <span>Show entities</span>
              <select>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={tableStyles.table_container_filter}>
              <span>Search : </span>
              <input type="text"></input>
            </div>
          </div>

          <table className={tableStyles.common_table}>
            <thead>
              <tr className={tableStyles.filterSearch}>
                {tableSchema.map((column, index) => {
                  return (
                    column.searchHeader &&
                    (column.searchType === 'textInput' ? (
                      <th key={index}>
                        <input
                          type="text"
                          placeholder={column.placeHolder}
                        ></input>
                      </th>
                    ) : (
                      <th key={index}>
                        <select>
                          {column.options.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </th>
                    ))
                  );
                })}
              </tr>
              <tr className={tableStyles.common_tableHeader}>
                {tableSchema.map((column, index) => {
                  return <th key={index}>{column.fieldName}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {offersListData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.influencerName}</td>
                    <td>{item.additionalOffer}</td>
                    <td>{item.minimumAmount}</td>
                    <td>{item.interestedOn}</td>
                    <td style={{ color: statusColorClass[item.status] }}>
                      {item.status}
                    </td>
                    <td>
                      <button
                        className={styles.campaigns_table_btn_edit}
                        onClick={() => {
                          setActionOfferName(item.influencerName);
                          setShowModal(true);
                        }}
                      >
                        <img src={PencilIcon} alt="" />
                      </button>
                    </td>
                    <td className={styles.align_center}>
                      <button
                        className={styles.campaigns_table_btn_edit}
                        onClick={() => {
                          navigate(`/brand/influencer/${item.influencerId}`);
                        }}
                      >
                        <img src={SearchIcon} alt="" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className={tableStyles.table_container_footer}>
            <div className={tableStyles.no_of_records}>
              <span>Showing 1 to 10 of 100 entities</span>
            </div>
            <div className={tableStyles.table_container_footer_pagination}>
              <button
                className={tableStyles.table_container_footer_pagination_prev}
              >
                Previous
              </button>
              <div className={tableStyles.table_container_footer_page_no}>
                1
              </div>
              <button
                className={tableStyles.table_container_footer_pagination_next}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignDetails;
