import styles from '../../../styles/common/components/Social_button.module.css';

const SocialButton = ({ title, socialButtonStyle, imgSrc, buttonOnClick }) => {
  return (
    <div className={socialButtonStyle} onClick={buttonOnClick}>
      <img alt={title} src={imgSrc} className={styles.button_image}></img>
      <p>{title}</p>
    </div>
  );
};

export default SocialButton;
